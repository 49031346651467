import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ImageIcon } from './Icons'

const DropZone = ({ image, onDrop, onRemoveImage, requiredSize }) => {
  const [droppedImage, setDroppedImage] = useState(image || null)

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case 'file-invalid-type':
          alert('Invalid file type. Please upload an image file.')
          break
        case 'file-too-large':
          alert('File is too large. Please upload an image file less than 1MB.')
          break
        default:
          alert('An error occurred while uploading the image.')
      }
      return
    }
    if (requiredSize) {
      const requiredWidth = requiredSize.width
      const requiredHeight = requiredSize.height
      const image = new Image()
      image.src = URL.createObjectURL(acceptedFiles[0])
      image.onload = () => {
        if (image.width !== requiredWidth || image.height !== requiredHeight) {
          alert(`Image size must be ${requiredWidth}x${requiredHeight}`)
          return
        }
        setDroppedImage(acceptedFiles[0])
        onDrop(acceptedFiles)
      }
    } else {
      // If no required size is provided, set the image
      setDroppedImage(acceptedFiles[0])
      onDrop(acceptedFiles)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg']
    },
    maxFiles: 1,
    onDrop: handleDrop,
    maxSize: 1000000
  })

  const handleRemoveImage = () => {
    setDroppedImage(null)
    onRemoveImage()
  }

  return (
    <section className="container">
      {droppedImage ? (
        <div className="flex mt-2 gap-2 items-center">
          <img
            src={
              droppedImage.preview
                ? droppedImage.preview
                : URL.createObjectURL(droppedImage)
            }
            alt="dropped"
            className="h-32 w-auto rounded-md"
          />
          <div className="flex flex-col gap-2">
            <p className="text-blue-400 text-md font-normal">
              {droppedImage.name}
            </p>
            <button
              className="text-red-500 font-medium text-left"
              onClick={handleRemoveImage}
            >
              Remove Image
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="cursor-pointer w-96 h-32 mt-2 flex flex-col justify-center items-center border-2 border-dashed border-indigo-300 bg-blue-50 rounded-md text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <ImageIcon className="h-9 w-9" stroke="#a5b4fc" />
            <p className="text-blue-400 mt-2 text-md font-normal">
              Drag and drop the image here, or click to select the image
            </p>
          </div>
          {requiredSize && (
            <p className="text-gray-400 text-sm mt-2">
              Image size must be {requiredSize.width}x{requiredSize.height}
            </p>
          )}
        </div>
      )}
    </section>
  )
}

export default DropZone
