import React, { useState } from 'react'
import { useHistory } from 'react-router'
import SelectCampaign from './Select'
import Upload from './Upload'
import Send from './Send'
import { AppContext, useAppContext } from '../../libs/context'
import Steps from '../../components/Steps'
import { UsersIcon } from '@heroicons/react/24/outline'
import i18n from '../../i18n'

const Campaign = () => {
  const section = 'lunch'
  const search = window.location.search
  const qParams = new URLSearchParams(search)
  const currentTab = qParams.get('step') || 'select'
  const history = useHistory()
  const {  updateNav } = useAppContext()
  const [stepsData, setStepsData] = useState([
    {
      id: 1,
      name: i18n.t('select_template'),
      href: 'select',
      current: true,
      completed: false
    },
    {
      id: 2,
      name: i18n.t('upload_contacts'),
      href: 'upload',
      current: false,
      completed: false
    },
    // {
    //   id: 3,
    //   name: 'Select date',
    //   href: 'date',
    //   current: false,
    //   completed: false
    // },
    {
      id: 3,
      name: i18n.t('send'),
      href: 'send',
      current: false,
      completed: false
    }
  ])
  const [campaignsTypes, setCampaignsTypes] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState()
  const [contacts, setContacts] = useState([])
  const [stat, setStat] = useState({
    name: i18n.t('total_subscribers'),
    users: '0',
    icon: UsersIcon
  })

  // if (currentTab) {
  //   let stepsDataMod = [...stepsData]
  //   stepsDataMod.find(({ href }) => href === currentTab).current = true
  //   stepsDataMod[0].current = false
  //   setStepsData(stepsDataMod)
  // }

  const onStepClick = (step) => {
    history.push({
      search: `?step=${step.href}`
    })
    let stepsDataMod = [...stepsData]
    stepsDataMod.find(({ current }) => current).current = false
    stepsDataMod.find(({ id }) => id === step.id).current = true
    setStepsData(stepsDataMod)
  }

  return (
    <AppContext.Provider
      value={{
        campaignsTypes,
        setCampaignsTypes,
        selectedCampaign,
        setSelectedCampaign,
        onStepClick,
        contacts,
        setContacts,
        stepsData,
        setStepsData,
        stat,
        setStat,
        updateNav
      }}
    >
      <Steps onStepClick={onStepClick} />
      {currentTab === 'select' && <SelectCampaign />}
      {currentTab === 'upload' && <Upload />}
      {currentTab === 'send' && <Send />}
    </AppContext.Provider>
  )
}

export default Campaign
