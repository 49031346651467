import React, { useState } from 'react'
import NewElement from './NewElement'
import RbmElementCard from './RbmElementCard'
import CreateModal from './CreateModal'
import NewElementForm from './NewElementForm'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import ButtonForm from './forms/ButtonForm'
import { getReplyOptions } from '../utils'
import {
  CARD_TYPE,
  RBM_ELEMENT_ERRORS,
  TEXT_TYPE,
  CAROUSEL_TYPE
} from './constants'

const getRbmElemetns = (flowId, rbmElements) => {
  const rbmElementsLength = Object.keys(rbmElements).length
  let elementsList = []
  for (let i = 0; i < rbmElementsLength; i++) {
    elementsList.push(rbmElements[`${flowId}-${i + 1}/${rbmElementsLength}`])
  }
  return elementsList
}

const elementNameExists = (name, flows, flowId, elementIndex) => {
  const elements = Object.keys(flows).reduce((acc, flow) => {
    if (flow === 'rbmFlowAliasesMap') return acc
    if (elementIndex !== null && flow === flowId) {
      acc = {
        ...acc,
        ...flows[flow].filter((element, index) => index !== elementIndex)
      }
    } else {
      acc = { ...acc, ...flows[flow] }
    }
    return acc
  }, {})
  return Object.keys(elements).some((element) => {
    return elements[element].name === name
  })
}

const getFlowSuggestions = (flowId, rbmElements) => {
  const rbmElementsLength = Object.keys(rbmElements).length
  let suggestionsList = []
  if (
    rbmElementsLength > 0 &&
    rbmElements[`${flowId}-${rbmElementsLength}/${rbmElementsLength}`]
      .suggestions
  ) {
    suggestionsList =
      rbmElements[`${flowId}-${rbmElementsLength}/${rbmElementsLength}`]
        .suggestions
  }
  return suggestionsList
}

const FlowBuilder = ({
  rbmElements = {},
  setMobileMessages,
  setFlow,
  flowId,
  isConversational = false,
  rbmFlows = {},
  rbmFlowAliases = {}
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [elements, setElements] = useState(
    Object.keys(rbmElements).length > 0
      ? getRbmElemetns(flowId, rbmElements)
      : []
  )
  const [elementIndex, setElementIndex] = useState(null)
  const [suggestions, setSuggestions] = useState(
    getFlowSuggestions(flowId, rbmElements)
  )
  const [suggestionIndex, setSuggestionIndex] = useState(null)
  const [suggestionModalOpen, setSuggestionModalOpen] = useState(false)
  const [errors, setErrors] = useState([])

  const validateElement = (element) => {
    const newErrors = []

    if (!element.name) {
      newErrors.push(RBM_ELEMENT_ERRORS.EMPTY_NAME)
    }
    switch (element.type) {
      case TEXT_TYPE:
        if (!element.text) {
          newErrors.push(RBM_ELEMENT_ERRORS.EMPTY_TEXT)
        }
        break
      case CARD_TYPE:
        if (!element.image || !(element.image.uploaded || element.image.file)) {
          newErrors.push(RBM_ELEMENT_ERRORS.MISSING_IMAGE)
        }
        break
      case CAROUSEL_TYPE:
        if (!element.cards || element.cards.length === 0) {
          newErrors.push(RBM_ELEMENT_ERRORS.MISSING_CARDS)
        }
        break
      default:
        break
    }
    setErrors(newErrors)
    if (newErrors.length > 0) {
      return false
    }
    return true
  }

  function updateRbmElements(newElements) {
    setFlow(flowId, newElements)
  }

  const handleCreateElement = (elementInfo) => {
    if (!validateElement(elementInfo)) return
    const newElements = [...elements, elementInfo]
    //check if element is valid
    if (elementNameExists(elementInfo.name, rbmFlows, flowId)) {
      alert(elementInfo.name + ' is already used. Choose another name.')
      return
    }
    //if there are more than one element and the last element has suggestions
    //then the suggestions should be moved to the new last element
    if (
      newElements.length > 1 &&
      newElements[newElements.length - 2].suggestions
    ) {
      delete newElements[newElements.length - 2].suggestions
    }
    if (suggestions.length > 0) {
      newElements[newElements.length - 1].suggestions = suggestions
    }
    setElements(newElements)
    setModalOpen(false)
    updateRbmElements(newElements)
  }

  const handleUpdateElement = (elementInfo) => {
    if (!validateElement(elementInfo)) return
    if (elementNameExists(elementInfo.name, rbmFlows, flowId, elementIndex)) {
      alert(elementInfo.name + ' is already used. Choose another name.')
      return
    }
    const newElements = [...elements]
    //if the element is the last one and there are suggestions
    //then the suggestions should be moved to the last element
    if (elementIndex === newElements.length - 1 && suggestions.length > 0) {
      elementInfo.suggestions = suggestions
    }
    newElements[elementIndex] = elementInfo
    updateRbmElements(newElements)
    setElements(newElements)
    setModalOpen(false)
    setElementIndex(null)
  }

  const handleDeleteElement = (index) => {
    const newElements = [...elements]
    //if the element is the last one and there are more than one element and there are suggestions
    //then the suggestions should be moved to the last element
    if (
      index === newElements.length - 1 &&
      newElements.length > 1 &&
      suggestions.length > 0
    ) {
      newElements[newElements.length - 2].suggestions = suggestions
    }
    newElements.splice(index, 1)
    updateRbmElements(newElements)
    setElements(newElements)
  }

  const handleChangeSuggestions = (suggestions) => {
    setSuggestions(suggestions)
    if (elements.length !== 0) {
      const newElements = [...elements]
      newElements[newElements.length - 1].suggestions = suggestions
      //if there are no suggestions then remove the suggestions key
      if (suggestions.length === 0) {
        delete newElements[newElements.length - 1].suggestions
      }
      updateRbmElements(newElements)
      setElements(newElements)
    }
  }

  const handleCreateSuggestion = (suggestion) => {
    const newSuggestions = [...suggestions, suggestion]
    handleChangeSuggestions(newSuggestions)
    setSuggestionModalOpen(false)
  }

  const handleUpdateSuggestion = (suggestion) => {
    const newSuggestions = [...suggestions]
    newSuggestions[suggestionIndex] = suggestion
    handleChangeSuggestions(newSuggestions)
    setSuggestionModalOpen(false)
    setSuggestionIndex(null)
  }

  const handleDeleteSuggestion = (index) => {
    const newSuggestions = [...suggestions]
    newSuggestions.splice(index, 1)
    handleChangeSuggestions(newSuggestions)
  }

  const onClickSuggestion = (index) => {
    setSuggestionIndex(index)
    setSuggestionModalOpen(true)
  }

  const onClickElement = (index) => {
    setErrors([])
    setElementIndex(index)
    setModalOpen(true)
  }

  const onSetOpen = (isOpen) => {
    setModalOpen(isOpen)
    if (!isOpen) setElementIndex(null)
  }

  const onSetSuggestionOpen = (isOpen) => {
    setSuggestionModalOpen(isOpen)
    if (!isOpen) setSuggestionIndex(null)
  }

  const getElementInfo = (index) => {
    if (index === null) return {}
    return elements[index]
  }

  const getOnClickFunction = (index) => {
    if (index === null) return handleCreateElement
    return handleUpdateElement
  }

  const getOnClickSuggestionFunction = (index) => {
    if (index === null) return handleCreateSuggestion
    return handleUpdateSuggestion
  }

  return (
    <div className="flex flex-col">
      <CreateModal open={modalOpen} setOpen={onSetOpen}>
        <NewElementForm
          key={'elementModal'}
          onClick={getOnClickFunction(elementIndex)}
          elementInfo={getElementInfo(elementIndex)}
          setOpen={onSetOpen}
          isConversational={isConversational}
          replyOptions={getReplyOptions(rbmFlows, rbmFlowAliases, flowId)}
          errors={errors}
        />
      </CreateModal>
      <CreateModal
        key={'suggestionModal'}
        open={suggestionModalOpen}
        setOpen={onSetSuggestionOpen}
        isSmall={true}
      >
        <ButtonForm
          onClick={getOnClickSuggestionFunction(suggestionIndex)}
          buttonInfo={suggestions[suggestionIndex]}
          setOpen={onSetSuggestionOpen}
          onCancel={() => {
            setErrors([])
            setSuggestionModalOpen(false)
            setSuggestionIndex(null)
          }}
          isConversational={isConversational}
          replyOptions={getReplyOptions(rbmFlows, rbmFlowAliases, flowId)}
        />
      </CreateModal>
      <h2
        className="text-xl font-semibold text-grey-blue w-full border-grey-blue py-4"
        htmlFor="msgText"
      >
        Elements to send
      </h2>
      <div className="flex gap-2">
        {elements.map((element, index) => (
          <RbmElementCard
            key={index}
            name={element.name}
            type={element.type}
            onClick={() => onClickElement(index)}
            onDelete={() => handleDeleteElement(index)}
          />
        ))}
        {elements.length >= 3 ? null : (
          <NewElement
            action={() => {
              setErrors([])
              setModalOpen(true)
            }}
          />
        )}
      </div>
      <h2
        className="text-xl font-semibold text-grey-blue w-full border-grey-blue py-4"
        htmlFor="msgText"
      >
        Suggestions
      </h2>
      <div className="flex gap-2">
        {suggestions.map((suggestion, index) => (
          <RbmElementCard
            key={index}
            name={suggestion.text}
            type={suggestion.type}
            isSuggestion
            onClick={() => onClickSuggestion(index)}
            onDelete={() => handleDeleteSuggestion(index)}
          />
        ))}
        {suggestions.length >= 3 ? null : (
          <button
            onClick={() => setSuggestionModalOpen(true)}
            className="h-14 w-1/3 flex gap-2 justify-center items-center border-dashed border-2 border-indigo-300 bg-blue-50 rounded-xl hover:scale-105 transition duration-300 ease-in-out"
          >
            <PlusCircleIcon className="h-6 w-6 text-indigo-300" />
            <span className="text-lg text-indigo-300 font-medium text-center">
              Add Suggestion
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

export default FlowBuilder
