import React from 'react'

import {
  EyeSvg,
  ClickSvg,
  DoubleCheckSvg,
  WebSvg
} from '../../components/Icons'

import { parseTextToHTML } from '../../libs/parse'

export const RichCardStats = ({ elementInfo }) => {
  return (
    <section id="richCardColumn" className="w-80">
      <div id="richCard" className="border-4 rounded-2xl overflow-hidden w-80">
        <img
          className="aspect-video object-cover w-full"
          src={elementInfo.imageUrl}
          alt="RichCard image"
        />
        <p className="font-medium text-base p-4 leading-5">
          {elementInfo.title && parseTextToHTML(elementInfo.title)}
        </p>
        {elementInfo.buttons &&
          elementInfo.buttons.map((button) => (
            <div id="linkButton" className="p-4 w-full text-center">
              <span className="text-blue-500">{button.text}</span>
            </div>
          ))}
      </div>
      <div id="suggestionsGroup" className="flex flex-wrap gap-1 mt-2">
        {elementInfo.suggestions &&
          elementInfo.suggestions.map((suggestion) => (
            <div
              id="suggestion2"
              className="border-2 border-gray-700 rounded-full px-3 py-1"
            >
              <span className="text-gray-700 font-normal">
                {suggestion.text}
              </span>
            </div>
          ))}
      </div>
    </section>
  )
}
