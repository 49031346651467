import React, { useState } from 'react'
import DropZone from '../../../../../components/DropZone'
import NewElement from '../NewElement'
import CreateModal from '../CreateModal'
import ButtonForm from './ButtonForm'
import RbmElementCard from '../RbmElementCard'
import crypto from 'crypto'
import { RBM_ELEMENT_ERRORS, RBM_ELEMENT_ERRORS_MESSAGES } from '../constants'

async function calculateFileHash(file) {
  const arrayBuffer = await file.arrayBuffer()
  const buffer = Buffer.from(arrayBuffer)
  const hash = crypto.createHash('sha256').update(buffer).digest('hex')
  return hash
}

const RichCardMsgForm = ({
  elementInfo = {},
  setInfo,
  isConversational,
  replyOptions,
  errors,
  flowId
}) => {
  const [isNewButtonOpen, setNewButtonOpen] = useState(false)
  const [buttons, setButtons] = useState(elementInfo.buttons || [])
  const [buttonIndex, setButtonIndex] = useState(null)

  const handleDrop = async (files) => {
    const fileHash = await calculateFileHash(files[0])
    setInfo({
      ...elementInfo,
      image: {
        name: files[0].name,
        preview: URL.createObjectURL(files[0]),
        file: files[0],
        hash: fileHash
      }
    })
  }

  const onRemoveImage = () => {
    //remove image from elementInfo
    const { image, ...rest } = elementInfo
    setInfo(rest)
  }

  const createButton = (buttonInfo) => {
    setButtons([...buttons, buttonInfo])
    setInfo({ ...elementInfo, buttons: [...buttons, buttonInfo] })
  }

  const updateButton = (buttonInfo, index) => {
    const newButtons = [...buttons]
    newButtons[index] = buttonInfo
    setButtons(newButtons)
    setInfo({ ...elementInfo, buttons: newButtons })
  }

  const deleteButton = (index) => {
    const newButtons = [...buttons]
    newButtons.splice(index, 1)
    setButtons(newButtons)
    setInfo({ ...elementInfo, buttons: newButtons })
  }

  const handleButtonSubmit = (buttonInfo) => {
    if (buttonIndex === null) {
      createButton(buttonInfo)
    } else {
      updateButton(buttonInfo, buttonIndex)
      setButtonIndex(null)
    }
    setNewButtonOpen(false)
  }

  const handleButtonClicked = (index) => {
    setButtonIndex(index)
    setNewButtonOpen(true)
  }

  const handleCancelButton = () => {
    setButtonIndex(null)
    setNewButtonOpen(false)
  }

  const onSetOpen = (isOpen) => {
    setNewButtonOpen(isOpen)
    if (!isOpen) setButtonIndex(null)
  }

  return (
    <div>
      <CreateModal open={isNewButtonOpen} setOpen={onSetOpen} isSmall={true}>
        <ButtonForm
          onClick={handleButtonSubmit}
          buttonInfo={buttons[buttonIndex]}
          setOpen={setNewButtonOpen}
          onCancel={handleCancelButton}
          isConversational={isConversational}
          replyOptions={replyOptions}
        />
      </CreateModal>
      <label
        htmlFor="image"
        className="block text-sm mt-3 font-medium text-grey-blue"
      >
        Card Image
      </label>
      <DropZone
        image={elementInfo.image}
        onDrop={handleDrop}
        onRemoveImage={onRemoveImage}
      />
      {errors.includes(RBM_ELEMENT_ERRORS.MISSING_IMAGE) && (
        <p className="text-red-500">
          {RBM_ELEMENT_ERRORS_MESSAGES[RBM_ELEMENT_ERRORS.MISSING_IMAGE]}
        </p>
      )}
      <label
        htmlFor="msgText"
        className="block text-sm mt-3 mb-2 font-medium text-grey-blue"
      >
        Card Title
      </label>
      <textarea
        type="text"
        name="msgText"
        id="msgText"
        value={elementInfo.title || ''}
        className="shadow block w-full resize-y rounded-md border-gray-300 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder="Title ..."
        onChange={(e) => {
          setInfo({ ...elementInfo, title: e.target.value })
        }}
      />
      <label
        htmlFor="msgDescription"
        className="block text-sm mt-3 mb-2 font-medium text-grey-blue"
      >
        Card Description
      </label>
      <textarea
        type="text"
        name="msgDescription"
        id="msgDescription"
        value={elementInfo.description || ''}
        className="shadow block w-full resize-y rounded-md border-gray-300 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder="Description ..."
        onChange={(e) =>
          setInfo({ ...elementInfo, description: e.target.value })
        }
      />
      <label
        htmlFor="msgDescription"
        className="block text-sm mt-3 mb-2 font-medium text-grey-blue"
      >
        Buttons
      </label>
      <div className="flex gap-4">
        {buttons.map((button, index) => (
          <RbmElementCard
            key={index}
            name={button.text}
            type={button.type}
            isButton={true}
            onClick={() => handleButtonClicked(index)}
            onDelete={() => deleteButton(index)}
          />
        ))}
        {buttons.length < 3 && (
          <NewElement
            isNewButton={true}
            action={() => setNewButtonOpen(true)}
          />
        )}
      </div>
    </div>
  )
}

export default RichCardMsgForm
