import { Button, message, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import {
  ChatBubbleBottomCenterTextIcon,
  ExclamationTriangleIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline'
import './phone.css'
import NewSmsForm from './components/NewSmsForm.js'
import { API, Auth } from 'aws-amplify'
import ResultModal from '../../../components/ResultModal.js'
import i18n from '../../../i18n/index.js'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Link, useHistory } from 'react-router-dom'
import { useAppContext } from '../../../libs/context.js'
import CreateRbm from './components/CreateRbm.jsx'
import {
  parseSupplierToTemplateRbm,
  parseTemplateToSupplierRbm
} from '../../../libs/parse.js'
import {
  CARD_TYPE,
  CAROUSEL_TYPE,
  RBM_SIMPLE_TYPE,
  REPLY_BUTTON,
  RBM_ELEMENT_ERRORS,
  RBM_ELEMENT_ERRORS_MESSAGES
} from './components/constants.js'
import ConversationalFlow from './components/ConversationalFlow.jsx'
import PhonePreview from './components/PhonePreview.jsx'

const handleCreateButton = () => {
  //TODO para crear el objeto del flow final unir todos los mensaje con Object.assign({}, ...messagesList)
}

const AlertPanel = () => {
  return (
    <div className="rounded-md bg-yellow-50 p-4 m-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Atention</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{i18n.t('rbm_alert')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const modalMsg = {
  ok: {
    title: i18n.t('operation_performed'),
    content: i18n.t('created_campaign'),
    cta: 'Ok'
  },
  error: {
    title: 'Error',
    content: i18n.t('error_creating_campaign'),
    cta: 'Error'
  }
}

const getImagesFromTemplate = (template) => {
  //the template is the rbmElements object
  const images = {}
  Object.keys(template).forEach((key) => {
    if (template[key].type === CARD_TYPE && !template[key].image.uploaded) {
      images[template[key].image.hash] = template[key].image.file
    }
    if (template[key].type === CAROUSEL_TYPE) {
      template[key].cards.forEach((card) => {
        if (!card.image.uploaded) {
          images[card.image.hash] = card.image.file
        }
      })
    }
  })
  return images
}

const uploadImgaes = async (images) => {
  const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
    .idToken.jwtToken

  //convert image to sent it in the aws api post
  const convertImageToFormData = (image) => {
    const formData = new FormData()
    formData.append('file', image)
    return formData
  }

  let promises = []
  for (let key in images) {
    const file = images[key]
    const formData = convertImageToFormData(file)
    formData.append('key', key)
    const params = {
      key: key,
      file: formData
    }
    promises.push(
      API.post('rcs-api', '/upload-image', {
        headers: {
          Authorization: tokenReq
        },
        body: formData
      })
    )
  }
  let result = []
  try {
    result = await Promise.all(promises)
  } catch (err) {
    console.log(err)
  }
  const imagesUploaded = result.reduce((acc, res) => {
    acc[res.key] = res.location
    return acc
  }, {})
  return imagesUploaded
}

const getRbmElements = (elements, supplier, campaignName) => {
  const rbmElements = {}
  //parse from supplier to template
  //remove the campaign name from the keys
  for (let key in elements) {
    //check if starts with the campaign name and doesnt have 'initSMS' in the key
    if (key.startsWith(campaignName) && !key.includes('initSMS')) {
      const splitKey = key.split('-')
      const newKey = splitKey.slice(1).join('-')
      rbmElements[newKey] = elements[key]
    }
  }
  const parsedElements = parseSupplierToTemplateRbm(rbmElements, supplier)
  if (elements.rbmFlowAliasesMap) {
    parsedElements.rbmFlowAliasesMap = elements.rbmFlowAliasesMap
  }
  return parsedElements
}

const CreateCampaign = ({ template, setTemplates, setSelectedTemplate }) => {
  const defaultErrors = {
    invalidName: false,
    emptyName: template ? false : true,
    emptySms: template ? false : true,
    invalidVariable: false,
    invalidSender: false,
    emptySender: template && template.sender ? false : true
  }
  const { userInfo } = useAppContext()

  const errorText = {
    invalidName: i18n.t('error_space_name'),
    emptyName: i18n.t('error_empty_name'),
    emptySms: i18n.t('error_empty_sms'),
    invalidVariable: i18n.t('error_invalid_variable'),
    invalidSender: i18n.t('error_invalid_sender'),
    emptySender: i18n.t('error_empty_sender')
  }

  const [rbmElements, setRbmElements] = useState({})
  const [openCreate, setOpenCreate] = useState(false)
  const [isSms, setIsSms] = useState(true)
  const [rbmType, setRbmType] = useState(
    template ? template.rbmType || null : null
  )
  const [mobileMessages, setMobileMessages] = useState([])
  const [smsList, setSmsList] = useState('')
  const [campaignName, setCampaignName] = useState(
    template ? template.name : ''
  )
  const [sender, setSender] = useState(
    template && template.sender ? template.sender : ''
  )
  const [modalContent, setModalContent] = useState(modalMsg.ok)
  const [openResult, setOpenResult] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState(defaultErrors)
  const [templateSMS, setTemplateSMS] = useState(
    template ? JSON.parse(template.elements)[`${template.name}-initSMS`] : null
  )
  const [rbmSimpleErrors, setRbmSimpleErrors] = useState([])
  const [supplierRcs, setSupplierRcs] = useState('orange')

  const getUserInfo = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const userFullInfo = await API.get('rcs-api', '/user', {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
    return userFullInfo
  }

  const getInitialRbmElements = async () => {
    if (!template) return
    const userFullInfo = await getUserInfo()
    const userSupplier = userFullInfo.supplierRcs || 'orange'
    const elements = JSON.parse(template.elements)
    const newRbmElements = getRbmElements(elements, userSupplier, campaignName)
    setSupplierRcs(userSupplier)
    setRbmElements(newRbmElements)
  }

  useEffect(() => {
    getInitialRbmElements()
  }, [])

  const history = useHistory()

  function goToList() {
    history.push('/templates')
    setSelectedTemplate(null)
  }

  const existErrors = () => {
    for (let error in errors) {
      if (errors[error]) {
        return true
      }
    }
    return false
  }
  const existErrorsResult = useMemo(() => existErrors())

  const addUrlToImages = (elements, imagesUploaded) => {
    const newElements = { ...elements }
    Object.keys(elements).forEach((key) => {
      const element = elements[key]
      if (element.type === CARD_TYPE) {
        if (element.image.uploaded) return
        newElements[key].image = {
          ...element.image,
          url: imagesUploaded[element.image.hash] || ''
        }
        delete newElements[key].image.file
      }
      if (element.type === CAROUSEL_TYPE) {
        if (element.cards[0].image.uploaded) return
        const newCards = element.cards.map((card) => {
          let newCard = { ...card }
          delete newCard.image.file
          newCard.image.url = imagesUploaded[newCard.image.hash] || ''
          return newCard
        })
        newElements[key].cards = newCards
      }
    })
    return newElements
  }

  const addPostBackToButton = (button, elements, campaignName) => {
    const newButton = { ...button }
    if (button.replyFlow) {
      const replyFlow = button.replyFlow
      const flowElementsCount = Object.keys(elements).filter((key) =>
        key.startsWith(`${campaignName}-${replyFlow}-`)
      ).length
      if (flowElementsCount !== 0) {
        newButton.postback = `${campaignName}-${replyFlow}-1/${flowElementsCount}`
      }
    }
    return newButton
  }

  const addPostbackToCardButtons = (card, elements, campaignName) => {
    const newCard = { ...card }
    if (card.buttons) {
      const newButtons = card.buttons.map((button) => {
        if (button.type === REPLY_BUTTON) {
          return addPostBackToButton(button, elements, campaignName)
        }
        return button
      })
      newCard.buttons = newButtons
    }
    return newCard
  }

  const addPostBackToButtons = (elements, campaignName) => {
    const newElements = { ...elements }
    for (let key in elements) {
      const element = elements[key]
      if (element.type === CARD_TYPE) {
        const newCard = addPostbackToCardButtons(
          element,
          elements,
          campaignName
        )
        newElements[key] = newCard
      }
      if (element.type === CAROUSEL_TYPE) {
        const newCards = element.cards.map((card) =>
          addPostbackToCardButtons(card, elements, campaignName)
        )
        newElements[key].cards = newCards
      }
      if (element.suggestions) {
        const newSuggestions = element.suggestions.map((suggestion) => {
          if (suggestion.type === REPLY_BUTTON) {
            return addPostBackToButton(suggestion, elements, campaignName)
          }
          return suggestion
        })
        newElements[key].suggestions = newSuggestions
      }
    }
    return newElements
  }

  const create = async () => {
    setIsLoading(true)
    setOpenResult(true)

    if (rbmType === RBM_SIMPLE_TYPE && rbmElements['init-1/1'].text === '') {
      setRbmSimpleErrors([RBM_ELEMENT_ERRORS.EMPTY_TEXT])
      setIsSms(false)
      return
    } else {
      setRbmSimpleErrors([])
    }
    // add the campaign name to the begining of the rbm elements keys
    const rbmElementsKeys = Object.keys(rbmElements)
    let newRbmElements = {}
    rbmElementsKeys.forEach((key) => {
      if (key === 'rbmFlowAliasesMap') {
        newRbmElements[key] = rbmElements[key]
      } else {
        newRbmElements[`${campaignName}-${key}`] = rbmElements[key]
      }
    })

    const images = getImagesFromTemplate(newRbmElements)
    const imagesUploaded = await uploadImgaes(images)
    newRbmElements = addUrlToImages(newRbmElements, imagesUploaded)

    let newflow = {
      type: 'direct'
    }
    newRbmElements = addPostBackToButtons(newRbmElements, campaignName)

    console.log('newRbmElements', newRbmElements)

    const parsedElements = parseTemplateToSupplierRbm(
      newRbmElements,
      supplierRcs
    )
    newflow = {
      ...parsedElements,
      ...newflow,
      [campaignName + '-initSMS']: smsList
    }

    try {
      const user = await Auth.currentAuthenticatedUser()
      const tokenReq = user.signInUserSession.idToken.jwtToken
      const body = {
        name: campaignName,
        elements: JSON.stringify(newflow),
        sender: sender,
        rbmType: rbmType
      }
      if (template) {
        body.template = template
      }
      const res = await API.post('rcs-api', '/campaign-type', {
        headers: {
          Authorization: tokenReq
        },
        body: body
      })
      if (template) {
        setTemplates((oldTemplates) =>
          oldTemplates.map((t) => {
            let newTemplate = t
            if (t.name === campaignName) {
              newTemplate.text =
                smsList?.length > 20 ? `${smsList.substr(0, 50)}...` : smsList
              newTemplate.sender = sender
              newTemplate.elements = JSON.stringify(newflow)
              return newTemplate
            }
            return newTemplate
          })
        )
      }
    } catch (err) {
      console.log(err)
      setModalContent(modalMsg.error)
    }
    setModalContent(modalMsg.ok)
    setIsLoading(false)
  }

  return (
    <div>
      <ResultModal
        {...modalContent}
        isLoading={isLoading}
        open={openResult}
        setOpen={setOpenResult}
        method={goToList}
      />
      <div className="site-card-wrapper">
        <div className="flex ">
          <div className="w-60 mr-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {i18n.t('campaign_name')}
            </label>
            <div className="relative mt-1 w-52 rounded-md shadow-sm">
              <input
                type="text"
                name="name"
                id="name"
                disabled={template ? true : false}
                className={
                  errors.emptyName || errors.invalidName
                    ? 'appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded pl-3 py-2 pr-3 leading-tight focus:outline-none focus:bg-white'
                    : template
                    ? 'block w-full rounded-md border-0 pl-3 py-2 pr-3 text-gray-600 shadow-sm ring-1 ring-gray-300 cursor-not-allowed placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6'
                    : 'block w-full rounded-md border-gray-300 pl-3 py-2 pr-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                }
                placeholder={i18n.t('name')}
                value={campaignName}
                onChange={(e) => {
                  setCampaignName(e.target.value)
                  if (e.target.value === '') {
                    setErrors((oldErrors) => {
                      oldErrors.emptyName = true
                      return oldErrors
                    })
                  } else {
                    setErrors((oldErrors) => {
                      oldErrors.emptyName = false
                      return oldErrors
                    })
                  }
                }}
              />
            </div>
            {errors.emptyName && (
              <p className="text-red-500 text-xs italic">
                {errorText.emptyName}
              </p>
            )}
            {errors.invalidName && (
              <p className="text-red-500 text-xs italic">
                {errorText.invalidName}
              </p>
            )}
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {i18n.t('campaign_type')}
            </label>
            <div className="flex mt-1 ">
              <div className="mr-5">
                <Button
                  className={
                    'inline-flex justify-center items-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm' +
                    (isSms
                      ? ' bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      : ' bg-gray-200')
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    setIsSms(true)
                    setMobileMessages([])
                  }}
                >
                  SMS
                </Button>
              </div>
              <div className="mr-5">
                <Button
                  className={
                    'inline-flex justify-center items-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm' +
                    (!isSms
                      ? ' bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      : ' bg-gray-200')
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    setIsSms(false)
                    setMobileMessages([])
                  }}
                >
                  RBM
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`mt-3`}>
        <div className="relative w-full pr-5 mx-auto max-w-2xl lg:max-w-none ">
          <div className="flex gap-4 justify-between">
            {isSms ? (
              <NewSmsForm
                setSender={setSender}
                sender={sender}
                setMobileMessages={setMobileMessages}
                setSmsList={setSmsList}
                smsList={smsList}
                setErrors={setErrors}
                errors={errors}
                errorText={errorText}
                templateSMS={templateSMS}
              />
            ) : userInfo.isAdmin ? (
              // TODO: only Admin can create new RBM while the feature is in beta
              <CreateRbm
                setMobileMessages={setMobileMessages}
                rbmType={rbmType}
                setRbmType={setRbmType}
                setRbmElements={setRbmElements}
                rbmElements={rbmElements}
                rbmSimpleErrors={rbmSimpleErrors}
              />
            ) : (
              <AlertPanel />
            )}
            <div className="sticky h-fit top-10 z-10">
              {(isSms || rbmType) && (
                <PhonePreview rbmElements={mobileMessages} />
              )}
            </div>
          </div>

          <div className="mt-4 flex justify-end">
            <Link to={'/templates'}>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                onClick={() => {
                  if (template) {
                    setSelectedTemplate(null)
                  }
                }}
              >
                {i18n.t('cancel')}
              </button>
            </Link>

            <button
              type="button"
              disabled={existErrorsResult}
              className={
                (!existErrorsResult
                  ? 'bg-blue-dark hover:bg-indigo-700'
                  : 'cursor-not-allowed') +
                ' bg-gray-300 ml-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
              }
              onClick={(e) => {
                e.preventDefault()
                create()
              }}
            >
              {template ? i18n.t('update_template') : i18n.t('create_template')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCampaign
