import {
  CarouselSvg,
  MessageIcon,
  PhoneIcon,
  RichCardSvg,
  TextSvg,
  WebSvg
} from '../../../../components/Icons'

//Rbm types
export const RBM_BASIC_TYPE = 'RBM_BASIC_TYPE'
export const RBM_SIMPLE_TYPE = 'RBM_SIMPLE_TYPE'
export const RBM_CONVERSATIONAL_TYPE = 'RBM_CONVERSATIONAL_TYPE'
export const RBM_TYPES = [
  {
    name: 'Simple',
    description: 'Text message that allows links',
    type: RBM_SIMPLE_TYPE
  },
  {
    name: 'Basic',
    description:
      'Send elements with images like rich cards or carousels of images with buttons to create a more dynamic comunication',
    type: RBM_BASIC_TYPE
  },
  {
    name: 'Conversational',
    description:
      'Create a conversation with the clients, send them all your products with rich cards and respond all their questions',
    type: RBM_CONVERSATIONAL_TYPE
  }
]
//Rbm elements
export const TEXT_TYPE = 'TEXT_TYPE'
export const CARD_TYPE = 'CARD_TYPE'
export const CAROUSEL_TYPE = 'CAROUSEL_TYPE'
export const ELEMENT_TYPES = [
  {
    name: 'Text',
    description: 'Send a text message to the user',
    type: TEXT_TYPE,
    icon: TextSvg
  },
  {
    name: 'Rich Card',
    description: 'Send a card with an image and buttons to the user',
    type: CARD_TYPE,
    icon: RichCardSvg
  },
  {
    name: 'Carousel',
    description: 'Send a carousel of images with buttons to the user',
    type: CAROUSEL_TYPE,
    icon: CarouselSvg
  }
]

//Butons type
export const LINK_BUTTON = 'LINK_BUTTON'
export const PHONE_CALL_BUTTON = 'PHONE_CALL_BUTTON'
export const REPLY_BUTTON = 'REPLY_BUTTON'
export const BUTTONS_TYPES = [
  {
    name: 'Link',
    description: 'Send a link to the user',
    type: LINK_BUTTON,
    icon: WebSvg
  },
  {
    name: 'Phone call',
    description: 'Call to a phone number',
    type: PHONE_CALL_BUTTON,
    icon: PhoneIcon
  }
]

export const ALL_BUTTONS_TYPES = [
  ...BUTTONS_TYPES,
  {
    name: 'Reply',
    description: 'Send a reply to the user',
    type: REPLY_BUTTON,
    icon: MessageIcon
  }
]

export const BUTTON_ERROR_TYPES = {
  EMPTY_DISPLAY_TEXT: 'EMPTY_DISPLAY_TEXT',
  EMPTY_LINK: 'EMPTY_LINK',
  EMPTY_PHONE: 'EMPTY_PHONE',
  MISSING_REPLY: 'MISSING_REPLY'
}

export const BUTTON_ERRORS_MESSAGES = {
  [BUTTON_ERROR_TYPES.EMPTY_LINK]: 'Link is required',
  [BUTTON_ERROR_TYPES.EMPTY_PHONE]: 'Phone bumber is required',
  [BUTTON_ERROR_TYPES.MISSING_REPLY]: 'Reply is required',
  [BUTTON_ERROR_TYPES.EMPTY_DISPLAY_TEXT]: 'Display text is required'
}

export const RBM_ELEMENT_ERRORS = {
  EMPTY_NAME: 'EMPTY_NAME',
  MISSING_IMAGE: 'MISSING_IMAGE',
  MISSING_CARDS: 'MISSING_CARDS',
  EMPTY_TEXT: 'EMPTY_TEXT'
}
export const RBM_ELEMENT_ERRORS_MESSAGES = {
  [RBM_ELEMENT_ERRORS.EMPTY_NAME]: 'Name is required',
  [RBM_ELEMENT_ERRORS.MISSING_IMAGE]: 'Image is required',
  [RBM_ELEMENT_ERRORS.MISSING_CARDS]:
    'You need to add at least one card to the carousel',
  [RBM_ELEMENT_ERRORS.EMPTY_TEXT]: 'Text is required'
}
