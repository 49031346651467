import React, { useState } from 'react'

const ChangeFlow = ({
  setMobileMessages,
  setRbmType,
  rbmTypeName,
  setRbmElements,
  children
}) => {
  const handleChangeType = () => {
    setRbmType(null)
    setMobileMessages([])
  }

  return (
    <div className="w-full">
      <div className="flex">
        <h2 className="text-2xl font-bold text-grey-blue w-full border-grey-blue w-fit">
          {rbmTypeName} Campaign
        </h2>
        <button
          className="text-sm text-blue-500 underline self-end ml-2"
          onClick={handleChangeType}
        >
          Change Type
        </button>
      </div>
      {children}
    </div>
  )
}

export default ChangeFlow
