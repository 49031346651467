import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify'
import { RadioGroup } from '@headlessui/react'
import { dateToString } from '../../libs/date'
import { useAppContext } from '../../libs/context'
import Spin from '../../components/Spin'
import i18n from '../../i18n'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SelectCampaign = () => {
  const {
    campaignsTypes,
    setCampaignsTypes,
    selectedCampaign,
    setSelectedCampaign,
    onStepClick,
    stepsData,
    setStepsData
  } = useAppContext()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const _stepsData = [...stepsData]

  const getCampaignsTypes = async () => {
    setLoading(true)
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const req = await API.get('rcs-api', '/campaigns-types', {
        headers: {
          Authorization: tokenReq
        }
      })
      const cleanData = req.map((item) => {
        const parseText = JSON.parse(item.elements)
        let keys = Object.keys(parseText).find((key) =>
          key.includes('init-1')
        )
        let text 
        if (!keys){
          keys =Object.keys(parseText).find((key) =>
          key.includes('initSMS')
        )
          text = parseText[keys]
        }else{
          if(parseText[keys].RCSMessage){
            text = parseText[keys].RCSMessage.textMessage
          }else if(parseText[keys].contentMessage){
            text = parseText[keys].contentMessage.text
          }else{
            text = parseText[keys].messageText
          }
        }
        item.text = text?.length > 20 ? `${text.substr(0, 50)}...` : text
        const parseDate = dateToString(item.timestamp)
        item.date = parseDate.dayMin
        item.time = parseDate.time
        item.initElement = keys
        item.schema = item.schema ? item.schema : "basic"
        return item
      })
      setCampaignsTypes(cleanData)
    } catch (err) {
      console.log("error", err)
      setErrorMessage('There were problems loading the campaign types')
    }
    setLoading(false)
  }

  const handleOnChange = (e) => {
    setSelectedCampaign(e)
  }

  useEffect(() => {
    if (!campaignsTypes.length) {
      getCampaignsTypes()
    }
  }, [])

  return (
    <>
      {loading ? (
        <div className="flex justify-center my-16">
          <Spin type="lightBg" />
        </div>
      ) : (
        <div className="w-full max-w-xl mx-auto mt-10">
          <RadioGroup value={selectedCampaign} onChange={handleOnChange}>
            <RadioGroup.Label className="sr-only">{i18n.t('campaign')}</RadioGroup.Label>
            <div className="space-y-4">
              {campaignsTypes.map((campaign) => (
                <RadioGroup.Option
                  key={campaign.name}
                  value={campaign}
                  className={({ checked, active }) => {
                    return classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      active ? 'border-blue-dark ring-2 ring-blue-dark' : '',
                      'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                    )
                  }}
                  onClick={() => {
                    _stepsData.find(({ id }) => id === 1).completed = true
                    setStepsData(_stepsData)
                  }}
                >
                  {({ active, checked }) => (
                    <>
                      <span className="flex items-center">
                        <span className="text-sm flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="font-medium text-grey-blue"
                          >
                            {campaign.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="text-gray-500"
                          >
                            <span className="block sm:inline">
                              {campaign.text}
                            </span>
                          </RadioGroup.Description>
                        </span>
                      </span>
                      <RadioGroup.Description
                        as="span"
                        className="mt-2 flex text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                      >
                        <span className="font-medium text-grey-blue">
                          {campaign.date}
                        </span>
                        <span className="ml-1 text-gray-500 sm:ml-0">
                          {campaign.time}
                        </span>
                      </RadioGroup.Description>
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-blue-dark' : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <div className="mt-4 flex">
            <button
              type="button"
              className={classNames(
                selectedCampaign
                  ? 'bg-blue-dark hover:bg-indigo-700'
                  : 'cursor-not-allowed',
                'bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
              )}
              onClick={() => {
                onStepClick({
                  id: 2,
                  href: 'upload'
                })
              }}
            >
              {i18n.t('next')}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SelectCampaign
