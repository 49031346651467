import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import {
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/20/solid'
import { useAppContext } from '../../libs/context'
import { ERRORS } from '../../config/app'
import Spin from '../../components/Spin'
import ConfirmCodeForm from './ConfirmCodeForm'
import config from '../../config/aws';
import i18n from '../../i18n'
import CountryPicker from '../../components/CountryPicker'
import { Trans } from 'react-i18next'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [userParams, setUserParams] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [showConfirmationBox, setShowConfirmationBox] = useState(false)
  const [countryCode, setCountryCode] =useState('af')
  const [countryPrefix, setCountryPrefix] =useState('93')
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false)
  const { setIsAuthenticated, setUserInfo } = useAppContext()
  const history = useHistory()

  const addUserToDB = async (userId, email) => {
    await API.post('rcs-api', '/signup', {
      body: {
        userId: userId,
        email: email,
        countryCode: countryPrefix
      }
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const [email, password, passwordCheck] = e.target
    if (!isPolicyAccepted) {
      setErrorMessage(ERRORS.AccepPolicyException)
      return
    }
    if (password.value === passwordCheck.value) {
      setLoading(true)
      const data = {
        username: email.value,
        password: password.value
      }
      setUserParams(data)
      try {
        const user = await Auth.signUp(data)
        const params = {
          GroupName: "User",
          UserPoolId: config.cognito.USER_POOL_ID,
          Username: user.userSub,
        };
        await addUserToDB(user.userSub, email.value)
        const res = await API.post('rcs-api', '/user-to-group', {
          body: params
        })
        setShowConfirmationBox(true)
        setErrorMessage(null)
      } catch (err) {
        setErrorMessage(ERRORS[err.code])
      }
      setLoading(false)
    } else {
      setErrorMessage('Passwords do not match')
    }
  }

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                src="/assets/imgs/logo-tadoo-black.svg"
                style={{ width: '200px' }}
                alt="logo"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-grey-blue">
                {i18n.t('sign_up_register')}
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                {i18n.t('or')}{' '}
                <Link
                  to="/login"
                  className="font-medium text-blue-dark hover:text-blue-dark hover:underline"
                >
                  {i18n.t('log_in_register')}
                </Link>
              </p>
            </div>
            {!showConfirmationBox ? (
              <div className="mt-8">
                <div className="mt-6">
                  <form onSubmit={onSubmit} className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        {i18n.t('password')}
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="passwordCheck"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        {i18n.t('confirm_password')}
                      </label>
                      <div className="mt-1">
                        <input
                          id="passwordCheck"
                          name="passwordCheck"
                          type="password"
                          autoComplete="check-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="countryCode"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        {i18n.t('country_prefix')}
                      </label>
                      <div className="mt-1">
                        <CountryPicker
                          countryCode={countryCode}
                          setCountryCode={setCountryCode}
                          setCountryPrefix={setCountryPrefix}
                          countryPrefix={countryPrefix}
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <input
                        value = {isPolicyAccepted}
                        onChange = {() => setIsPolicyAccepted(!isPolicyAccepted)}
                        id="policy"
                        name="policy"
                        type="checkbox"
                        required
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="policy" className="ml-2 block text-sm">
                        {/* {i18n.t('accept_policy',)} */}
                        <Trans i18nKey="gdpr_accept"
                        components={{
                          link1: <a href="https://tadoo.app/politica-de-privacidad/" target="_blank" rel="noreferrer" className='text-blue-dark hover:text-blue-dark hover:underline' />,
                          link2: <a href="https://tadoo.app/aviso-legal/" target="_blank" rel="noreferrer" className='text-blue-dark hover:text-blue-dark hover:underline' />
                      }}/>
                         
                      </label>
                      
                    </div>
                    {errorMessage && (
                      <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              {i18n.t('atention')}
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <p>{errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="ease-in duration-100 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled=""
                      >
                        {loading && <Spin type="darkBg" size="small" />}
                        {loading ? i18n.t('processing') : i18n.t('sign_up_button')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <ConfirmCodeForm
                countryPrefix={countryPrefix}
                userParams={userParams}
              />
            )}
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/imgs/img-bg-chatsales.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default Login
