import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { useAppContext } from '../../libs/context'
import { API, Auth } from 'aws-amplify'
import ResultModal from '../../components/ResultModal'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import i18n from '../../i18n'
import { useHistory } from 'react-router'
import Papa from 'papaparse'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Send = () => {
  const { selectedCampaign, stat, contacts, updateNav, userInfo } =
    useAppContext()
  const [open, setOpen] = useState(false)
  const [userFullInfo, setUserFullInfo] = useState({})
  const [resultContent, setResultContent] = useState({})
  const [openResult, setOpenResult] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [scheduled, setScheduled] = useState(false)
  const [scheduleDate, setScheduleDate] = useState(null)
  const [scheduleTime, setScheduleTime] = useState(null)

  const history = useHistory()
  const [resultFunction, setResultFunction] = useState(false)

  function goStatistics() {
    console.log('se activa estatistics')
    setOpenResult(false)
    history.push('/statistics')
    updateNav('/statistics')
  }

  // const modalMsgs = {
  //   pre: {
  //     title: "Let's launch the campaign",
  //     content: `You are going to launch the campaign ${selectedCampaign?.name} to ${stat?.users}`,
  //     cta: 'Launch'
  //   },
  //   post: {
  //     title: 'Your campaign has been launched',
  //     content:
  //       'You can go to the stats page to start cheking how the campaign goes',
  //     cta: 'Ok'
  //   }
  // }
  const modalMsgs = {
    pre: {
      title: 'Lanza la campaña',
      content: `Vas a lanzar la campaña ${selectedCampaign?.name} a ${stat?.users}`,
      cta: 'Lanzar'
    },
    post: {
      title: i18n.t('campaign_launched'),
      content: i18n.t('check_stats'),
      cta: 'Ok'
    },
    error: {
      title: i18n.t('error_launching'),
      content: i18n.t('could_not_launch'),
      cta: 'Error'
    }
  }

  const [modalContent, setModalContent] = useState(modalMsgs.pre)

  const getUserFullInfo = async () => {
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const resp = await API.get('rcs-api', '/user', {
        headers: {
          Authorization: tokenReq
        }
      })
      setUserFullInfo(resp)
    } catch (err) {
      console.log(err)
    }
  }

  const hasEnoughBalance = () => {
    if (userFullInfo.paymentType && userFullInfo.paymentType === 'POST') {
      return true
    }
    const balance = userFullInfo.balance ? userFullInfo.balance : 0
    const costPerRBM = userFullInfo.costPerRBM ? userFullInfo.costPerRBM : 0.04
    const costPerSMS = userFullInfo.costPerSMS ? userFullInfo.costPerSMS : 0.04
    const highCost = costPerRBM >= costPerSMS ? costPerRBM : costPerSMS
    const minBalance =
      typeof userFullInfo.minBalance !== 'undefined'
        ? userFullInfo.minBalance
        : balance

    if (balance < highCost * stat.users) {
      const capacity = Math.floor(balance / highCost)
      setResultContent({
        title: i18n.t('insufficient_balance'),
        content: i18n.t('numbers_capacity', { capacity: capacity }),
        cta: 'Error'
      })
      return false
    }
    if (minBalance < highCost * stat.users) {
      setResultContent({
        title: i18n.t('insufficient_min_balance'),
        content: i18n.t('wait_charge'),
        cta: 'Error'
      })
      return false
    }
    return true
  }

  useEffect(() => {
    getUserFullInfo()
  }, [])

  const campaignLaunch = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const tokenReq = user.signInUserSession.idToken.jwtToken
    let scheduleDateTime = ''
    let endpoint = '/campaign'
    if (scheduled) {
      endpoint = '/schedule'
      scheduleDateTime = new Date(
        new Date(scheduleDate).setHours(scheduleTime.split(':')[0])
      )
      scheduleDateTime = scheduleDateTime
        .setMinutes(scheduleTime.split(':')[1])
        .toString()
    }
    const campaignData = {
      phones: contacts.map((p) => {
        delete p.key
        return p
      }),
      flow: selectedCampaign.initElement,
      schema: selectedCampaign.schema,
      scheduleDateTime: scheduleDateTime
    }

    try {
      const resp = await API.post('rcs-api', '/campaign-file', {
        headers: {
          Authorization: tokenReq
        },
        body: campaignData
      })
      console.log(resp)

      //if too much data use the s3 bucket in server side
      if (campaignData.phones.length > 50) {
        campaignData.phones = []
      }

      campaignData.fileName = resp.fileName
      const campaignResult = await API.post('rcs-api', endpoint, {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        },
        body: campaignData
      })
      console.log('result', campaignResult)
      setResultContent(modalMsgs.post)
      setResultFunction(true)
    } catch (err) {
      setResultContent(modalMsgs.error)
      alert('Campaign not launched \n' + err)
    }
  }

  const handleLaunchClick = async () => {
    if (selectedCampaign && stat?.users > 0) {
      if (hasEnoughBalance()) {
        //setOpen(true)
        //TODO campaignLaunch todavia no esta terminado
        setIsLoading(true)
        setOpenResult(true)
        await campaignLaunch()
        setIsLoading(false)
        setResultContent(modalMsgs.post)
      } else {
        setOpenResult(true)
      }
    }
  }
  const getDistribution = async () => {
    const user = await Auth.currentAuthenticatedUser()
    console.log(user.signInUserSession.idToken.jwtToken)
    try {
      const distribution = await API.post('rcs-api', '/numbers-distribution', {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        },
        body: { phones: contacts.map((c) => c.phone) }
      })
      return distribution
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const handleDistributionClick = async () => {
    const distribution = await getDistribution()
    console.log(distribution)
    let distributionList = []
    if (distribution.numbersRcs && distribution.numbersRcs.length > 0) {
      for (let n of distribution.numbersRcs) {
        console.log("añadimos",n)
        distributionList.push({
          number: n,
          type: 'RCS'
        })
      }
    }
    if (distribution.numbersSms && distribution.numbersSms.length > 0) {
      for (let n of distribution.numbersSms) {
        distributionList.push({
          number: n,
          type: 'SMS'
        })
      }
    }
    console.log("la dist", distributionList);
    const csv = Papa.unparse(distributionList)
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const csvURL = window.URL.createObjectURL(csvData)
    const tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', `distribution.csv`)
    tempLink.click()
  }

  return (
    <div className="px-10 py-5">
      <Modal {...modalContent} open={open} setOpen={setOpen} />
      <ResultModal
        {...resultContent}
        isLoading={isLoading}
        open={openResult}
        setOpen={setOpenResult}
        campaignLaunch={campaignLaunch}
        method={resultFunction ? goStatistics : null}
      />

      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              {i18n.t('campaign')}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{selectedCampaign?.name}</span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              {i18n.t('contacts')}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">{stat?.users}</span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              {i18n.t('price_sms')}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">
                {userFullInfo.costPerSMS ? userFullInfo.costPerSMS : 0.04} €
              </span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              {i18n.t('price_rbm')}
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="flex-grow">
                {userFullInfo.costPerRBM ? userFullInfo.costPerRBM : 0.04} €
              </span>
            </dd>
          </div>

          <div className="flex flex-col justify-between py-4 sm:py-5">
            <div className="flex items-center">
              <input
                id="scheduled"
                name="scheduled"
                type="checkbox"
                className="h-4 w-4 text-blue-dark border-gray-300 rounded"
                checked={scheduled}
                onChange={() => setScheduled(!scheduled)}
              />
              <label
                htmlFor="rsceduled"
                className="ml-2 block text-sm font-medium text-gray-500"
              >
                {/* Remember me */}
                {i18n.t('scheduled')}
              </label>
            </div>
            {scheduled && (
              <div className="flex w-2/3 justify-between self-center">
                <div className="datepicker relative form-floating mt-3 xl:w-96">
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Fecha de envio
                  </label>
                  <input
                    type="date"
                    className="block w-full rounded-md border-gray-300 pl-3 py-2 pr-12 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Select a date"
                    value={scheduleDate}
                    onChange={(e) => setScheduleDate(e.target.value)}
                  />
                </div>
                <div className="datepicker relative form-floating mt-3 xl:w-96">
                  <label
                    htmlFor="time"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Hora de envio
                  </label>
                  <input
                    type="time"
                    className="block w-full rounded-md border-gray-300 pl-3 py-2 pr-12 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Select a date"
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">
              <span className="flex-grow">
                <button
                  type="button"
                  className={classNames(
                    selectedCampaign && stat?.users > 0
                      ? 'bg-blue-dark hover:bg-indigo-700'
                      : 'cursor-not-allowed',
                    'bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                  )}
                  onClick={() => handleDistributionClick()}
                >
                  Get distribution
                </button>
              </span>
            </dt>
            <dt className="text-sm font-medium text-gray-500">
              <span className="flex-grow">
                <button
                  type="button"
                  className={classNames(
                    selectedCampaign && stat?.users > 0
                      ? 'bg-blue-dark hover:bg-indigo-700'
                      : 'cursor-not-allowed',
                    'bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                  )}
                  onClick={() => handleLaunchClick()}
                >
                  {i18n.t('launch')}
                </button>
              </span>
            </dt>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default Send
