export const getReplyOptions = (rbmFlow, rbmFlowAliases, flowId) => {
  const otherFlows = { ...rbmFlow }
  delete otherFlows[flowId]
  delete otherFlows['init']
  return Object.keys(otherFlows).map((flow) => {
    return {
      value: flow,
      label: rbmFlowAliases[flow] || 'Response ' + flow.split('response')[1]
    }
  })
}
