import React from 'react'

const BotPreview = ({ name, description, profilePicture, bannerPicture }) => {
  const profilePreview = profilePicture
    ? typeof profilePicture === 'string'
      ? profilePicture
      : URL.createObjectURL(profilePicture)
    : ''

  const bannerPreview = bannerPicture
    ? typeof bannerPicture === 'string'
      ? bannerPicture
      : URL.createObjectURL(bannerPicture)
    : ''

  return (
    <div className=" flex flex-col relative phone w-full max-w-[300px] min-w-[300px] border-4 border-grey-blue/30  rounded-3xl shadow-2xl bg-white overflow-hidden">
      <div
        id="bannerPicture"
        style={{
          aspectRatio: '1440/448'
        }}
        className="w-full bg-blue-light text-black text-center font-semibold relative "
      >
        {bannerPreview !== '' ? (
          <img src={bannerPreview} alt="banner Image" />
        ) : null}
        <div
          id="profilePicture"
          style={{
            aspectRatio: '224/224'
          }}
          className={`w-14 rounded-full bg-blue-light border-2 border-white absolute top-0 left-1/2 transform -translate-x-1/2 translate-y-full overflow-hidden`}
        >
          {profilePreview !== '' ? (
            <img src={profilePreview} alt="profile Image" />
          ) : null}
        </div>
      </div>
      <div id="BotInfo" className="flex flex-col p-4 gap-2 mt-5">
        <p className="text-lg font-semibold text-black text-center">
          {name ? name : 'Name'}
        </p>
        <p className="text-sm text-gray-400 text-center">
          {description ? description : 'Description'}
        </p>
      </div>
    </div>
  )
}

export default BotPreview
