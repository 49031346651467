import React, { useState, useEffect, Fragment } from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LanguageDropdown from '../../components/languageDropdown'
import countries from '../../config/countries'
import { API, Auth } from 'aws-amplify'
import CountryPicker from '../../components/CountryPicker'
import { useTranslation } from 'react-i18next'
import ResultModal from '../../components/ResultModal'
import { useAppContext } from '../../libs/context'
import { dateToString } from '../../libs/date'
import Spin from '../../components/Spin'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'
import CreateCampaign from './Create'
import DeleteModal from './DeleteModal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Templates = () => {
  const { campaignsTypes, setCampaignsTypes } = useAppContext()
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const search = window.location.search
  const qParams = new URLSearchParams(search)
  const templateName = qParams.get('name')
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteTempalte, setDeleteTemplate] = useState(null)

  const getCampaignsTypes = async () => {
    setLoading(true)
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const req = await API.get('rcs-api', '/campaigns-types', {
        headers: {
          Authorization: tokenReq
        }
      })
      const cleanData = req.map((item) => {
        const parseText = JSON.parse(item.elements)
        let keys = Object.keys(parseText).find((key) => key.includes('init-1'))
        let text
        if (!keys) {
          keys = Object.keys(parseText).find((key) => key.includes('initSMS'))
          text = parseText[keys]
        } else {
          if (parseText[keys].RCSMessage) {
            text = parseText[keys].RCSMessage.textMessage
          } else if (parseText[keys].contentMessage) {
            text = parseText[keys].contentMessage.text
          } else {
            text = parseText[keys].messageText
          }
        }
        item.text = text?.length > 20 ? `${text.substr(0, 50)}...` : text
        const parseDate = dateToString(item.timestamp)
        item.date = parseDate.dayMin
        item.time = parseDate.time
        item.initElement = keys
        item.schema = item.schema ? item.schema : 'basic'
        return item
      })
      console.log(cleanData)
      if (templateName) {
        setSelectedTemplate(cleanData.find((cd) => cd.name === templateName))
      }
      setTemplates(cleanData)
    } catch (err) {
      console.log('error', err)
      setErrorMessage('There were problems loading the campaign types')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!campaignsTypes || !campaignsTypes.length) {
      getCampaignsTypes()
    } else {
      setSelectedTemplate(campaignsTypes.find((ct) => ct.name === templateName))
      setTemplates(campaignsTypes)
    }
  }, [])

  return (
    <>
      {loading ? (
        <div className="flex justify-center my-16">
          <Spin type="lightBg" />
        </div>
      ) : selectedTemplate ? (
        <CreateCampaign
          setSelectedTemplate={setSelectedTemplate}
          template={selectedTemplate}
          setTemplates={setTemplates}
        />
      ) : (
        <div className="space-y-4">
          <DeleteModal
            open={openDelete}
            setOpen={setOpenDelete}
            template={deleteTempalte}
            setTemplates={setTemplates}
          />
          <Link to={'/createCampaign'}>
            <button
              type="button"
              className={
                'bg-blue-dark hover:bg-indigo-700 bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
              }
            >
              {i18n.t('create_template')}
            </button>
          </Link>
          {templates.map((campaign) => (
            <div
              key={campaign.name}
              value={campaign}
              className={
                'border-gray-300 relative block bg-white border rounded-lg shadow-sm px-6 py-4 sm:flex sm:justify-between focus:outline-none'
              }
            >
              <>
                <span className="flex items-center w-full">
                  <span className="text-sm flex flex-col">
                    <div as="span" className="font-medium text-grey-blue">
                      {campaign.name}
                    </div>
                    <div as="span" className="text-gray-500">
                      <span className="block sm:inline">{campaign.text}</span>
                    </div>
                  </span>
                </span>
                <div
                  as="span"
                  className="mt-2 flex text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                >
                  <span className="font-medium text-grey-blue">
                    {campaign.date}
                  </span>
                  <span className="ml-1 text-gray-500 sm:ml-0">
                    {campaign.time}
                  </span>
                </div>

                <div
                  as="span"
                  className="mt-2 flex self-center text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                >
                  <Link to={`/templates?name=${campaign.name}`}>
                    <button
                      onClick={() => {
                        setSelectedTemplate(campaign)
                      }}
                      className="flex-shrink-0 text-blue-800 flex items-center justify-center w-10"
                    >
                      <PencilSquareIcon className="h-6 w-6 align-center" />
                    </button>
                  </Link>
                </div>
                <div
                  as="span"
                  className="mt-2 flex self-center text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                >
                  <button
                    onClick={() => {
                      setDeleteTemplate(campaign)
                      setOpenDelete(true)
                    }}
                    className="flex-shrink-0 text-blue-800 flex items-center justify-center w-10"
                  >
                    <TrashIcon className="h-6 w-6 align-center" />
                  </button>
                </div>
                <span
                  className={
                    'border-2 border-transparent absolute -inset-px rounded-lg pointer-events-none'
                  }
                  aria-hidden="true"
                />
              </>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default Templates
