import React from 'react'

import RbmTypeCard from './RbmTypeCard'
import { RBM_TYPES } from './constants'
import {
  RbmBasicTypeSvg,
  RbmConversationalTypeSvg,
  RbmSimpleTypeSvg
} from '../../../../components/Icons'

export default function SelectRbmType({ setRbmType }) {
  return (
    <div className="w-full">
      <h2 className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4">
        Select your campaign type
      </h2>
      <div className="flex justify-between gap-2">
        <RbmTypeCard
          cardImage={RbmSimpleTypeSvg}
          setRbmType={setRbmType}
          typeInfo={RBM_TYPES[0]}
        />
        <RbmTypeCard
          cardImage={RbmBasicTypeSvg}
          setRbmType={setRbmType}
          typeInfo={RBM_TYPES[1]}
        />
        <RbmTypeCard
          cardImage={RbmConversationalTypeSvg}
          setRbmType={setRbmType}
          typeInfo={RBM_TYPES[2]}
        />
      </div>
    </div>
  )
}
