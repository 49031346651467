import React, { useState, useEffect, Fragment } from 'react'
import { API, Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import {
  Bars3Icon,
  XMarkIcon,
  PaperAirplaneIcon,
  ChartPieIcon,
  HomeIcon,
  UsersIcon,
  PlusIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { AppContext, useAppContext } from '../libs/context'
import Idletime from './Idletime'
import currencies from '../config/currency'
import { useTranslation } from 'react-i18next'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TITLE_MAP = {
  createCampaign: 'Create Template',
  editTemplate: 'Edit Template',
  profile: 'Profile',
  'bot-configuration': 'Bot Configuration'
}

const Layout = (p) => {
  const { t, i18n } = useTranslation()

  const navigation = [
    // {
    //   name: 'Dashboard',
    //   href: '/dashboard',
    //   current: false,
    //   icon: HomeIcon
    // },
    {
      name: 'campaigns',
      href: '/campaign',
      current: false,
      icon: PaperAirplaneIcon
    },
    {
      name: 'templates',
      href: '/templates',
      current: false,
      icon: DocumentIcon
    },
    {
      name: 'statistics',
      href: '/statistics',
      current: false,
      icon: ChartPieIcon
    },
    {
      name: 'Top Ups',
      href: '/topups',
      current: false,
      icon: CurrencyDollarIcon
    }
  ]

  const { setIsAuthenticated, userInfo } = useAppContext()
  const contx = useAppContext()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currentNav, setCurrentNav] = useState(navigation)
  const [balance, setBalance] = useState(0)
  const [currency, setCurrency] = useState(currencies['EUR'])
  const history = useHistory()

  // const logOut = async () => {
  //   await Auth.signOut()
  //   setIsAuthenticated(false)
  //   history.push('/login')
  // }

  const userNavigation = [
    { name: i18n.t('profile'), href: '/profile' },
    { name: 'Sign out', href: '/login' }
  ]

  const updateNav = (newHref) => {
    console.log(newHref)
    const navChanged = [...currentNav]
    navChanged.forEach((item) => {
      if (item.href === newHref) {
        item.current = true
      } else {
        item.current = false
      }
    })
    setCurrentNav(navChanged)
  }

  const getBalance = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const userFullInfo = await API.get('rcs-api', '/user', {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
    setBalance(userFullInfo.balance ? userFullInfo.balance : 1)
    setCurrency(
      userFullInfo.currency
        ? currencies[userFullInfo.currency]
        : currencies['EUR']
    )
  }

  async function logOut() {
    await Auth.signOut()
    setIsAuthenticated(false)
    history.push('/login')
  }

  useEffect(() => {
    const navChanged = [...navigation]

    if (userInfo.isAdmin) {
      navChanged.push({
        name: 'Clients',
        href: '/clients',
        current: false,
        icon: UsersIcon
      })
    }
    const currentPath = window.location.pathname.split('/').pop()
    const currentPathWithoutQuery = currentPath.split('?')[0]
    const matchingNavItem = navChanged.find(({ href }) =>
      href.split('?')[0].includes(currentPathWithoutQuery)
    )

    if (matchingNavItem) {
      matchingNavItem.current = true
    }

    setCurrentNav(navChanged)
    if (!userInfo.isAdmin) {
      getBalance()
    }
  }, [])

  return (
    <>
      <AppContext.Provider
        value={{
          ...contx,
          updateNav,
          setBalance
        }}
      >
        <Idletime logOutAction={logOut} />
        <div className="min-h-full">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                      <div className="flex flex-shrink-0 items-center px-4">
                        <img
                          src="/assets/imgs/logo-tadoo-white.svg"
                          style={{
                            width: '300px',
                            padding: '50px 50px 10px 0'
                          }}
                          alt="logo"
                        />
                      </div>
                      <nav className="mt-5 space-y-1 px-2">
                        {currentNav.map((item) => (
                          <button
                            key={item.name}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-gray-300'
                                  : 'text-gray-400 group-hover:text-gray-300',
                                'mr-4 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                    {/* <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <a href="#" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <p className="inline-block h-10 w-10 rounded-full">
                            {userInfo && userInfo.email.charAt(0).toUpperCase()}
                          </p>
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-white">
                            Tom Cook
                          </p>
                          <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                            View profile
                          </p>
                        </div>
                      </div>
                    </a>
                  </div> */}
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0"></div>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-[2]">
            <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
              <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    src="/assets/imgs/logo-tadoo-white.svg"
                    style={{ width: '120px' }}
                    alt="logo"
                  />
                </div>
                <nav className="mt-5 flex-1 space-y-1 px-2">
                  {currentNav.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )}
                      onClick={() => {
                        updateNav(item.href)
                      }}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-300'
                            : 'text-gray-400 group-hover:text-gray-300',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {i18n.t(item.name)}
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="flex flex-shrink-0 bg-gray-700 p-4">
                <Menu as="div" className="relative ml-3">
                  <div className="flex justify-center items-center">
                    <Menu.Button className="flex max-w-xs rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-dark focus:ring-offset-2">
                      <p className="flex h-9 w-9 justify-center items-center text-lg text-blue-dark text-bold">
                        {userInfo && userInfo.email.charAt(0).toUpperCase()}
                      </p>
                    </Menu.Button>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-white capitalize">
                        {userInfo && userInfo.email.split('@')[0]}
                      </p>
                      {/* <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      View profile
                    </p> */}
                    </div>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-12 -top-10 z-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-grey-blue hover:text-blue-dark'
                              )}
                              onClick={async () => {
                                if (item.href === '/login') {
                                  await Auth.signOut()
                                  setIsAuthenticated(false)
                                }
                                updateNav(item.href)
                              }}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                {!userInfo.isAdmin && (
                  <div className="absolute right-5 bottom-2 text-white text-xs">
                    <p>{i18n.t('balance') + balance + ' ' + currency.symbol}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="h-screen flex flex-1 flex-col md:pl-64">
            <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <main className="flex-1 bg-white">
              <div className="p-10">
                <div className="mx-auto max-w-6xl">
                  <h1 className="text-4xl font-semibold text-grey-blue">
                    {i18n.t(
                      `${
                        currentNav.find(({ current }) => current)?.name
                          ? currentNav.find(({ current }) => current).name
                          : TITLE_MAP[window.location.href.split('/').pop()]
                      }`
                    )}
                  </h1>
                </div>
                <div className="mx-auto max-w-6xl py-4 sm:py-6 md:py-8 rounded-md">
                  {p.content}
                </div>
              </div>
            </main>
          </div>
        </div>
      </AppContext.Provider>
    </>
  )
}

export default Layout
