import React from 'react'

export default function RbmTypeCard({ cardImage, setRbmType, typeInfo }) {
  const CardImage = cardImage
  return (
    <div
      onClick={() => setRbmType(typeInfo.type)}
      id="rbmTypeCard"
      className="w-[300px] h-[400px] rounded-2xl overflow-hidden border-4 border-blue-300 hover:scale-105 cursor-pointer transition-all"
    >
      <div
        id="typeImage"
        className="h-1/2 px-8 py-4 w-full flex justify-center items-center bg-blue-50"
      >
        <CardImage />
      </div>
      <div id="typeInfo" className="p-4">
        <h2
          id="typeName"
          className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4"
        >
          {typeInfo.name}
        </h2>
        <span id="typeDescription" className="text-grey-blue">
          {typeInfo.description}
        </span>
      </div>
    </div>
  )
}
