import React, { useState, useEffect, Fragment } from 'react'
import { API, Auth } from 'aws-amplify'
import { useAppContext } from '../../libs/context'
import Spin from '../../components/Spin'
import { Pagination, Tooltip } from 'antd'
import currencies from '../../config/currency'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import i18n from '../../i18n'
import { Link, useParams } from 'react-router-dom'
import AddMoneyModal from './AddMoneyModal'
import ResultModal from '../../components/ResultModal'

const formatDate = (date) => {
  const objectDate = new Date(date)

  let day = objectDate.getDate()
  let month = objectDate.getMonth() + 1
  let year = objectDate.getFullYear()

  const formatedDate = day + '/' + month + '/' + year
  return formatedDate
}

const modalMsg = {
  ok: {
    title: i18n.t('operation_performed'),
    content: i18n.t('pay_done'),
    cta: 'Ok'
  },
  error: {
    title: 'Error',
    content: i18n.t('error_operation'),
    cta: 'Error'
  },
  processing: {
    title: i18n.t('operation_performed'),
    content: "Payment processing. We'll update you when payment is received.",
    cta: 'Ok'
  }
}

const TopUps = () => {
  const [topUps, setTopUps] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [topUpsPerPage, setTopUpsPerPage] = useState(10)
  const [userFullInfo, setUserFullInfo] = useState(null)
  const [open, setOpen] = useState(false)
  const { userInfo } = useAppContext()
  const indexOfLast = currentPage * topUpsPerPage
  const indexOfFirst = indexOfLast - topUpsPerPage

  const getTopUps = async () => {
    setLoading(true)
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const result = await API.get('rcs-api', '/topups', {
        headers: {
          Authorization: tokenReq
        }
      })
      setTopUps(result)
    } catch (err) {
      console.log("error con los toputps")
    }
    setLoading(false)
  }

  const handleAddMoney = async () => {
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const resp = await API.get('rcs-api', '/user', {
        headers: {
          Authorization: tokenReq
        }
      })
      setUserFullInfo(resp)
      setOpen(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTopUps()
  }, [])

  return (
    <>
      {loading ? (
        <div className="flex justify-center my-16">
          <Spin type="lightBg" />
        </div>
      ) : (
        <div>
          {userFullInfo &&
            <AddMoneyModal
              open={open}
              client={userFullInfo}
              setOpen={setOpen}
              setTopUps={setTopUps}
            />}
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <button
                  type="button"
                  className={
                    'bg-blue-dark hover:bg-indigo-700 bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                  }
                  onClick={() => handleAddMoney()}
                >
                  {i18n.t('add_money')}
                </button>
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-5">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        {userInfo.isAdmin && (
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Client
                          </th>
                        )}
                        {userInfo.isAdmin && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Top Up Id
                          </th>
                        )}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {i18n.t('concept')}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {i18n.t('amount')}
                        </th>
                        <th
                          scope="col"
                          className="pl-3 pr-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          {i18n.t('date')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {topUps
                        .slice(indexOfFirst, indexOfLast)
                        .map((topUp, topUpIdx) => (
                          <tr
                            key={topUp.topUpId}
                            className={
                              topUpIdx % 2 === 0 ? undefined : 'bg-gray-50'
                            }
                          >
                            {userInfo.isAdmin && (
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {topUp.username !== ''
                                  ? topUp.username
                                  : 'No asignado'}
                              </td>
                            )}
                            {userInfo.isAdmin && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <Tooltip
                                  placement="right"
                                  title={`${topUp.topUpId}`}
                                >
                                  <a>
                                    <InformationCircleIcon className="h-6 w-6" />
                                  </a>
                                </Tooltip>
                              </td>
                            )}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {topUp.concept}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {topUp.amount +
                                ' ' +
                                currencies[topUp.currency].symbol}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {formatDate(topUp.paymentDate)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            className="mt-3"
            total={topUps.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            defaultPageSize={topUpsPerPage}
            defaultCurrent={1}
            current={currentPage}
            onChange={setCurrentPage}
            onShowSizeChange={(current, size) => setTopUpsPerPage(size)}
          />
        </div>
      )}
    </>
  )
}
export default TopUps
