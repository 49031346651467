const config = {
  dev: {
    apiGateway: {
      URL: 'https://dev-rcs.tadoo.biz',
      REGION: 'eu-west-3'
    },
    cognito: {
      REGION: ' eu-west-3',
      USER_POOL_ID: 'eu-west-3_UWW4wlczz',
      APP_CLIENT_ID: '3v0gbrmv32m2ikiifraj1eo9fm',
      IDENTITY_POOL_ID: 'eu-west-3:74b89a7f-bd51-47e6-b669-232f420bbbad'
    }
  },
  prod: {
    apiGateway: {
      URL: 'https://rcs.tadoo.biz',
      REGION: 'eu-west-3'
    },
    cognito: {
      REGION: 'eu-west-3',
      USER_POOL_ID: 'eu-west-3_iS5G8xV8c',
      APP_CLIENT_ID: '1cr1mbvj9j2kftl263q1pvj82',
      IDENTITY_POOL_ID: 'eu-west-3:9cd5269a-bb78-4699-bd05-42d3f169fbb3'
    }
  }
}

export default {
  ...config[process.env.REACT_APP_STAGE]
}
