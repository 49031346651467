export const esp = {

  //components/Layout.js
  campaigns: "Campañas",
  statistics: "Estadísticas",
  create_campaign: "Crear campaña",
  balance: "Saldo : ",
  profile: "Perfil",

  //scenes/Access/ComfirmCodeForm.js
  sent_you_code: "Te hemos enviado un código",
  use_the_code: "Usa el código que te hemos enviado al email",
  code: "código",
  atention: "Atención",
  processing: "Procesando...",
  confirm_code: "Confirma el código",

  //scenesAccess/Login.js
  log_in: "Logeate con tu cuenta",
  or: "O",
  sign_up: "regístrate con una nueva cuenta",
  password: "Contraseña",
  remember_me: "Recuerdame",
  sign_in: "Iniciar sesión",
  forgot_password: "Olvidaste la contraseña?",

  //scenes/Access/Register.js
  sign_up_register: "Regístrate con una nueva cuenta",
  log_in_register: "logeate con tu cuenta",
  confirm_password: "Confirma la contraseña",
  sign_up_button: "Regístrate",
  country_prefix: "Prefijo del país",
  gdpr_accept:
    'He leído y acepto la <link1>Política de privacidad</link1> y el <link2>Aviso Legal</link2>',
    
  //scenes/Access/ForgotPassword.js
  reset_password: "Restablecer la contraseña",
  send_email: "Enviar email",
  wrong_email: "No se ha encontrado cuenta con este email",

  //scenes/Access/ForgotPassword.js
  reset_password_done: "La contraseña se ha actualizado",
  password_not_match: "Las contraseñas no coinciden",
  wrong_code: "Ha habido un problema, por favor compruebe que el código es correcto e intentelo de nuevo",

  //scenes/Campaign/Campaign.js
  select_campaign: "Selecciona campaña",
  select_template :"Selecciona plantilla",
  upload_contacts: "Cargar contactos",
  send: "Enviar",
  total_subscribers: "Subscriptores totales",

  //scenes/Campaign/Select.js
  campaign: "Campaña",
  next: "Siguiente",

  //scenes/Campaign/Upload.js
  file_not_valid: "El archivo no es válido",
  upload_file: "Subir archivo",
  formats_acepted: "Los formatos aceptados son JSON y CSV",
  add_contacts: "Añadir contactos",
  csv_format: "Formato de CSV :",
  json_format: "Formato de JSON :",
  close: "Cerrar",
  be_warmed: "Cuidado",
  missing_variables: "Algunos de tus contactos no tienen al menos una de las variables que estás utilizando en los textos de tu campaña:",
  check_the_file: "Esto podría causar problemas durante el lanzamiento. Por favor, revisa el archivo que estás utilizando.",
  

  //components/InvalidNumbersModal.js
  warning: "Advertencia",
  invalid_numbers: "Hay {{invalidContacts}} números que no cumplen los criterios de validación (la variable \"phone\" es obligatoria para los contactos) y {{duplicatedContacts}} números duplicados, estos números no se añadirán a la campaña.",

  //scenes/Campaign/Send.js
  campaign_launched: "Tu campaña se ha lanzado",
  check_stats: "Puedes comprobar la sección de estadisticas para revisar los datos generados",
  error_launching: "Error lanzando la campaña",
  could_not_launch: "La campaña no se ha podido lanzar, por favor comprueba el error y contactanos a support@tadoo.biz.",
  insufficient_balance: "Saldo insuficiente",
  numbers_capacity: "Tienes salo para enviar a {{capacity}} números, por favor elimine los números que correspondan de su archivo.",
  insufficient_min_balance : "Saldo no calculado",
  wait_charge : "Todavia no se ha calculado su nuevo saldo después de su última campaña lanzada, porfavor vuelva a intentarlo más tarde.",
  contacts: "Contactos",
  price_sms: "Precio por SMS",
  price_rbm: "Precio por RBM",
  launch: "Lanzar",
  scheduled: "Programar campaña",

  //scenes/Campaign/Create.js
  name: "Nombre",
  error_space_name: "El nombre de la campaña no puede tener espacios o guiones",
  error_empty_name: "El nombre de la campaña es obligatorio",
  error_empty_sms: "El mensaje sms no puede estar vacío",
  error_invalid_variable: "La variable debe teener un nombre por ejemplo: {nombre}",
  campaign_name: "Nombre de campaña",
  campaign_type: "Tipo de mensaje",
  rbm_alert: "Si quieres crear una campaña RBM por favor contacta a support@tadoo.biz para que podamos ayudarte.",
  operation_performed: "Operación realizada",
  created_campaign: "La campaña se ha creado y añadido a tu lista de campañas listas para lanzar.",
  error_creating_campaign: "La campaña no se ha podido crear, por favor compruebe la información introducida y si el error persiste contacta a support@tadoo.biz para mas información",
  error_invalid_sender: "Remitente invalido",
  error_empty_sender: "Remitente obligatorio",
  sender_validations: "Debe ser numérico con un rango de longitud entre 10 y 16 dígitos (solo para destinos no griegos) o alfanumérico hasta 11 caracteres latinos (incluidos los caracteres especiales !:;+.-)",
  sender: "Remitente",
  update_template: "Actualizar plantilla",

  //scenes/Campaign/CreateModal/NewSmsForm.js
  to_add_variables: "Para añadir variables, rodea el nombre de la variable con llaves como en el ejemplo: \n\"{variable}\"",
  text: "Texto",
  characters_used: "Carácteres usados: ",
  codification_type: "Tipo de codificación: ",
  available_chars: "Caracteres disponibles: ",
  number_of_sms: "Número de SMS's: ",
  sms_could_increase: "El número de sms puede incrementar dependiendo de el número de variables y su longitud.",
  message_text: "Texto del mensaje",

  //scenes/TopUps/TopUps.js
  concept: "Concepto",
  amount: "Cantidad",
  date: "Fecha",
  add_money: "Añadir dinero",
  error_operation: "No se ha podido realizar la operación, por favor revise los datos introducidos y si el error persiste contacte con support@tadoo.biz para más información",

  //scenes/TopUps/TopUps.js
  error_amount_format: "Este formato no es valido",
  error_minimum_amount: "La cantidad es inferior al minimo : {{minimum}}",
  error_amount_decimals: "Esta dibisa (\"{{currency}}\") no acepta decimales",
  pay_done: "Se ha realizado el pago correctamente y se le ha añadido a su balance total.",

  //scenes/Statistics/General.js
  sent: "Enviado",
  delivered: "Entregado",
  not_delivered: "No entregado",
  rbm_delivered: "RBM entregado",
  rbm_not_delivered: "RBM no entregado",
  sms_delivered: "SMS entregado",
  sms_not_delivered: "SMS no entregado",
  unsubscriptioins: "Bajas",
  not_unsubscriptioins: "Sin bajas",
  rbm_unsubscriptioins: "RBM con bajas",
  rbm_not_unsubscriptioins: "RBM sin bajas",
  sms_unsubscriptioins: "SMS con bajas",
  sms_not_unsubscriptioins: "SMS sin bajas",
  general_statistics: "Estadísticas generales",
  stats_by_states: "Estadísticas por estados",
  all_stats_rbm_sms: "Envios, entregas y bajas de RBM y SMS",
  deliveries_percentage: "Porcentaje de entregas",
  deliveries_percentage_sms_rbm: "Porcentaje de entregas de RBM y SMS ",
  deliveries_relation_sent: "Porcentaje de entregas en relación a envios.",
  deliverie_report: "Reporte de entregas",
  not_deliverie_report: "Reporte de no entregados",
  deliveries_rbm_relation_sent: "Porcentaje de RBM Entregas en relación al número de envios.",
  rbm_delivered_report: "Reporte de entregas de RBM",
  rbm_not_delivered_report: "Reporte de RBM no entregados",
  deliveries_sms_relation_sent: "Porcentaje de SMS entregados en relación al número de envios.",
  sms_delivery_report: "Reporte de SMS entregados",
  sms_not_delivery_report: "Reporte de SMS no entregados",
  percentage_unsubs: "Porcentaje de bajas",
  percentage_unsubs_sms_rbm: "Porcentaje de bajas de RBM y SMS",
  percentage_unsub_relation_delivery: "Porcentaje de bajas en relación al número de entregas.",
  unsub_report: "Reporte de bajas",
  not_unsub_report: "Reporte de no dados de baja",
  rbm_unsubs_relation_delivereies: "Porcentajes de bajas en RBM en relación al número de entregas.",
  rbm_unsub_report: "Reporte de bajas por RBM",
  rbm_not_unsub_report: "Reporte de no dados de bajas por RBM",
  sms_unsubs_relation_delivereies: "Porcentaje de bajas en SMS en relación al número de entregas.",
  sms_unsub_report: "Reporte de bajas por SMS",
  sms_not_unsub_report: "Reporte de no dados de bajas por SMS",
  print_statistics: "Imprimir estadísticas",
  print_without_menus: "Imprima las estadísticas sin los menús, los márgenes exteriores y los botones de informe. ",

  //scenes/Statistics/Interactions.js
  step_visualizations: "Paso {{step}} visualizaciones",
  step_deliveries: "Paso {{step}} entregados",
  first_stp_deliveries: "Entregas 1er paso",
  first_stp_visualizations: "Visualizaciones 1er paso",
  second_stp_deliveries: "Entregas 2º paso",
  second_stp_visualizations: "Visualizaciones 2º paso",
  objectives_achived: "objetivos alcanzados",
  deliveries: "Entregas",
  visualizations: "Visualizaciones",
  unique: "Único",
  elems_touched: "Elementos tocados",
  unique_touch_suggs: "Toques únicos en sugerencias",
  total_touch_suggs: "Toques totales en sugerencias",
  unique_touch_objectives: "Toques únicos en objetivos",
  total_touch_objectives: "Toques totales en objetivos",
  rbm_visualization: "RBM con visualizaciones",
  rbm_not_visualization: "RBM sin visualizaciones",
  rbm_touched: "Elementos RBM tocados",
  rbm_not_touched: "Elementos RBM no tocados",
  rbm_objectives: "RBM con objetivos",
  rbm_not_objectives: "RBM sin objetivos",
  rbm_stats: "Estadístias RBM",
  rbm_funnel_stats: "Funnel de status de RBM",
  funnel_content: "Funnel de entregas, visualizaciones y objetivos alcanzados úincos",
  percentage_all_interactions: "Porcentaje de elementos vistos, clicados y objetivos alcanzados",
  percentage_unique_all: "Los porcentajes de elementos vistos, toques únicos y objetivos únicos logrados",
  percentage_displayed: "Porcentaje de mostrado",
  displayed_respect_delivery: "Elementos visualizados en relación a entregados.",
  unique_visualizations: "Reporte de visualizaciones únicas",
  no_visualizations_report: "Reporte de no visualizaciones",
  percentage_pulsations: "Porcentaje de pulsacionens",
  unique_touch_compared_displayed: "Porcentaje de elementos pulsados únicos en relación a visualizaciones.",
  unique_pulsations: "Reporte de pulsaciones únicas",
  no_pulsations: "Re porte de no pulsados",
  percentaje_objectives: "porcentaje de objetivos",
  unique_objectives_relation_displayed: "Porcentaje de objetivos únicos logrados en relación con el número de elementos mostrados.",
  objectives_achived_report: "Reporte de objetvos alcanzados únicos",
  objectives_not_achived_report: "Reporte de objetivos no alcanzados",
  total_unique_interactions: "Interaciones totales y únicas",
  elems_touch__total_unique_goals: "Estadistica de elementos pulsados y objetivos alcanzados únicos y totales",
  unique_interactions: "Reporte de interacciones únicas",
  total_interactions: "Reporte de interacciones totales",
  interactions_stats: "Estadisticas de elementos de interacción",
  all_stats_per_elem: "Entregas, visualizaciones y pulsaciones únicas y totales para cada elemento de interacción",
  unique_interactions_per_elem: "Interacciones únicas para cada elemento de interacción",
  views_per_elem: "Estadísticas de visualizaciones para cada elemento.",
  unique_pulsations_per_elem: "Estadísticas de pulsaciones únicas para cada elemento de interacción..",
  unique_interaction_elem: "Reporte de elementos de interacción únicos",
  total_interactions_per_elem: "Interacciones totales para cada elemento de interacción",
  total_pulsations_per_elem: "Estadísticas de pulsaciones totales para cada elemento de interacción",
  total_interactions_elem_report: "Reporte de elementos de interacción total",

  //scenes/Statistics/Statistics.js
  no_informatioin: "Sin información",
  language: "Idioma: ",
  refresh: "Actualizar",

  //scenes/Statistics/Profile.js
  save_changes: "Guardar cambios",
  cancel: "Cancelar",
  profile_privacy: "Esta información se mostrará públicamente, así que tenga cuidado con lo que comparte.",
  profile_changed: "Se ha actualizado la información de su perfil correctamente",

  //scenes/Templates/Templates.js
  templates: "Plantillas",
  create_template: "Crear plantilla",
  delete_template: "Eliminar plantilla",
  are_you_sure_delete: "Esta seguro de querer eliminar la plantilla \"{{name}}\" ?",

  //scenes/Integration/Integration.js
  integration_hubspot_ok: "¡Conexión con HubSpot realizada!"
}