import React, { useEffect, useState } from 'react'
import FlowBuilder from './FlowBuilder'
import { CARD_TYPE, CAROUSEL_TYPE, REPLY_BUTTON } from './constants'
import { use } from 'i18next'

const getRbmElements = (rbmElements) => {
  const groupedElements = {}
  const rbmElementsCopy = { ...rbmElements }
  delete rbmElementsCopy.rbmFlowAliasesMap
  Object.keys(rbmElementsCopy)
    .map((key) => key.split('-')[0])
    .forEach((flow) => {
      if (!groupedElements[flow]) {
        groupedElements[flow] = []
        const flowLength = Object.keys(rbmElementsCopy).filter(
          (key) => key.split('-')[0] === flow
        ).length
        for (let i = 0; i < flowLength; i++) {
          groupedElements[flow].push(
            rbmElementsCopy[`${flow}-${i + 1}/${flowLength}`]
          )
        }
      }
    })
  return groupedElements
}

const removeReplyButtonFromCard = (element) => {
  const elementCopy = { ...element }
  if (elementCopy.buttons) {
    elementCopy.buttons = elementCopy.buttons.filter(
      (button) => button.type !== REPLY_BUTTON
    )
  }
  return elementCopy
}

const removeReplyButtonsFromCarousel = (element) => {
  const elementCopy = { ...element }
  elementCopy.cards = elementCopy.cards.map((card) =>
    removeReplyButtonFromCard(card)
  )
  return elementCopy
}

const removeReplyButtonsFromSuggestions = (element) => {
  const elementCopy = { ...element }
  if (elementCopy.suggestions) {
    elementCopy.suggestions = elementCopy.suggestions.filter(
      (suggestion) => suggestion.type !== REPLY_BUTTON
    )
  }
  return elementCopy
}

const removeReplyButtons = (rbmElements) => {
  const rbmElementsCopy = { ...rbmElements }
  for (let key in rbmElementsCopy) {
    if (rbmElementsCopy[key].type === CAROUSEL_TYPE) {
      rbmElementsCopy[key] = removeReplyButtonsFromCarousel(
        rbmElementsCopy[key]
      )
    } else if (rbmElementsCopy[key].type === CARD_TYPE) {
      rbmElementsCopy[key] = removeReplyButtonFromCard(rbmElementsCopy[key])
    }
    rbmElementsCopy[key] = removeReplyButtonsFromSuggestions(
      rbmElementsCopy[key]
    )
  }
  return rbmElementsCopy
}

const cleanRbmElements = (rbmElements) => {
  let rbmElementsCopy = { ...rbmElements }
  delete rbmElementsCopy.rbmFlowAliasesMap
  for (let key in rbmElementsCopy) {
    if (!key.includes('init')) {
      delete rbmElementsCopy[key]
    }
  }
  rbmElementsCopy = removeReplyButtons(rbmElementsCopy)
  return rbmElementsCopy
}

const BasicFlow = ({ rbmElements = {}, setMobileMessages, setRbmElements }) => {
  const [rbmFlows, setRbmFlows] = useState(
    Object.keys(rbmElements).length > 0 ? getRbmElements(rbmElements) : []
  )

  const setFlow = (flowId, newFlow) => {
    const newRbmFlows = { ...rbmFlows, [flowId]: newFlow }
    setRbmFlows(newRbmFlows)
    const newRbmElements = Object.keys(newRbmFlows).reduce((acc, flow) => {
      newRbmFlows[flow].forEach((element, index) => {
        acc[`${flow}-${index + 1}/${newRbmFlows[flow].length}`] = element
      })
      return acc
    }, {})
    setMobileMessages(newRbmFlows['init'] || [])
    setRbmElements(newRbmElements)
  }

  useEffect(() => {
    setMobileMessages(rbmFlows['init'] || [])
  }, [rbmElements])

  return (
    <FlowBuilder
      rbmElements={cleanRbmElements(rbmElements)}
      rbmFlows={rbmFlows}
      setFlow={setFlow}
      flowId={'init'}
      setMobileMessages={setMobileMessages}
    />
  )
}

export default BasicFlow
