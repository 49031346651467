import React from 'react'
import { API, Auth } from 'aws-amplify'
import Papa from 'papaparse'

import {
  Button,
  Badge,
  Row,
  Col,
  Typography,
  Popover,
  Tooltip,
  Card
} from 'antd'

import { Column, Bar, Pie, Funnel } from '@ant-design/charts'
import {
  Column as ColumnPlot,
  Pie as Donut,
  measureTextWidth,
  Line
} from '@ant-design/plots'

import { FUNNEL_CONVERSATION_FIELD } from '@antv/g2plot'

const { Text } = Typography

const colors = {
  rcs: '#6395F9',
  sms: '#ffa940',
  total: '#677898',
  gray: '#aaaaaa',
  darkgray: '#555555',
  sent: '#fa8c16',
  delivered: '#6294F9',
  displayed: '#61D9AB',
  touched: '#FF9D4E',
  goal: '#ffc53d'
}

const StatisticsTitle = ({ title, description }) => {
  const styles = {
    title: {
      margin: 'auto',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      marginBottom: '20px',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc'
    },
    mainTitle: {
      fontSize: '30px',
      margin: '0px'
    },
    description: {
      fontSize: '20px'
    }
  }

  return (
    <div style={styles.title}>
      <h1 style={styles.mainTitle}>{title}</h1>
      <h4 style={styles.description}>{description}</h4>
    </div>
  )
}

const StatisticsSectionTitle = ({ title, description }) => {
  const styles = {
    title: {
      margin: 'auto',
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      marginTop: '40px',
      marginBottom: '20px',
      borderTop: '1px solid #ccc',
      borderBottom: '1px solid #ccc'
    },
    mainTitle: {
      fontSize: '22px',
      margin: '0px'
    },
    description: {}
  }

  return (
    <div style={styles.title}>
      <h1 style={styles.mainTitle}>{title}</h1>
      <h4 style={styles.description}>{description}</h4>
    </div>
  )
}

const StatisticsBar = ({ data, colors }) => {
  const config = {
    data: data,
    isGroup: true,
    xField: 'total',
    yField: 'name',
    seriesField: 'state',
    color: colors,
    dodgePadding: 2,
    intervalPadding: 20,
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' }
      ]
    }
  }

  return <Bar {...config} />
}

const StatisticsElementsBar = ({ data, color }) => {
  const config = {
    data: data,
    xField: 'total',
    yField: 'name',
    seriesField: 'state',
    color: color,
    maxBarWidth: 25,
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }]
    }
  }

  return <Bar {...config} />
}

const StatisticsElementsBarGrouped = ({ data, colors }) => {
  const config = {
    data: data,
    isGroup: true,
    xField: 'total',
    yField: 'name',
    seriesField: 'state',
    color: colors,
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }]
    }
  }

  return <Bar {...config} />
}

const StatisticsColumnSerie = ({ data, colors }) => {
  const config = {
    data: data,
    isGroup: true,
    color: colors,
    xField: 'state',
    yField: 'total',
    seriesField: 'name',
    dodgePadding: 2,
    intervalPadding: 20,
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }]
    }
  }

  return <Column {...config} />
}

const StatisticsColumn = ({ data }) => {
  const config = {
    data: data,
    xField: 'state',
    yField: 'total',
    dodgePadding: 2,
    intervalPadding: 20,
    color: function color(_ref) {
      switch (_ref.state) {
        case 'Entregas':
          return colors.delivered
        case 'Visualizaciones':
          return colors.displayed
        case 'Pulsaciones':
          return colors.touched
        case 'Objetivos':
          return colors.goal
        default:
          return colors.gray
      }
    },
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' }
      ]
    }
  }

  return <Column {...config} />
}

const StatisticsColumnCompared = ({ data }) => {
  const SENT_COLOR = '#2A275D'
  const DELIVERY_COLOR = '#6562BE'
  const VISUALICED_COLOR = '#8584AE'

  const config = {
    data,
    label: {
      stle: {
        fontSize: 40
      }
    },
    xField: 'type',
    yField: 'value',
    columnWidthRatio: 0.9,
    color: ({ type }) => {
      if (type === 'sent') return SENT_COLOR
      if (type === 'delivered') return DELIVERY_COLOR
      if (type === 'visualiced') return VISUALICED_COLOR
      return '#2F46F6'
    },
    conversionTag: {},
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    }
  }
  return <ColumnPlot {...config} />
}

const StatisticsPie = ({ data, colors }) => {
  const config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'name',
    radius: 0.8,
    height: 300,
    legend: {
      layout: 'horizontal',
      position: 'top'
    },
    label: {
      type: 'inner',
      offset: '-30%',
      content: function content(_ref) {
        var percent = _ref.percent
        return ''.concat((percent * 100).toFixed(0), '%')
      },
      style: {
        fontSize: 14,
        textAlign: 'center'
      }
    },
    color: colors,
    interactions: [{ type: 'element-active' }]
  }

  return <Pie {...config} />
}

const StatisticsDonut = ({ data }) => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    )
    const R = containerWidth / 2 // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      )
    }

    const textStyleStr = `width:${containerWidth}px;`
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`
  }
  let deliveries = 0
  let smsOptOut = 0
  let rbmOupout = 0
  for (let element of data) {
    if (element.type === 'Deliveries') deliveries = element.value
    if (element.type === 'SMS Unsubscriptions') smsOptOut = element.value
    if (element.type === 'RBM Unsubscriptions') rbmOupout = element.value
  }

  const ACCEPTANCE_PERCENTAGE = +(
    ((deliveries - smsOptOut - rbmOupout) / deliveries) *
    100
  ).toFixed(2)

  const DELIVERY_COLOR = '#6E6BC2'
  const SMS_COLOR = '#A14242'
  const RBM_COLOR = '#E34A4A'

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    color: ({ type }) => {
      if (type === 'Deliveries') return DELIVERY_COLOR
      if (type === 'SMS Unsubscriptions') return SMS_COLOR
      if (type === 'RBM Unsubscriptions') return RBM_COLOR
      return '#2F46F6'
    },
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`
      }
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center'
      },
      autoRotate: false,
      content: ({ value }) => {
        if (value > 0) return value
        return ''
      }
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect()
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2))
          const text = 'Acceptance'
          return renderStatistic(d, text, {
            fontSize: 28
          })
        }
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px'
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect()
          const text = ACCEPTANCE_PERCENTAGE + '%'
          return renderStatistic(width, text, {
            fontSize: 32
          })
        }
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      },
      {
        type: 'pie-statistic-active'
      }
    ]
  }
  return <Pie {...config} />
}

const StatisticsLinesChart = ({ data }) => {
  const config = {
    data: data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    sizeField: 'value',
    shapeField: 'trail',
    legend: { position: 'top' },
    colors: ['#5B8FF9', '#61DDAA', '#65789B', '#F6BD16'],
    colorField: 'category'
  }

  return <Line {...config} />
}

const StatisticsButton = ({ children, ...props }) => {
  return (
    <button
      className="inline-block h-full px-5 rounded-md border border-transparent bg-blue-dark text-center font-medium text-white hover:bg-indigo-700"
      {...props}
    >
      {children}
    </button>
  )
}

const StatisticsReport = ({ children, ...props }) => {
  return <></>
  // const campaignId = props.campaignid ?? null
  // const filename = props.filename ?? 'report'
  // const filters = props.filters ?? null

  // if (!campaignId) return

  // const serialize = (obj) => {
  //   let str = []
  //   for (var p in obj)
  //     if (obj.hasOwnProperty(p)) {
  //       str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
  //     }
  //   return str.join('&')
  // }

  // const handleClick = async () => {
  //   const user = await Auth.currentAuthenticatedUser()

  //   let data,
  //     lastMsgId = null,
  //     csv
  //   let csvData = new Blob([], { type: 'text/csv;charset=utf-8;' })

  //   do {
  //     data = await API.get(
  //       'rcs-api',
  //       `/campaign/${campaignId}?` +
  //         (lastMsgId ? `lastMsgId=${lastMsgId}&` : '') +
  //         serialize(filters) ?? null,
  //       {
  //         headers: { Authorization: user.signInUserSession.idToken.jwtToken }
  //       }
  //     )

  //     csv = Papa.unparse(data.report, { header: !csvData.size })
  //     csvData = new Blob([csvData, csv + '\n'], {
  //       type: 'text/csv;charset=utf-8;'
  //     })

  //     lastMsgId = data?.meta?.pagination?.lastMsgId ?? null
  //   } while (lastMsgId)
  //   console.log(data)
  //   const csvURL = window.URL.createObjectURL(csvData)
  //   const tempLink = document.createElement('a')
  //   tempLink.href = csvURL
  //   tempLink.setAttribute('download', `${filename}.csv`)
  //   tempLink.click()
  // }

  // return (
  //   <Button shape="round" {...props} onClick={handleClick}>
  //     {children}
  //   </Button>
  // )
}

const StatisticsRibbon = ({ text, type, children }) => {
  let color
  switch (type) {
    case 'rcs':
      color = colors.rcs
      break
    case 'sms':
      color = colors.sms
      break
    case 'total':
      color = colors.total
      break
    default:
      color = colors.gray
  }
  return (
    <Badge.Ribbon text={text} style={{ background: color, fontSize: '12px' }}>
      {children}
    </Badge.Ribbon>
  )
}

const StatisticsFunnel = ({ data }) => {
  const config = {
    data: data,
    xField: 'state',
    yField: 'total',
    color: [colors.delivered, colors.displayed, colors.touched, colors.goal],
    isTransposed: true,
    minSize: 0.3,
    maxSize: 0.8,
    label: {
      formatter: function formatter(datum) {
        return datum.state + ': ' + datum.total
      }
    },
    conversionTag: {
      formatter: (datum) => {
        return (
          (
            (datum[FUNNEL_CONVERSATION_FIELD][1] /
              datum[FUNNEL_CONVERSATION_FIELD][0]) *
            100
          ).toFixed(2) + ' %'
        )
      }
    }
  }

  return <Funnel {...config} />
}

const StatisticsLegendText = ({ element }) => {
  const styles = {
    text: {
      padding: '8px 20px',
      backgroundColor: '#f0f0f0',
      borderRadius: '20px',
      fontSize: '14px'
    },
    suggestion: {
      marginRight: '10px',
      padding: '8px 25px',
      backgroundColor: '#f0f0f0',
      borderRadius: '20px',
      fontSize: '14px',
      backgroundColor: colors.rcs,
      color: '#FFF'
    },
    Col: {
      marginTop: '25px'
    },
    pb10: {
      paddingBottom: '10px'
    }
  }

  let i = 0

  return (
    <div style={styles.pb10}>
      <span style={styles.text}>{element.content}</span>
      <br />
      <Row>
        {element.suggestions
          ? element.suggestions.map((subel) => {
              return (
                <Col key={++i} style={styles.Col}>
                  <Tooltip
                    title={`${subel.name} · ${subel.element}`}
                    color={colors.darkgray}
                  >
                    <span style={styles.suggestion}>{subel.text}</span>
                  </Tooltip>
                </Col>
              )
            })
          : null}
      </Row>
    </div>
  )
}

const StatisticsLegendCard = ({ element }) => {
  const styles = {
    Card: {
      borderRadius: '10px',
      border: '1px solid #eee',
      minHeight: '365px'
    },
    CardImg: {
      maxWidth: '300px',
      maxHeight: '300px',
      padding: '1px',
      borderRadius: '10px 10px 0px 0px'
    },
    CardTitle: {
      fontSize: '15px',
      whiteSpace: 'normal',
      fontWeight: 600
    },
    CardDescription: {
      fontSize: '13px',
      whiteSpace: 'normal'
    },
    CardSuggestions: {
      fontWeight: 600,
      textAlign: 'center'
    },
    CardLink: {
      color: colors.rcs
    }
  }

  let i = 0

  return (
    <Card
      cover={
        element.image ? (
          <img alt={element.title} src={element.image} style={styles.CardImg} />
        ) : null
      }
      style={styles.Card}
    >
      <p style={styles.CardTitle}>{element.title}</p>
      <p style={styles.CardDescription}>{element.description}</p>
      {element.suggestions
        ? element.suggestions.map((subel) => {
            return (
              <p key={++i} style={styles.CardSuggestions}>
                <br />
                <Tooltip
                  title={`${subel.name} · ${subel.element}`}
                  color={colors.darkgray}
                >
                  <span style={styles.CardLink}>{subel.text}</span>
                </Tooltip>
              </p>
            )
          })
        : null}
    </Card>
  )
}

const StatisticsLegendCarousel = ({ element }) => {
  const styles = {
    Row: {
      height: '380px'
    },
    Col: {
      padding: '4px',
      width: '300px',
      height: '300px'
    }
  }

  let i = 0

  return (
    <Row style={styles.Row}>
      {element.map((subel) => {
        return (
          <Col key={++i} style={styles.Col}>
            <StatisticsLegendCard element={subel} />
          </Col>
        )
      })}
    </Row>
  )
}

const StatisticsLegend = ({ title, data }) => {
  const styles = {
    Col: {
      padding: '5px',
      fontSize: '12px'
    },
    title: {
      fontSize: '13px',
      fontWeight: '600',
      padding: '25px 0px 10px 5px'
    },
    img: {
      maxWidth: '300px',
      maxHeight: '300px',
      borderRadius: '10px',
      border: '1px solid #eee',
      marginBottom: '10px'
    },
    PopoverTitle: {
      fontWeight: '600'
    },
    PopoverText: {
      color: '#888'
    },
    content: {
      paddingTop: '15px'
    }
  }

  let content

  return (
    <div>
      {title ? (
        <Row>
          <Col style={styles.title}>{title}</Col>
        </Row>
      ) : null}
      <Row>
        {data.map((el) => {
          content = (
            <div style={styles.content}>
              {el.type === 'text' ? (
                <StatisticsLegendText element={el} />
              ) : el.type === 'card' ? (
                <StatisticsLegendCard element={el.content} />
              ) : (
                <StatisticsLegendCarousel element={el.content} />
              )}
            </div>
          )
          return (
            <Col key={el.name} style={styles.Col}>
              <Popover
                content={content}
                title={
                  <span style={styles.PopoverTitle}>
                    {el.name} · {el.element}
                  </span>
                }
                trigger="hover"
              >
                <Text style={styles.PopoverText}>{el.name}</Text>
              </Popover>
            </Col>
          )
        })}
      </Row>
      <br />
    </div>
  )
}

const StatisticsColors = colors

export {
  StatisticsTitle,
  StatisticsSectionTitle,
  StatisticsBar,
  StatisticsElementsBar,
  StatisticsElementsBarGrouped,
  StatisticsColumn,
  StatisticsColumnCompared,
  StatisticsColumnSerie,
  StatisticsPie,
  StatisticsLinesChart,
  StatisticsDonut,
  StatisticsFunnel,
  StatisticsButton,
  StatisticsReport,
  StatisticsRibbon,
  StatisticsLegend,
  StatisticsColors
}
