import Joi from '@hapi/joi'
// import precondedidos from '../mocks/preconcedidos.json';
// import recuperaciones from '../mocks/recuperaciones.json';

const CAMPAIGNS = {
  basic: {
    joiSchema: Joi.array().items(
      Joi.object().keys({
        telefono: Joi.string().length(11).required()
      })
    ),
    maps: {
      phone: 'telefono'
    }
  },
  withName : {
    joiSchema: Joi.array().items(
      Joi.object().keys({
        telefono: Joi.string().max(18).required(),
        name : Joi.string().max(30).required()
      })
    ),
    maps: {
      phone: 'telefono',
      name : 'name'
    }
  },
  unknown : {
    joiSchema: Joi.array().items(
      Joi.object().keys({
        telefono: Joi.string().max(18).required(),
      }).unknown(true)
    ),
    maps: {
      phone: 'telefono',
      name : 'name',
      docNumber : 'docNumber',
      residence : 'residence',
      nationality : 'nationality'
    }
  }
}

const ERRORS = {
  UserNotConfirmedException: 'Your user is not confirmed',
  NotAuthorizedException: 'Invalid credentials',
  InvalidPasswordException:
    'Passwords must have at least: 8 characters, one lowercase letter, one uppercase letter, one number and one symbol',
  InvalidParameterException:
    'Passwords must have at least: 8 characters, one lowercase letter, one uppercase letter, one number and one symbol.',
  UsernameExistsException: 'User already exists',
  ExpiredCodeException: 'Invalid code',
  UserLambdaValidationException:
    'You cannot register with this email account',
  AccepPolicyException: 'You must accept the terms and conditions'
}

const msgStatesWeight = {
  failed: 1,
  sent: 1,
  delivered: 2,
  processed: 2,
  undeliv: 2,
  expired: 2,
  failed: 2,
  rejectd: 2,
  displayed: 3
}

const statesVisualization = (state) => {
  const ux = {}
  switch (state) {
    case 'sent':
      ux.state = 'Enviado'
      ux.color = 'orange'
      break
    case 'delivered':
      ux.state = 'Entregado'
      ux.color = 'green'
      break
    case 'displayed':
      ux.state = 'Leído'
      ux.color = 'green'
      break
    case 'processed':
      ux.state = 'Procesado'
      ux.color = 'orange'
      break
    case 'saved':
      ux.state = 'Procesado'
      ux.color = 'orange'
      break
    case 'undeliv':
      ux.state = 'No entregado'
      ux.color = 'red'
      break
    case 'rejectd':
      ux.state = 'No entregado'
      ux.color = 'red'
      break
    case 'expired':
      ux.state = 'Expirado'
      ux.color = 'red'
      break
    case 'failed':
      ux.state = 'Ha fallado'
      ux.color = 'red'
      break
    default:
      ux.state = 'Fallo desconocido'
      ux.color = 'red'
      break
  }
  return ux
}

export { CAMPAIGNS, ERRORS, msgStatesWeight, statesVisualization }
