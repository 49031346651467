import React from "react";

function Balloon({ message, sent, ...rest }) {
  const balloonStyle = {
    backgroundColor: sent ? "#DCF8C6" : "#F0F0F0",
    color: sent ? "#333333" : "#555555",
    alignSelf: sent ? "flex-end" : "flex-start",
    borderRadius: "20px",
    padding: "10px 20px",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: sent ? "10px" : "0",
    marginRight: sent ? "0" : "10px",
    maxWidth: "80%",
    position: "relative",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap" 
  };

  // const triangleStyle = {
  //   width: "0",
  //   height: "0",
  //   borderTop: `10px solid transparent`,
  //   borderBottom: `10px solid transparent`,
  //   borderRight: sent
  //     ? `10px solid ${balloonStyle.backgroundColor}`
  //     : "10px solid transparent",
  //   borderLeft: sent
  //     ? "10px solid transparent"
  //     : `10px solid ${balloonStyle.backgroundColor}`,
  //   position: "absolute",
  //   top: "10px",
  //   left: sent ? "-10px" : "auto",
  //   right: sent ? "auto" : "-10px",
  // };

  return (
    <div className="text-xs" style={balloonStyle} {...rest}>
      {message}
      {/* <div style={triangleStyle} /> */}
    </div>
  );
}

export default Balloon;
