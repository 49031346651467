import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  useLocation
} from "react-router-dom"
import i18n from '../../i18n'

const Integration = () => {

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const hsCode = queryParameters.get("code");

  const getToken = async () => {
    try {

      const result = await API.post('rcs-api', '/hs-token', {
        body: {
          code: hsCode
        }
      })
      console.log(result)
    } catch (err) {
      console.log("error con tokon")
    }
  }

  useEffect(() => {
    getToken()
  }, [])

  return (
    <div className="flex items-center flex-col justify-center min-h-full">
      <h1 className="text-5xl font-semibold text-grey-blue mb-20">
        {i18n.t('integration_hubspot_ok')}
      </h1>
      <div className="flex items-center">

        <img
          src="/assets/imgs/logo-tadoo-black.svg"
          style={{ width: '300px', padding: '10px 10px 10px 0' }}
          alt="logo"
        />
        <div className="bg-grey-blue rounded-md w-20 h-2"></div>
        <CheckIcon
          className="h-20 w-20 text-green-600"
          aria-hidden="true"
        />
        <div className="bg-grey-blue rounded-md w-20 h-2"></div>
        <img
          src="/assets/imgs/hubspot-logo.svg"
          style={{ width: '300px', padding: '0px 10px 10px 20px' }}
          alt="logo"
        />
      </div>

    </div>
  )
}
export default Integration