import React from 'react'
import { RBM_ELEMENT_ERRORS_MESSAGES, RBM_ELEMENT_ERRORS } from '../constants'

const TextMsgForm = ({ elementInfo, setInfo, errors }) => {
  const handleInfoChange = (e) => {
    setInfo((prev) => ({ ...prev, text: e.target.value }))
  }
  return (
    <div>
      <label
        htmlFor="msgText"
        className="block text-sm mt-3 font-medium text-grey-blue"
      >
        Message Text
      </label>
      <textarea
        value={elementInfo.text || ''}
        type="text"
        name="msgText"
        id="msgText"
        className="shadow block w-full resize-y rounded-md border-gray-300 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder="text"
        onChange={handleInfoChange}
      />
      {errors.includes(RBM_ELEMENT_ERRORS.EMPTY_TEXT) && (
        <p className="text-red-500">
          {RBM_ELEMENT_ERRORS_MESSAGES[RBM_ELEMENT_ERRORS.EMPTY_TEXT]}
        </p>
      )}
    </div>
  )
}

export default TextMsgForm
