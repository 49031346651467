import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import 'antd/dist/antd.css'
import {
  Row,
  Col,
  Table,
  Popover,
  Alert,
  Empty,
  Popconfirm,
  Typography,
  Tag,
  Tooltip
} from 'antd'
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PoweroffOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from '@ant-design/icons'
import { dateToString } from '../../libs/date'

const Steps = () => {
  const [campaigns, setCampaigns] = useState(null)
  const [steps, setSteps] = useState(null)
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const { Text } = Typography
  let campaignsData

  useEffect(async () => {
    const token = (await Auth.currentAuthenticatedUser()).signInUserSession
      .idToken.jwtToken
    await listCampaigns(token)
    stepsLogs(token)
  }, [])

  const listCampaigns = async (token) => {
    setLoading(true)
    const req = (
      await API.get('rcs-api', '/campaigns/', {
        headers: {
          Authorization: token
        }
      })
    )
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .map((el, idx) => {
        el.key = idx
        return el
      })
      .reverse()
    campaignsData = req
  }

  const stepsLogs = async (token) => {
    const logs = await API.get('rcs-api', `/steps-logs`, {
      headers: {
        Authorization: token
      }
    })
    const logComplete = campaignsData.map((c) => {
      const found = logs.find((l) => l.campaignId === c.campaignId)
      if (found) {
        c.auth = found.auth
      }
      return c
    })
    setSteps(logComplete)
    setLoading(false)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'campaignId',
      render: (id) => (
        <Popover content={id} title="Id de campaña">
          <QuestionCircleOutlined />
        </Popover>
      )
    },
    {
      title: 'Campaña',
      dataIndex: 'flow',
      render: (flow) => {
        const f = flow
        return `${f.charAt(0).toUpperCase()}${f.slice(1)}`
      }
    },
    {
      title: 'Día',
      dataIndex: 'timestamp',
      render: (e) => dateToString(e).day
    },
    {
      title: 'Hora',
      dataIndex: 'timestamp',
      render: (e) => dateToString(e).time
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (e) => (
        <span>
          <Tag color={e === 'open' ? 'green' : '#d9d9d9'}>
            {e === 'open' ? 'Activa' : 'Finalizada'}
          </Tag>
        </span>
      )
    },
    {
      title: 'Auth',
      dataIndex: 'auth',
      align: 'center',
      render: (auth) => {
        if (auth) {
          const { result, timestamp } = JSON.parse(auth)
          const tsAuth = dateToString(timestamp)
          return (
            <Popover content={`${tsAuth.day} ${tsAuth.time}`} title="Timestamp">
              {result !== 'success' ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                <CloseCircleTwoTone twoToneColor="#f5222d" />
              )}
            </Popover>
          )
        }
      }
    },
    {
      title: 'Acción',
      align: 'center',
      render: (row) =>
        row.status === 'open' ? (
          <Popconfirm
            title={
              <>
                <Text type="danger">Atención</Text>
                <p style={{ marginBottom: 0 }}>
                  La finalización de la campaña es permanente
                </p>
              </>
            }
            onConfirm={() => {
              // StepsCampaign(row.campaignId);
            }}
            cancelText="No, espera"
            okText="Sí, quiero finalizarla"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip placement="right" title="Finalizar">
              <a>
                <PoweroffOutlined />
              </a>
            </Tooltip>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={
              <>
                <Text type="danger">Atención</Text>
                <p style={{ marginBottom: 0 }}>
                  La eliminación es permanente y
                </p>
                <p style={{ marginBottom: 0 }}>
                  los datos ya no serán accesibles
                </p>
              </>
            }
            onConfirm={() => {
              // StepsCampaign(row.campaignId);
            }}
            cancelText="No, espera"
            okText="Sí, quiero eliminarla"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip placement="right" title="Eliminar">
              <a>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Popconfirm>
        )
    }
  ]

  return (
    <>
      <Row justify="center">
        <Col
          span={16}
          style={{
            backgroundColor: '#fff',
            marginTop: '50px',
            padding: '25px'
          }}
        >
          <>
            {errorMessage && (
              <Alert
                message="Warning"
                description={errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: '15px' }}
              />
            )}
            <Table
              loading={loading}
              dataSource={steps}
              columns={columns}
              size="small"
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Sin información</span>}
                  ></Empty>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </>
  )
}

export default Steps
