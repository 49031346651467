import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AppContext } from './libs/context'
import Layout from './components/Layout'
import Login from './scenes/Access/Login'
import Register from './scenes/Access/Register'
import Results from './scenes/Results'
import Campaign from './scenes/Campaign'
import Remove from './scenes/Remove'
import Statistics from './scenes/Statistics'
import Dashboard from './scenes/Dashboard'
import Steps from './scenes/Steps'
import Clients from './scenes/Clients'
import CreateCampaign from './scenes/Templates/Create'
import './styles/global.css'
import TopUps from './scenes/TopUps/TopUps'
import ForgotPassword from './scenes/Access/ForgotPassword'
import Payment from './scenes/TopUps/Payment'
import Profile from './scenes/Profile'
import Templates from './scenes/Templates/Templates'
import Campaigns from './scenes/Campaigns'
import Integration from './scenes/Integration'
import BotConfiguration from './scenes/Profile/components/BotConfiguration'

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState(false)

  const onLoad = async () => {
    try {
      const current = await Auth.currentSession()
      setIsAuthenticated(true)
      setUserInfo({
        email: current.idToken.payload.email,
        isAdmin:
          current.accessToken.payload?.['cognito:groups']?.[0] === 'Admin'
      })
    } catch (e) {
      setIsAuthenticated(false)
    }
  }

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <AppContext.Provider
      value={{ setIsAuthenticated, isAuthenticated, setUserInfo, userInfo }}
    >
      {isAuthenticated ? (
        !userInfo ? (
          <Router>
            <Switch>
              <Route path="/"></Route>
            </Switch>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/resetpassword">
                <ForgotPassword />
              </Route>
              <Route path="/integration-hubspot">
                <Integration />
              </Route>
              {/* {!userInfo.isAdmin && ( */}
              <Route path="/campaign">
                <Layout content={<Campaign />} />
              </Route>
              {/* )} */}
              <Route path="/results">
                <Layout content={<Results />} />
              </Route>
              {userInfo.isAdmin && (
                <Route path="/steps">
                  <Layout content={<Steps />} />
                </Route>
              )}
              {/* <Route path="/dashboard">
                <Layout content={<Dashboard />} />
              </Route> */}
              <Route path="/statistics">
                <Layout content={<Statistics />} />
              </Route>
              <Route path="/createCampaign">
                <Layout content={<CreateCampaign />} />
              </Route>
              <Route path="/templates">
                <Layout content={<Templates />} />
              </Route>
              <Route path="/campaigns">
                <Layout content={<Campaigns />} />
              </Route>
              {!userInfo.isAdmin && (
                <Route path="/remove">
                  <Layout content={<Remove />} />
                </Route>
              )}
              {userInfo.isAdmin && (
                <Route path="/clients">
                  <Layout content={<Clients />} />
                </Route>
              )}
              <Route path="/topups">
                <Layout content={<TopUps />} />
              </Route>
              <Route path="/profile">
                <Layout content={<Profile />} />
              </Route>
              <Route path="/bot-configuration">
                <Layout content={<BotConfiguration />} />
              </Route>
              <Route path="/">
                <Layout content={<Campaign />} />
              </Route>
            </Switch>
          </Router>
        )
      ) : (
        <Router>
          <Switch>
            <Route path="/resetpassword">
              <ForgotPassword />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/integration-hubspot">
              <Integration />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      )}
    </AppContext.Provider>
  )
}

export default App
