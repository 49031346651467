import React from 'react'
import {ElementClicksTag} from './ElementClickTag'

import {
  EyeSvg,
  ClickSvg,
  DoubleCheckSvg,
  WebSvg
} from '../../components/Icons'

import { parseTextToHTML } from '../../libs/parse'

const REPLY_TYPE = 'reply'
const ACTION_TYPE = 'action'
const BUTTON_ELEMENT = 'button'
const SUGGESTION_ELEMENT = 'suggestion'

const getClickActions = (elementInfo) => {
  const clickActions = []
  if (elementInfo.buttons) {
    for (let button of elementInfo.buttons) {
      clickActions.push(
        <ElementClicksTag elementInfo={button} elementType={BUTTON_ELEMENT} />
      )
    }
  }

  if (elementInfo.suggestions) {
    for (let suggestion of elementInfo.suggestions) {
      clickActions.push(
        <ElementClicksTag
          elementInfo={suggestion}
          elementType={SUGGESTION_ELEMENT}
        />
      )
    }
  }
  return clickActions
}

export const BasicStatsLayout = ({ children, elementInfo }) => {
  return (
    <div id="elementInfo" className="flex gap-2">
      <section id="leftColumn" className="w-80">
        {children}
      </section>
      <section id="statsColumm">
        <div
          id="deliveriesRow"
          className="flex flex-row gap-2 items-center w-full"
        >
          <h2 className="text-2xl font-semibold text-grey-blue border-grey-blue py-4 whitespace-nowrap">
            Deliveries :{' '}
            <span className="bg-gradient-to-r from-grey-blue to-indigo-400 bg-clip-text text-transparent text-3xl">
              {elementInfo.delivered}{' '}
            </span>
          </h2>
          <DoubleCheckSvg />
        </div>
        <div
          id="deliveriesRow"
          className="flex flex-row gap-2 items-center w-full"
        >
          <h2 className="text-2xl font-semibold text-grey-blue border-grey-blue py-4 whitespace-nowrap">
            Visualizations :{' '}
            <span className="bg-gradient-to-r from-grey-blue to-indigo-400 bg-clip-text text-transparent text-3xl">
              {elementInfo.displayed}{' '}
            </span>
          </h2>
          <EyeSvg />
        </div>
      </section>
      <section id="clicks" className="w-full flex flex-col">
        <div
          id="clickTitle"
          className=" flex gap-2 w-full border-b-4 border-grey-blue"
        >
          <h1 className="text-3xl font-semibold text-grey-blue w-full">
            Clicks
          </h1>
          <ClickSvg />
        </div>
        <div id="actionsGroup" className="flex flex-wrap gap-2 mt-4">
          {getClickActions(elementInfo)}
        </div>
      </section>
    </div>
  )
}
