import React, { useEffect, useState } from 'react'
import Balloon from './Balloon'
import unicodeToGsm from '../../../../libs/unicodeToGsm'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'antd'
import i18n from '../../../../i18n'
import { parseTextToHTML } from '../../../../libs/parse'
import { TEXT_TYPE } from './constants'

const NewSmsForm = ({
  setSender,
  sender,
  setMobileMessages,
  setSmsList,
  smsList,
  setErrors,
  errors,
  errorText,
  templateSMS
}) => {
  const [newSMS, setnewSMS] = useState(smsList)
  const [codificationSMS, setCodificationSMS] = useState({
    codification: '7bits',
    size: 0,
    limit: 160,
    SMS: 1
  })
  const [estado, setEstado] = useState(true)
  const [existVariables, setExistVariables] = useState(false)

  const [alert, setAlert] = useState()

  const typeEncondig = (e) => {
    //metodo para saber el tipo de codificación y el numero de caracteres utilizado y la cantidad de SMS
    let message = e.target.value
    setnewSMS(message)
    const regex = /{([^}]*)}/g
    const matches = e.target.value
      .match(regex)
      ?.map((match) => match.replace(regex, '$1'))
    setErrors((oldErrors) => {
      oldErrors.emptySms = false
      oldErrors.invalidVariable = false
      if (message === '') {
        oldErrors.emptySms = true
      } else {
        oldErrors.emptySms = false
        if (matches && matches.includes('')) {
          oldErrors.invalidVariable = true
        }
      }
      return oldErrors
    })
    setExistVariables(matches ? true : false)
    message = message.trim()
    const messageClean = message.replace(regex, '')
    const sevenBitencoding =
      '|	^	€	{	}	[	]	~ @ Δ SP 0 ¡ P ¿ p £ _ ! 1 A Q a q $ Φ " 2 B R b r ¥ Γ # 3 C S c s è Λ ¤ 4 D T d t é Ω % 5 E U e u ù Π & 6 F V f v ì Ψ \' 7 G W g w ò Σ ( 8 H X h x Ç Θ ) 9 I Y i y LF Ξ * : J Z j z Ø ESC + ; K Ä k ä ø Æ , < L Ö l ö CR æ - = M Ñ m ñ Å ß . > N Ü n ü å É / ? O § o à'
    const specialCharacter = '|	^	€	{	}	[	]	~'
    let contOfSpecialCharacter = 0
    sevenBitencoding.split()
    specialCharacter.split()

    let nEstado = true
    for (var i = 0; i < message.length; i++) {
      if (!unicodeToGsm[message.charCodeAt(i)]) {
        nEstado = false
        break
      }
      // if (specialCharacter.includes(message[i]))
      //     contOfSpecialCharacter = 1 + contOfSpecialCharacter
    }
    setEstado(nEstado)
    let smsCount
    let limit
    let ucsLimit
    if (nEstado) {
      let suma = contOfSpecialCharacter + messageClean.length
      limit = 160
      // adding header space
      if (suma > limit) {
        limit = 153
      }
      smsCount = clacularSMS(limit, suma)
      setCodificationSMS({
        codification: '7bits',
        size: suma,
        limit: limit,
        SMS: smsCount
      })
    } else {
      limit = 70
      if (messageClean.length > limit) {
        limit = 67
      }
      smsCount = clacularSMS(limit, messageClean.length)
      setCodificationSMS({
        codification: 'Unicode',
        size: messageClean.length,
        limit: limit,
        SMS: smsCount
      })
    }
    let messagesText = []
    let from = 0
    if (message.length > 0) {
      setMobileMessages([
        {
          type: TEXT_TYPE,
          text: message
        }
      ])
      messagesText.push(message.substring(from, message.length + 1))
    } else {
      setMobileMessages([])
    }

    // setSmsList(messagesText)
    setSmsList(message)
  }
  const clacularSMS = (limit, size) => {
    if (size == 0) return 1
    if (size > limit) {
    }
    const tam = Math.ceil(size / limit)
    if (tam > 1) setAlert(true)
    else setAlert(false)
    return tam
  }

  useEffect(() => {
    if (templateSMS) {
      typeEncondig({
        target: {
          value: templateSMS
        }
      })
    }
    if (newSMS !== '') {
      setMobileMessages([
        {
          type: TEXT_TYPE,
          text: newSMS
        }
      ])
    }
  }, [])

  return (
    <div className="w-full">
      <label
        htmlFor="sender"
        className="flex mt-5 text-sm font-medium text-gray-700"
      >
        {i18n.t('sender')}
        <Tooltip
          placement="right"
          className="ml-2"
          title={i18n.t('sender_validations')}
        >
          <a>
            <InformationCircleIcon className="h-6 w-6" />
          </a>
        </Tooltip>
      </label>
      <div className="relative mt-1 w-52 rounded-md shadow-sm">
        <input
          type="text"
          name="sender"
          id="sender"
          className={
            errors.invalidSender || errors.emptySender
              ? 'appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded pl-3 py-2 pr-3 leading-tight focus:outline-none focus:bg-white'
              : 'block w-full rounded-md border-gray-300 pl-3 py-2 pr-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          }
          placeholder={i18n.t('sender')}
          value={sender}
          onChange={(e) => {
            setSender(e.target.value)
            if (e.target.value === '') {
              setErrors((oldErrors) => {
                oldErrors.invalidSender = false
                oldErrors.emptySender = true
                return oldErrors
              })
            }
            const regex = /^([a-zA-Z0-9!:;+.-]{1,11}|[0-9]{10,16})$/
            if (!regex.test(e.target.value)) {
              setErrors((oldErrors) => {
                oldErrors.invalidSender = true
                oldErrors.emptySender = false
                return oldErrors
              })
            } else {
              setErrors((oldErrors) => {
                oldErrors.invalidSender = false
                oldErrors.emptySender = false
                return oldErrors
              })
            }
          }}
        />
      </div>
      {errors.invalidSender && (
        <p className="text-red-500 text-xs italic">{errorText.invalidSender}</p>
      )}
      {errors.emptySender && (
        <p className="text-red-500 text-xs italic">{errorText.emptySender}</p>
      )}
      <label
        htmlFor="msgText"
        className="flex text-sm mt-3 font-medium text-gray-700"
      >
        {i18n.t('message_text')}
        <Tooltip
          placement="right"
          className="ml-2"
          title={i18n.t('to_add_variables')}
        >
          <a>
            <InformationCircleIcon className="h-6 w-6" />
          </a>
        </Tooltip>
      </label>
      <textarea
        style={{ whiteSpace: 'pre-wrap' }}
        type="text"
        name="msgText"
        id="msgText"
        className={
          errors.emptySms || errors.invalidVariable
            ? 'appearance-none block w-1/2 bg-gray-200 text-gray-700 border border-red-500 rounded pl-3 py-2 pr-3 leading-tight focus:outline-none focus:bg-white'
            : 'shadow block w-1/2 resize-y rounded-md border-gray-300 mt-3 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        }
        placeholder={i18n.t('text')}
        value={newSMS}
        onChange={typeEncondig}
      />
      {errors.invalidVariable && (
        <p className="text-red-500 text-xs italic">
          {errorText.invalidVariable}
        </p>
      )}
      {errors.emptySms && (
        <p className="text-red-500 text-xs italic">{errorText.emptySms}</p>
      )}
      <div>
        <label
          htmlFor="msgText"
          className="block text-sm mt-3 font-medium text-gray-700"
        >
          {i18n.t('characters_used')}
          <span>{codificationSMS.size}</span>
        </label>
        <label
          htmlFor="msgText"
          className="block text-sm mt-3 font-medium text-gray-700"
        >
          {i18n.t('codification_type')}
          <span>{estado ? 'GSM' : 'UNICODE'}</span>
        </label>
        <label
          htmlFor="msgText"
          className="block text-sm mt-3 font-medium text-gray-700"
        >
          {i18n.t('available_chars')}
          <span>{codificationSMS.limit}</span>
        </label>
        <label
          htmlFor="msgText"
          className="flex text-sm mt-3 font-medium text-gray-700"
        >
          {i18n.t('number_of_sms')}
          <span>{codificationSMS.SMS}</span>
          {existVariables && (
            <Tooltip
              placement="right"
              className="ml-2"
              title={i18n.t('sms_could_increase')}
            >
              <a>
                <InformationCircleIcon className="h-6 w-6" />
              </a>
            </Tooltip>
          )}
        </label>
      </div>
    </div>
  )
}

export default NewSmsForm
