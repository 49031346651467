import React, { useState } from 'react'
import TypeButton from '../TypeButton'
import {
  ALL_BUTTONS_TYPES,
  BUTTONS_TYPES,
  LINK_BUTTON,
  PHONE_CALL_BUTTON,
  REPLY_BUTTON,
  BUTTON_ERROR_TYPES as BUTTON_ERRORS,
  BUTTON_ERRORS_MESSAGES
} from '../constants'

const ButtonForm = ({
  buttonInfo = {},
  setOpen,
  onClick,
  onCancel,
  isConversational = false,
  replyOptions = []
}) => {
  const [type, setType] = useState(buttonInfo.type || LINK_BUTTON)
  const [buttonText, setButtonText] = useState(buttonInfo.text || '')
  const [phone, setPhone] = useState(buttonInfo.phone || '')
  const [link, setLink] = useState(buttonInfo.link || '')
  const [replyFlow, setReplyFlow] = useState(buttonInfo.replyFlow || '')
  const [errors, setEerrors] = useState([])

  const handleTypeChange = (type) => {
    setType(type)
  }

  const handleButtonTextChange = (event) => {
    setButtonText(event.target.value)
  }

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handleLinkChange = (event) => {
    setLink(event.target.value)
  }

  const isValid = (buttonInfo) => {
    const newErrors = []
    if (!buttonInfo.text) newErrors.push(BUTTON_ERRORS.EMPTY_DISPLAY_TEXT)
    if (buttonInfo.type === LINK_BUTTON && !buttonInfo.link)
      newErrors.push(BUTTON_ERRORS.EMPTY_LINK)
    if (buttonInfo.type === PHONE_CALL_BUTTON && !buttonInfo.phone)
      newErrors.push(BUTTON_ERRORS.EMPTY_PHONE)
    if (buttonInfo.type === REPLY_BUTTON && !buttonInfo.replyFlow)
      newErrors.push(BUTTON_ERRORS.MISSING_REPLY)
    setEerrors(newErrors)
    if (newErrors.length > 0) return false
    return true
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const button = {
      type,
      text: buttonText
    }
    if (type === LINK_BUTTON) button.link = link
    if (type === PHONE_CALL_BUTTON) button.phone = phone
    if (type === REPLY_BUTTON) button.replyFlow = replyFlow
    if (isValid(button)) onClick(button)
  }

  const getButtonsSelect = () => {
    const buttonsTypes = !isConversational ? BUTTONS_TYPES : ALL_BUTTONS_TYPES
    return buttonsTypes.map((buttonType) => (
      <TypeButton
        key={buttonType.type}
        type={buttonType.type}
        selected={type === buttonType.type}
        onClick={handleTypeChange}
        icon={buttonType.icon}
      >
        {buttonType.name}
      </TypeButton>
    ))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label className="block text-sm mt-3 font-medium text-grey-blue">
          Type
        </label>
        <div className="flex gap-2 mt-2">{getButtonsSelect()}</div>
      </div>
      <div className="flex flex-col">
        <label className="block text-sm mt-3 font-medium text-grey-blue">
          Text
        </label>
        <p className="text-xs text-gray-500">25 characters max.</p>
        <input
          className="mt-2 border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1 w-full"
          type="text"
          value={buttonText}
          onChange={handleButtonTextChange}
          maxLength={25}
        />
        {errors.includes(BUTTON_ERRORS.EMPTY_DISPLAY_TEXT) && (
          <p className="text-red-500">
            {BUTTON_ERRORS_MESSAGES.EMPTY_DISPLAY_TEXT}
          </p>
        )}
      </div>
      {type === LINK_BUTTON && (
        <div className="flex flex-col gap-2">
          <label className="block text-sm mt-3 font-medium text-grey-blue">
            Link URL
          </label>
          <input
            className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1 w-full"
            type="text"
            value={link}
            onChange={handleLinkChange}
          />
          {errors.includes(BUTTON_ERRORS.EMPTY_LINK) && (
            <p className="text-red-500">{BUTTON_ERRORS_MESSAGES.EMPTY_LINK}</p>
          )}
        </div>
      )}
      {type === PHONE_CALL_BUTTON && (
        <div className="flex flex-col gap-2">
          <label className="block text-sm mt-3 font-medium text-grey-blue">
            Phone Number
          </label>
          <input
            className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1 w-full"
            type="text"
            value={phone}
            onChange={handlePhoneChange}
          />
          {errors.includes(BUTTON_ERRORS.EMPTY_PHONE) && (
            <p className="text-red-500">{BUTTON_ERRORS_MESSAGES.EMPTY_PHONE}</p>
          )}
        </div>
      )}
      {type === REPLY_BUTTON && (
        <div className="flex flex-col gap-2">
          <label className="block text-sm mt-3 font-medium text-grey-blue">
            Reply Text
          </label>
          <select
            className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1 w-full"
            value={replyFlow}
            onChange={(e) => setReplyFlow(e.target.value)}
          >
            <option value="">Select a response</option>
            {replyOptions.map((reply) => (
              <option key={reply.value} value={reply.value}>
                {reply.label}
              </option>
            ))}
          </select>
          {errors.includes(BUTTON_ERRORS.MISSING_REPLY) && (
            <p className="text-red-500">
              {BUTTON_ERRORS_MESSAGES.MISSING_REPLY}
            </p>
          )}
        </div>
      )}
      <div
        id="buttons"
        className="w-full flex justify-between items-center mt-4"
      >
        <button
          onClick={onCancel}
          type="button"
          className="bg-none font-medium border-none text-gray-500"
        >
          Cancel
        </button>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          type="submit"
        >
          {buttonInfo.type ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  )
}

export default ButtonForm
