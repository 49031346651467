import React, { useState } from 'react'
import DropZone from '../../../components/DropZone'
import BotPreview from './BotPreview'
import { API, Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'

const BotConfiguration = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [profilePicture, setProfilePicture] = useState(null)
  const [bannerPicture, setBannerPicture] = useState(null)
  const history = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!name || !description || !profilePicture || !bannerPicture) {
      alert('Please fill all the fields')
      return
    }

    const profileFormData = new FormData()
    profileFormData.append('file', profilePicture)
    profileFormData.append('key', 'profile')

    const bannerFormData = new FormData()
    bannerFormData.append('file', bannerPicture)
    bannerFormData.append('key', 'banner')

    let imagesResult = []
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken

      const userFullInfo = await API.get('rcs-api', '/user', {
        headers: {
          Authorization: tokenReq
        }
      })

      const userId = userFullInfo.userId

      imagesResult = await Promise.all([
        API.post('rcs-api', '/upload-image', {
          headers: {
            Authorization: tokenReq
          },
          body: profileFormData
        }),
        API.post('rcs-api', '/upload-image', {
          headers: {
            Authorization: tokenReq
          },
          body: bannerFormData
        })
      ])

      const botData = {
        name,
        description,
        profilePicture: imagesResult[0].location,
        bannerPicture: imagesResult[1].location
      }

      await API.post('rcs-api', '/users', {
        headers: {
          Authorization: tokenReq
        },
        body: {
          [userId]: {
            botData: botData
          }
        }
      })

      history.push('/profile')
    } catch (err) {
      console.log(err)
      alert('Error while updating bot. Please try again later.')
    }
  }

  const handleProfileDrop = (files) => {
    console.log(files)
    setProfilePicture(files[0])
  }

  const handleBannerDrop = (files) => {
    setBannerPicture(files[0])
  }

  return (
    <div className="flex max-w-4xl gap-6">
      <form onSubmit={handleSubmit} className="space-y-4 w-full">
        <div>
          <label htmlFor="name" className="block font-medium">
            Name:
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 max-w-[300px] w-full"
          />
        </div>
        <div>
          <label htmlFor="description" className="block font-medium">
            Description:
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 w-full max-w-[300px]"
          />
        </div>
        <div id="profilePicture">
          <label htmlFor="profilePicture" className="block font-medium">
            Profile Picture:
          </label>
          <DropZone
            onDrop={handleProfileDrop}
            requiredSize={{ width: 224, height: 224 }}
            className="border border-gray-300 rounded-md px-3 py-2 w-full"
            onRemoveImage={() => setProfilePicture(null)}
          />
        </div>
        <div id="bannerPicture">
          <label htmlFor="profilePicture" className="block font-medium">
            Profile Picture:
          </label>
          <DropZone
            onDrop={handleBannerDrop}
            requiredSize={{ width: 1440, height: 448 }}
            className="border border-gray-300 rounded-md px-3 py-2 w-full"
            onRemoveImage={() => setBannerPicture(null)}
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Save
        </button>
      </form>
      <BotPreview
        bannerPicture={bannerPicture}
        profilePicture={profilePicture}
        name={name}
        description={description}
      />
    </div>
  )
}

export default BotConfiguration
