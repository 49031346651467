import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import 'antd/dist/antd.css'
import {
  Row,
  Col,
  Table,
  Popover,
  Alert,
  Empty,
  Popconfirm,
  Typography,
  Tag,
  message,
  Tooltip
} from 'antd'
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  PoweroffOutlined
} from '@ant-design/icons'
import { dateToString } from '../../libs/date'

const Remove = () => {
  const [campaigns, setCampaigns] = useState(null)
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const { Text } = Typography

  useEffect(() => {
    listCampaigns()
  }, [])

  const columns = [
    {
      title: 'Id',
      dataIndex: 'campaignId',
      render: (id) => (
        <Popover content={id} title="Id de campaña">
          <QuestionCircleOutlined />
        </Popover>
      )
    },
    {
      title: 'Campaña',
      dataIndex: 'flow',
      render: (flow) => {
        const f = flow
        return `${f.charAt(0).toUpperCase()}${f.slice(1)}`
      }
    },
    {
      title: 'Día',
      dataIndex: 'timestamp',
      render: (e) => dateToString(e).day
    },
    {
      title: 'Hora',
      dataIndex: 'timestamp',
      render: (e) => dateToString(e).time
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (e) => (
        <span>
          <Tag color={e === 'open' ? 'green' : '#d9d9d9'}>
            {e === 'open' ? 'Activa' : 'Finalizada'}
          </Tag>
        </span>
      )
    },
    {
      title: 'Envíos',
      dataIndex: 'campaignId',
      align: 'center',
      render: (id) => {
        return (
          <a
            onClick={async () => {
              setLoading(true)
              const sendings = await getCampaignSendings(id)
              const campaignsMutated = [...campaigns]
              campaignsMutated.find((c) => c.campaignId === id).sendings =
                sendings
              setCampaigns(campaignsMutated)
              setLoading(false)
            }}
          >
            {campaigns.find((c) => c.campaignId === id).sendings || (
              <EyeOutlined />
            )}
          </a>
        )
      }
    },
    {
      title: 'Acción',
      align: 'center',
      render: (row) =>
        row.status === 'open' ? (
          <Popconfirm
            title={
              <>
                <Text type="danger">Atención</Text>
                <p style={{ marginBottom: 0 }}>
                  La finalización de la campaña es permanente
                </p>
              </>
            }
            onConfirm={() => {
              removeCampaign(row.campaignId)
            }}
            cancelText="No, espera"
            okText="Sí, quiero finalizarla"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip placement="right" title="Finalizar">
              <a>
                <PoweroffOutlined />
              </a>
            </Tooltip>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={
              <>
                <Text type="danger">Atención</Text>
                <p style={{ marginBottom: 0 }}>
                  La eliminación es permanente y
                </p>
                <p style={{ marginBottom: 0 }}>
                  los datos ya no serán accesibles
                </p>
              </>
            }
            onConfirm={() => {
              removeCampaign(row.campaignId)
            }}
            cancelText="No, espera"
            okText="Sí, quiero eliminarla"
            icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
          >
            <Tooltip placement="right" title="Eliminar">
              <a>
                <DeleteOutlined />
              </a>
            </Tooltip>
          </Popconfirm>
        )
    }
  ]

  async function removeCampaign(campaignId) {
    setLoading(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      await API.get('rcs-api', `/remove-campaign/${campaignId}`, {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      })
      listCampaigns()
      message.success('La campaña ha sido eliminada')
    } catch (error) {
      message.error('Ha habido un error eliminando la campaña')
      setLoading(true)
    }
  }

  async function listCampaigns() {
    setLoading(true)
    const user = await Auth.currentAuthenticatedUser()
    const campaigns = (
      await API.get('rcs-api', '/campaigns/', {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      })
    )
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .map((el, idx) => {
        el.key = idx
        return el
      })
      .reverse()
    setCampaigns(campaigns)
    setLoading(false)
  }

  async function getCampaignSendings(campaignId) {
    const user = await Auth.currentAuthenticatedUser()
    const campaignData = await API.get(
      'rcs-api',
      `/get-campaign/${campaignId}/0`,
      {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      }
    )
    return campaignData.results.length
  }

  return (
    <>
      <Row justify="center">
        <Col
          span={16}
          style={{
            backgroundColor: '#fff',
            marginTop: '50px',
            padding: '25px'
          }}
        >
          <>
            {errorMessage && (
              <Alert
                message="Warning"
                description={errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: '15px' }}
              />
            )}
            <Table
              loading={loading}
              dataSource={campaigns}
              columns={columns}
              size="small"
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Sin información</span>}
                  ></Empty>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </>
  )
}

export default Remove
