import React, { useEffect } from 'react'
import i18n from '../../../../i18n'
import Balloon from './Balloon'
import { parseTextToHTML } from '../../../../libs/parse'
import { TEXT_TYPE } from './constants'
import { RBM_ELEMENT_ERRORS, RBM_ELEMENT_ERRORS_MESSAGES } from './constants'

const SimpleFlow = ({
  setMobileMessages,
  setRbmElements,
  rbmSimpleErrors: errors
}) => {
  const handleTextChange = (e) => {
    const messages = [{ type: TEXT_TYPE, text: e.target.value }]
    setMobileMessages(e.target.value.length > 0 ? messages : [])
    setRbmElements({
      'init-1/1': {
        name: 'Text Message',
        type: TEXT_TYPE,
        text: e.target.value
      }
    })
  }

  useEffect(() => {
    setRbmElements({
      'init-1/1': {
        name: 'Text Message',
        type: TEXT_TYPE,
        text: ''
      }
    })
  }, [])

  return (
    <div className="flex flex-col">
      <label
        className="text-xl font-semibold text-grey-blue w-full border-grey-blue py-4"
        htmlFor="msgText"
      >
        Message Text
      </label>
      <textarea
        type="text"
        name="msgText"
        id="msgText"
        className="w-2/3 resize-y rounded-md border-2 border-grey-blue active:border-grey-blue pl-3 py-2 pr-12 max-h-80 sm:text-sm outline-none focus:border-indigo-500 focus:ring-indigo-500"
        placeholder={i18n.t('text')}
        onChange={handleTextChange}
      />
      {errors.includes(RBM_ELEMENT_ERRORS.EMPTY_TEXT) && (
        <p className="text-red-500">
          {RBM_ELEMENT_ERRORS_MESSAGES[RBM_ELEMENT_ERRORS.EMPTY_TEXT]}
        </p>
      )}
    </div>
  )
}

export default SimpleFlow
