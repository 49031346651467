import React from 'react'

import {
  EyeSvg,
  ClickSvg,
  DoubleCheckSvg,
  WebSvg
} from '../../components/Icons'

import { parseTextToHTML } from '../../libs/parse'

const Suggestion = ({ text }) => {
  return (
    <div
      id="suggestion2"
      className="border-2 border-gray-700 rounded-full px-3 py-1"
    >
      <span className="text-gray-700 font-normal">{text}</span>
    </div>
  )
}

export const TextStats = ({ elementInfo }) => {
  return (
    <section id="textColumn" className="w-80">
      <div
        id="textBallon"
        className="w-80 p-4 bg-blue-100 rounded-tr-xl rounded-tl-xl rounded-bl-xl"
      >
        <p>{elementInfo.text && parseTextToHTML(elementInfo.text)}</p>
      </div>
      <div id="suggestionsGroup" className="flex flex-wrap gap-1 mt-2">
        {elementInfo.suggestions &&
          elementInfo.suggestions.map((suggestion) => (
            <Suggestion text={suggestion.text} />
          ))}
      </div>
    </section>
  )
}
