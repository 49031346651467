import React, { Fragment, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { API, Auth } from 'aws-amplify'
import ResultModal from '../../components/ResultModal'
import currencies from '../../config/currency'
import Payment from './Payment'
import i18n from '../../i18n'

const modalMsg = {
  ok: {
    title: 'Operación realizada',
    content:
      'El cambio en el saldo del cliente se ha realizado corectamente',
    cta: 'Ok'
  },
  error: {
    title: 'Error',
    content:
      'No se ha podido realizar la operación, por favor revise los datos introducidos y si el error persiste contacte con support@tadoo.biz para más información',
    cta: 'Error'
  }

}

const AddMoneyModal = ({ setTopUps, client, open, setOpen }) => {

  const defaultErrors = {
    invalidAmountFormat: true,
    minimumAmountError: false,
    decimalsInvalid: false
  }


  const [amount, setAmount] = useState("")
  const [errors, setErrors] = useState(defaultErrors)
  const [paymentView, setPaymentView] = useState(false)
  const currency = client.currency ? client.currency : 'EUR'

  const errorText = {
    invalidAmountFormat: i18n.t('error_amount_format'),
    minimumAmountError: i18n.t('error_minimum_amount', { minimum: currencies[currency].minimum }),
    decimalsInvalid: i18n.t('error_amount_decimals', { currency: currency })
  }

  const [existErrorsResult, setExistErrorsResult] = useState(true)

  const checkAmount = (newAmount, oldErrors) => {
    let newErrors = oldErrors
    if (currency === "HUF") {
      if (newAmount % 100 !== 0) {
        newErrors.decimalsInvalid = true

        setExistErrorsResult(true)
      } else {
        newErrors.decimalsInvalid = false

      }
    }
    return newErrors
  }

  const checkNumber = (event) => {
    let newErrors = errors
    if (/^\d+(?:\.\d{1,2})?$/.test(event.target.value)) {
      //check minimum
      if (parseFloat(event.target.value) < currencies[currency].minimum) {
        newErrors.invalidAmountFormat = false
        newErrors.minimumAmountError = true

        setExistErrorsResult(true)
      } else {
        newErrors.invalidAmountFormat = false
        newErrors.minimumAmountError = false

        setExistErrorsResult(false)
      }
      //check currency format
      newErrors = checkAmount(parseFloat(event.target.value) * 100, newErrors)
    } else {
      newErrors.invalidAmountFormat = true
      newErrors.minimumAmountError = false
      newErrors.decimalsInvalid = false
      setExistErrorsResult(true)
    }
    setErrors(newErrors)
    setAmount(parseFloat(event.target.value) * 100)
  }

  const topUp = async () => {
    setPaymentView(true)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Transition.Root show={open} as={Fragment}>

      <Dialog
        as="div"
        className="relative z-10"
        onClose={setOpen}
        onClick={() => {
          setOpen(false)
          setPaymentView(false)
        }
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">

          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="">
                  <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-yellow-50">
                    <CurrencyDollarIcon
                      className="h-8 w-8 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  {/* <span
                    className="block text-bg mb-4 text-center font-bold text-gray-700"
                  >
                    {client.userId}
                  </span> */}
                  {paymentView ?
                    <Payment setTopUps={setTopUps} amount={amount} currency={currency} />
                    :
                    <>
                      <div className="mt-3">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Saldo actual
                        </label>
                        <div className="relative mt-1 w-52 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">{currencies[currency].symbol}</span>
                          </div>
                          <input
                            type="text"
                            name="price"
                            id="price"
                            defaultValue={client.balance}
                            step="0.01"
                            disabled
                            className="block w-full rounded-md border-gray-300 pl-7 py-2 pr-12 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              {currencies[currency].code}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <label
                          htmlFor="price"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Saldo a añadir
                        </label>
                        <div className="relative mt-1 w-52 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">{currencies[currency].symbol}</span>
                          </div>
                          <input
                            type="text"
                            name="add-price"
                            id="add-price"
                            step="0.01"
                            className={existErrorsResult ?
                              'block w-full rounded-md pl-7 py-2 pr-12 bg-gray-200 text-gray-700 border border-red-500 focus:outline-none focus:bg-white' :
                              "block w-full rounded-md border-gray-300 pl-7 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"}
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            onChange={checkNumber}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              {currencies[currency].code}
                            </span>
                          </div>
                        </div>
                        {Object.keys(errors).map(error => {
                          if (errors[error]) {
                            return (
                              <p className='text-red-500 text-xs italic'>{errorText[error]}</p>
                            )
                          }
                        })
                        }
                        <div className="text-right sm:col-span-2">
                          <button
                            type="submit"
                            disabled={existErrorsResult}
                            onClick={topUp}
                            className={(!existErrorsResult ? "" : "opacity-50 cursor-not-allowed") +
                              "inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            }
                          >
                            Top Up
                          </button>
                        </div>
                      </div>
                    </>
                  }

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AddMoneyModal
