import {
  WifiIcon,
  Battery50Icon,
  ChartBarIcon,
  UserCircleIcon
} from '@heroicons/react/20/solid'

import React from 'react'
import { parseTextToHTML } from '../../../../libs/parse'

const Header = () => {
  return (
    <header className="sticky top-0 bg-white shadow z-10 px-4 py-3 text-grey-blue ">
      <div id="phoneInfo" className="flex">
        <p className="text-sm font-bold">1:37</p>
        <div id="phoneStatus" className="ml-auto flex gap-2">
          <WifiIcon className="h-5 w-5 " />
          <ChartBarIcon className="h-5 w-5 " />
          <Battery50Icon className="h-5 w-5 " />
        </div>
      </div>
      <UserCircleIcon className="h-8 w-8 mx-auto" />
    </header>
  )
}

const RichCard = ({ card, isFromCrousel }) => {
  return (
    <div
      className={`flex flex-col border-2 border-gray-400 rounded-2xl overflow-hidden ${
        isFromCrousel ? 'min-w-[200px]' : ''
      }`}
    >
      <img
        src={card.image.preview}
        alt={card.title || 'image'}
        style={{ aspectRatio: !isFromCrousel ? 2 / 1 : 4 / 3 }}
        className="w-full object-cover "
      />
      <div
        id="cardInfo"
        className="flex flex-col bg-white px-4 py-2 min-h-[80px] justify-between"
      >
        <div>
          {card.title && <h2 className="text-lg font-bold">{card.title}</h2>}
          {card.description && <p className="text-sm">{card.description}</p>}
        </div>
        {card.buttons && card.buttons.length > 0 && (
          <div className="flex flex-col">
            {card.buttons.map((button) => (
              <p
                key={button.text}
                type="button"
                className=" text-indigo-500 px-4 py-2 border-none text-center"
              >
                {button.text}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const Carousel = ({ carousel }) => {
  return (
    <div className="flex flex-col gap-4">
      <div
        id="cards"
        className="flex gap-2 -mx-4 pl-4 overflow-x-auto scrollbar-styled"
      >
        {carousel.cards.map((card) => (
          <RichCard key={card.title} card={card} isFromCrousel />
        ))}
      </div>
    </div>
  )
}

const TextMessage = ({ message, isFirst }) => {
  return (
    <div className="flex gap-2">
      {isFirst && <UserCircleIcon className="h-8 w-8 text-grey-blue" />}
      <div className="bg-blue-light/25 rounded-2xl p-2 min-w-[40px]">
        <p className="w-fit">{parseTextToHTML(message.text)}</p>
      </div>
    </div>
  )
}

const Suggestions = ({ suggestions }) => {
  return (
    <div className="-mx-4">
      <div
        id="suggestions"
        className="flex px-4 ml-auto w-fit max-w-full gap-2 self-end overflow-x-auto scrollbar-styled "
      >
        {suggestions.map((suggestion) => (
          <p
            key={suggestion.text}
            type="button"
            className=" border-2 border-gray-400 px-4 py-1 text-center rounded-full whitespace-nowrap"
          >
            {suggestion.text}
          </p>
        ))}
      </div>
    </div>
  )
}

const EmptyFlow = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full bg-blue-light/25 rounded-2xl p-2">
      <p className="text-lg font-bold">No elements</p>
      <p className="text-sm">Add elements to visualize them</p>
    </div>
  )
}

const getElements = (elements) => {
  let rbmElements = []
  if (elements.length === 0) {
    rbmElements.push(<EmptyFlow key="empty" />)
    return rbmElements
  }
  rbmElements = elements.map((element, index) => {
    switch (element.type) {
      case 'TEXT_TYPE':
        return (
          <TextMessage
            key={element.name || index}
            message={element}
            isFirst={index === 0}
          />
        )
      case 'CARD_TYPE':
        return <RichCard key={element.name} card={element} />
      case 'CAROUSEL_TYPE':
        return <Carousel key={element.name} carousel={element} />
      default:
        return null
    }
  })

  if (
    elements[elements.length - 1].suggestions &&
    elements[elements.length - 1].suggestions.length > 0
  ) {
    rbmElements.push(
      <Suggestions
        key="suggestions"
        suggestions={elements[elements.length - 1].suggestions}
      />
    )
  }

  return rbmElements
}

const PhonePreview = ({ rbmElements = [] }) => {
  return (
    <div className=" flex flex-col relative phone w-full max-w-[300px] min-w-[300px] border-4 border-grey-blue/30  rounded-3xl shadow-2xl bg-white overflow-hidden">
      <Header />
      <div className="h-full overflow-auto scrollbar-styled">
        <div id="messages" className="flex flex-col p-4 gap-2 scrollbar-styled">
          {getElements(rbmElements)}
        </div>
      </div>
    </div>
  )
}

export default PhonePreview
