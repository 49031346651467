import React, { useEffect, useState } from 'react'
import FlowBuilder from './FlowBuilder'
import {
  PencilSquareIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { CARD_TYPE, CAROUSEL_TYPE, REPLY_BUTTON } from './constants'
import { EyeSvg } from '../../../../components/Icons'

const getRbmElements = (rbmElements) => {
  const rbmElementsCopy = { ...rbmElements }
  delete rbmElementsCopy.rbmFlowAliasesMap
  const groupedElements = {}
  Object.keys(rbmElementsCopy)
    .map((key) => key.split('-')[0])
    .forEach((flow) => {
      if (!groupedElements[flow]) {
        groupedElements[flow] = []
        const flowLength = Object.keys(rbmElementsCopy).filter(
          (key) => key.split('-')[0] === flow
        ).length
        for (let i = 0; i < flowLength; i++) {
          groupedElements[flow].push(
            rbmElementsCopy[`${flow}-${i + 1}/${flowLength}`]
          )
        }
      }
    })
  return groupedElements
}

const getRbmFlowAliasesMap = (rbmElements) => {
  if (rbmElements.rbmFlowAliasesMap) {
    return rbmElements.rbmFlowAliasesMap
  }
  return {}
}

const ConversationalFlow = ({
  rbmElements = {},
  setMobileMessages,
  setRbmElements
}) => {
  const [rbmFlows, setRbmFlows] = useState(
    Object.keys(rbmElements).length > 0
      ? getRbmElements(rbmElements)
      : { init: [] }
  )
  const [visibleFlow, setVisibleFlow] = useState('init')
  const [rbmFlowAliasesMap, setAliasesState] = useState(
    getRbmFlowAliasesMap(rbmElements)
  )

  useEffect(() => {
    setMobileMessages(rbmFlows[visibleFlow])
  }, [])

  useEffect(() => {
    //if we remove the actual visible flow, we set the init flow as visible
    if (!rbmFlows[visibleFlow]) {
      setVisibleFlow('init')
      return
    }
    setMobileMessages(rbmFlows[visibleFlow])
  }, [rbmFlows])

  useEffect(() => {
    setMobileMessages(rbmFlows[visibleFlow])
  }, [visibleFlow])

  const setRbmFlowAliasesMap = (newRbmFlowAliasesMap) => {
    setAliasesState(newRbmFlowAliasesMap)
    setRbmElements({
      ...rbmElements,
      rbmFlowAliasesMap: newRbmFlowAliasesMap
    })
  }

  const setFlow = (flowId, newFlow) => {
    const newRbmFlows = { ...rbmFlows, [flowId]: newFlow }
    setRbmFlows(newRbmFlows)
    const newRbmElements = Object.keys(newRbmFlows).reduce((acc, flow) => {
      newRbmFlows[flow].forEach((element, index) => {
        acc[`${flow}-${index + 1}/${newRbmFlows[flow].length}`] = element
      })
      return acc
    }, {})
    newRbmElements.rbmFlowAliasesMap = rbmFlowAliasesMap
    setRbmElements(newRbmElements)
  }

  const getRbmFlowElements = (flowId) => {
    const rbmFlowElements = {}
    for (let i = 0; i < rbmFlows[flowId].length; i++) {
      rbmFlowElements[`${flowId}-${i + 1}/${rbmFlows[flowId].length}`] =
        rbmFlows[flowId][i]
    }
    return rbmFlowElements
  }

  const getFlowAlias = (flowId) => {
    if (rbmFlowAliasesMap[flowId]) {
      return rbmFlowAliasesMap[flowId]
    }
    return 'Response ' + flowId.split('response')[1]
  }

  const onClickEditFlowName = (flowAliasRef) => {
    flowAliasRef.current.focus()
  }

  const isAliasInUse = (alias, flowId) => {
    const rbmFlowAliases = Object.keys(rbmFlows)
      .map((flow) => {
        if (flow !== flowId && flow !== 'init') return getFlowAlias(flow)
        return null
      })
      .filter((alias) => alias !== null)
    return rbmFlowAliases.includes(alias)
  }

  const addFlow = () => {
    let num = Object.keys(rbmFlows).length
    let newFlowId = 'response' + num
    while (
      rbmFlows[newFlowId] ||
      isAliasInUse(getFlowAlias(newFlowId), newFlowId)
    ) {
      newFlowId = 'response' + ++num
    }
    setRbmFlows({ ...rbmFlows, [newFlowId]: [] })
  }

  const isResponseInUse = (flowId) => {
    const isReplyInButtons = (buttons, flowId) => {
      return buttons.some((button) => {
        if (button.type === REPLY_BUTTON && button.replyFlow === flowId) {
          return true
        }
        return false
      })
    }
    let isReply = Object.keys(rbmFlows).some((flow) => {
      if (flow === flowId) return false

      //check if the flow has a reply suggestion that points to the flowId
      return rbmFlows[flow].some((element) => {
        let replyInFlow = false
        if (element.suggestions) {
          replyInFlow = isReplyInButtons(element.suggestions, flowId)
          if (replyInFlow) {
            return true
          }
        }

        //check if the element has a reply button that points to the flowId
        switch (element.type) {
          case CARD_TYPE:
            if (element.buttons) {
              replyInFlow = isReplyInButtons(element.buttons, flowId)
              if (replyInFlow) {
                return true
              }
            }
            break
          case CAROUSEL_TYPE:
            if (element.cards) {
              replyInFlow = element.cards.some((card) => {
                if (card.buttons) {
                  return isReplyInButtons(card.buttons, flowId)
                }
                return false
              })
              if (replyInFlow) {
                return true
              }
            }
            break
          default:
            break
        }
        return false
      })
    })
    return isReply
  }

  const deleteFlow = (flowId) => {
    if (isResponseInUse(flowId)) {
      alert(
        'This response is being used for a reply. Please remove the reference before deleting it.'
      )
      return
    }
    const newRbmFlows = { ...rbmFlows }
    delete newRbmFlows[flowId]
    setRbmFlows(newRbmFlows)

    const newRbmElements = Object.keys(newRbmFlows).reduce((acc, flow) => {
      newRbmFlows[flow].forEach((element, index) => {
        acc[`${flow}-${index + 1}/${newRbmFlows[flow].length}`] = element
      })
      return acc
    }, {})
    const newRbmFlowAliasesMap = { ...rbmFlowAliasesMap }
    delete newRbmFlowAliasesMap[flowId]
    setRbmElements({
      ...newRbmElements,
      rbmFlowAliasesMap: newRbmFlowAliasesMap
    })
  }

  const handleKeyDownOnFlowAlias = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      //desseleccionar el elemento quitar el focus
      e.currentTarget.blur()
      const selection = window.getSelection()
      selection.removeAllRanges()
      return
    }
  }

  const handleAliasChange = (e, flowId) => {
    if (e.currentTarget.innerText.trim() === '') {
      e.currentTarget.innerText = getFlowAlias(flowId)
    }
    if (isAliasInUse(e.currentTarget.innerText.trim(), flowId)) {
      e.currentTarget.innerText = getFlowAlias(flowId)
      alert('This flow name already exists. Please choose another one.')
      return
    }
    e.currentTarget.innerText = e.currentTarget.innerText.trim()
    setRbmFlowAliasesMap({
      ...rbmFlowAliasesMap,
      [flowId]: e.currentTarget.innerText.trim()
    })
  }

  return (
    <div className="mt-4">
      <div className="flex gap-2">
        <h1 className="text-2xl w-fit font-bold text-grey-blue">
          Initial Comunication
        </h1>
        <button
          onClick={() => {
            setVisibleFlow('init')
          }}
          className={`h-full items-center ${
            visibleFlow === 'init' ? 'text-grey-blue' : 'text-grey-blue/25'
          } hover:text-grey-blue transition-colors duration-300`}
        >
          <EyeSvg className="h-8 w-8 stroke-current" />
        </button>
      </div>
      <FlowBuilder
        flowId={'init'}
        rbmElements={rbmFlows['init'] ? getRbmFlowElements('init') : {}}
        setFlow={setFlow}
        setMobileMessages={setMobileMessages}
        isConversational
        rbmFlowAliases={rbmFlowAliasesMap}
        rbmFlows={rbmFlows}
      />

      {Object.keys(rbmFlows).map((flow) => {
        if (flow !== 'init') {
          const flowAliasRef = React.createRef()

          return (
            <div
              key={flow}
              className="flex flex-col mt-4 border-t-2 border-blue-light/50"
            >
              <div className="flex items-center gap-4">
                <h1
                  ref={flowAliasRef}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  className="text-2xl w-fit font-bold text-grey-blue focus:outline-none focus:border-b-2 focus:border-grey-blue "
                  onBlur={(e) => handleAliasChange(e, flow)}
                  onKeyDown={(e) => handleKeyDownOnFlowAlias(e)}
                  spellCheck={false}
                >
                  {getFlowAlias(flow)}
                </h1>

                <button
                  className="border-none outline-none"
                  onClick={() => onClickEditFlowName(flowAliasRef)}
                >
                  <PencilSquareIcon className="h-6 w-6 text-grey-blue" />
                </button>
                <button
                  onClick={() => {
                    setVisibleFlow(flow)
                  }}
                  className={`h-full items-center ${
                    visibleFlow === flow
                      ? 'text-grey-blue'
                      : 'text-grey-blue/25'
                  } hover:text-grey-blue transition-colors duration-300`}
                >
                  <EyeSvg className="h-8 w-8 stroke-current" />
                </button>
              </div>
              <FlowBuilder
                key={flow}
                flowId={flow}
                rbmElements={getRbmFlowElements(flow)}
                setFlow={setFlow}
                setMobileMessages={setMobileMessages}
                isConversational
                rbmFlowAliases={rbmFlowAliasesMap}
                rbmFlows={rbmFlows}
              />
              <button
                onClick={() => deleteFlow(flow)}
                className="h-14 flex w-fit items-center gap-2 border-none outline-none group "
              >
                <TrashIcon className="h-6 w-6 text-red-300 group-hover:text-red-500 transition-colors duration-300" />
                <span className="text-lg text-red-300 font-medium text-center group-hover:text-red-500 transition-colors duration-300">
                  Delete Response
                </span>
              </button>
            </div>
          )
        }
        return null
      })}
      {Object.keys(rbmFlows).length < 3 && (
        <button
          onClick={addFlow}
          className="h-14 flex  items-center gap-2 border-none outline-none group "
        >
          <PlusIcon className="h-6 w-6 text-indigo-300 group-hover:text-indigo-500 transition-colors duration-300" />
          <span className="text-lg text-indigo-300 font-medium text-center group-hover:text-indigo-500 transition-colors duration-300">
            Add Response
          </span>
        </button>
      )}
    </div>
  )
}

export default ConversationalFlow
