import React from 'react'
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

const NewElement = ({
  action,
  isNewCard = false,
  isNewButton = false,
  isNewSugegstion = false
}) => {
  const getClasses = () => {
    if (isNewButton) {
      return 'h-14 w-1/3 flex gap-2 justify-center items-center border-dashed border-2 border-indigo-300 bg-blue-50 rounded-md hover:scale-105 transition duration-300 ease-in-out'
    }
    if (isNewCard) {
      return 'h-80 w-1/3 flex flex-col  justify-center items-center border-dashed border-2 border-indigo-300 bg-blue-50 rounded-md hover:scale-105 transition duration-300 ease-in-out'
    }
    if (isNewSugegstion) {
      return 'h-14 w-1/3 flex gap-2 justify-center items-center border-dashed border-2 border-indigo-300 bg-blue-50 rounded-xl hover:scale-105 transition duration-300 ease-in-out'
    }
    return 'h-20 w-1/3 flex flex-col  justify-center items-center border-dashed border-2 border-indigo-300 bg-blue-50 rounded-md hover:scale-105 transition duration-300 ease-in-out'
  }

  const getText = () => {
    if (isNewCard) {
      return 'Add Card'
    }
    if (isNewButton) {
      return 'Add Button'
    }
    return 'New Element'
  }

  return (
    <button onClick={() => action()} className={getClasses()}>
      <PlusCircleIcon className="h-6 w-6 text-indigo-300" />
      <span className="text-lg text-indigo-300 font-medium text-center">
        {getText()}
      </span>
    </button>
  )
}

export default NewElement
