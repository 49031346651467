import React, { useState } from 'react'
import SelectRbmType from './SelectRbmType'
import {
  RBM_BASIC_TYPE,
  RBM_SIMPLE_TYPE,
  RBM_CONVERSATIONAL_TYPE,
  RBM_TYPES
} from './constants'
import ChangeFlow from './ChangeFlow'
import SimpleFlow from './SimpleFlow'
import BasicFlow from './BasicFlow'
import ConversationalFlow from './ConversationalFlow'

const getRbmType = (type) => {
  const rbmType = RBM_TYPES.find((rbm) => rbm.type === type)
  return rbmType
}

const getFlow = (
  type,
  setMobileMessages,
  setRbmElements,
  rbmElements,
  rbmSimpleErrors
) => {
  const rbmType = getRbmType(type)
  let Flow = SimpleFlow
  switch (rbmType.type) {
    case RBM_SIMPLE_TYPE:
      Flow = SimpleFlow
      break
    case RBM_BASIC_TYPE:
      Flow = BasicFlow
      break
    case RBM_CONVERSATIONAL_TYPE:
      Flow = ConversationalFlow
      break
    default:
      Flow = SimpleFlow
  }

  return (
    <Flow
      setMobileMessages={setMobileMessages}
      setRbmElements={setRbmElements}
      rbmElements={rbmElements}
      rbmSimpleErrors={rbmSimpleErrors}
    />
  )
}

export default function CreateRbm({
  rbmType,
  setMobileMessages,
  setRbmType,
  setRbmElements,
  rbmElements,
  rbmSimpleErrors
}) {
  return (
    <>
      {!rbmType ? (
        <SelectRbmType setRbmType={setRbmType} />
      ) : (
        <ChangeFlow
          setRbmType={setRbmType}
          rbmTypeName={getRbmType(rbmType).name}
          setRbmElements={setRbmElements}
          setMobileMessages={setMobileMessages}
        >
          {getFlow(
            rbmType,
            setMobileMessages,
            setRbmElements,
            rbmElements,
            rbmSimpleErrors
          )}
        </ChangeFlow>
      )}
    </>
  )
}
