import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid'
import { CursorArrowRippleIcon } from '@heroicons/react/24/outline'
import Modal from '../../components/Modal'
import { useAppContext } from '../../libs/context'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statsMain = [
  {
    id: 1,
    name: 'Últimos envíos',
    stat: 0,
    icon: CursorArrowRippleIcon,
    change: '122',
    changeType: 'increase',
    color: 'bg-blue-dark text-white'
  },
  {
    id: 2,
    name: 'Últimos entregados',
    stat: 0,
    icon: CursorArrowRippleIcon,
    change: '5.4%',
    changeType: 'increase',
    color: 'bg-gray-100 text-grey-blue'
  },
  {
    id: 3,
    name: 'Últimos mostrados',
    stat: 0,
    icon: CursorArrowRippleIcon,
    change: '3.2%',
    changeType: 'decrease',
    color: 'bg-gray-100 text-grey-blue'
  }
]

const modalMsg = {
  title: 'Sin campañas',
  content:
    'No tienes ningún flujo de campaña creado, por favor contacta con support@tadoo.biz para realizar este paso'
}

const Dashboard = () => {
  const [stats, setStats] = useState(statsMain)
  const [modalContent, setModalContent] = useState(false)
  const [open, setOpen] = useState(false)
  const { userInfo } = useAppContext();

  const getStats = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const campaigns = await API.get('rcs-api', '/campaigns', {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      })
      if (campaigns.length) {
        const campaign = campaigns.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )
        const campapignStats = await API.get(
          'rcs-api',
          `/campaign-stats/${campaign[0].campaignId}`,
          {
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken
            }
          }
        )
        const currentStats = [...statsMain]
        setStats(
          currentStats.map((item) => {
            if (item.id === 1) {
              item.stat = campapignStats.rcs.status.sent
            } else if (item.id === 2) {
              item.stat = campapignStats.rcs.status.delivered
            } else if (item.id === 3) {
              item.stat = campapignStats.rcs.status.displayed
            }
            return item
          })
        )
      } else {
        setModalContent(modalMsg)
        if (!userInfo.isAdmin) {
          setOpen(true)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getStats()
  }, [])
  return (
    <div>
      <Modal {...modalContent} open={open} setOpen={setOpen} />
      {/* <h3 className="text-lg font-medium leading-6 text-gray-900">
        Last 30 days
      </h3> */}
      <dl className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className={`relative overflow-hidden rounded-2xl px-4 pt-5 pb-12 sm:px-6 sm:pt-6 ${item.color}`}
          >
            <dt>
              <p className="truncate text-2xl font-bold">{item.name}</p>
            </dt>
            {item.stat !== 0 && (
              <dd className="flex items-center pb-6 sm:py-4">
                <p className="text-2xl font-semibold">{item.stat}</p>
                <p
                  className={classNames(
                    item.changeType === 'increase'
                      ? item.color.includes('gray-100')
                        ? 'text-green-600'
                        : 'bg-green-600'
                      : item.color.includes('gray-100')
                        ? 'text-red-600'
                        : 'bg-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold px-2 py-1 rounded-lg'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon
                      className={classNames(
                        item.color.includes('gray-100')
                          ? 'text-green-600'
                          : 'text-white',
                        'h-5 w-5 flex-shrink-0 self-center'
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className={classNames(
                        item.color.includes('gray-100')
                          ? 'text-red-600'
                          : 'text-white',
                        'h-5 w-5 flex-shrink-0 self-center'
                      )}
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {' '}
                    {item.changeType === 'increase'
                      ? 'Increased'
                      : 'Decreased'}{' '}
                    by{' '}
                  </span>
                  {item.change}
                </p>
              </dd>
            )}
          </div>
        ))}
      </dl>
      <dl className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div
          key=""
          className="relative overflow-hidden rounded-2xl px-4 pt-5 pb-12 sm:px-6 sm:pt-6"
        >
          <dd className="flex items-center pb-6 sm:py-4"></dd>
        </div>
      </dl>
    </div>
  )
}

export default Dashboard
