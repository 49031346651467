import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const RichCardPreview = ({ card, onClick, onRemove = null }) => {
  const handleClickRemove = (event) => {
    event.stopPropagation()
    onRemove()
  }

  return (
    <div
      id="richCard"
      className="cursor-pointer group flex flex-col relative w-1/3 h-80 justify-between border-4 rounded-2xl overflow-hidden hover:scale-105 transition duration-300 ease-in-out"
      onClick={onClick}
    >
      {onRemove && (
        <button
          onClick={(e) => handleClickRemove(e)}
          className="absolute top-2 right-2 z-10 text-red-500 opacity-0 group-hover:opacity-100 hover:scale-105 bg-white/70 hover:bg-red-500 hover:text-white  transition duration-300 ease-in-out"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      )}
      <div>
        <img
          style={{ aspectRatio: '4/3' }}
          className="object-cover w-full"
          src={
            card.image
              ? card.image.url || (card.image.preview ? card.image.preview : '')
              : ''
          }
          alt="RichCard"
        />
        <div className="felx felx-col min-h-[30px]">
          {card.title && (
            <p className="font-medium text-base p-4 leading-5">{card.title}</p>
          )}
          {card.description && (
            <p className="font-light mt-1 text-sm p-4 leading-4">
              {card.description}
            </p>
          )}
        </div>
      </div>
      <div id="buttonsGroup">
        {card.buttons &&
          card.buttons.map((button) => (
            <div id="linkButton" className="p-4 w-full text-center">
              <span className="text-blue-500">{button.text}</span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RichCardPreview
