import React, { useState, useEffect } from 'react'

import {
  RichCardSvg,
  CarouselSvg,
  VectorVSvg,
  TextSvg
} from '../../components/Icons'
import { RichCardStats } from './RichCardStats'
import { CarouselStats } from './CarouselStats'
import { BasicStatsLayout } from './BasicStatsLayout'
import { TextStats } from './TextStats'

const RICH_CARD_TYPE = 'card'
const CAROUSEL_TYPE = 'carousel'
const TEXT_TYPE = 'text'

const renderElement = (element) => {
  switch (element.type) {
    case RICH_CARD_TYPE:
      return (
        <BasicStatsLayout elementInfo={element}>
          <RichCardStats elementInfo={element} />
        </BasicStatsLayout>
      )
    case CAROUSEL_TYPE:
      return <CarouselStats elementInfo={element} />
    case TEXT_TYPE:
      return (
        <BasicStatsLayout elementInfo={element}>
          <TextStats elementInfo={element} />
        </BasicStatsLayout>
      )

    default:
      return <div></div>
  }
}
const getElementIcon = (element) => {
  switch (element.type) {
    case RICH_CARD_TYPE:
      return <RichCardSvg fill="white" stroke="white" />
    case CAROUSEL_TYPE:
      return <CarouselSvg fill="white" stroke="white" />
    case TEXT_TYPE:
      return <TextSvg stroke="white" />
    default:
      return <div></div>
  }
}
const Element = ({ element, action }) => {
  return (
    <button
      className="flex items-center justify-center py-6 transition-colors duration-500 ease-out hover:bg-gray-200 border-b border-t"
      onClick={() => action()}
    >
      <span className="whitespace-nowrap text-xl font-medium ">
        {element.name}
      </span>
    </button>
  )
}
export const StatisticsRbm = ({ rbmElements }) => {
  const [selectedElement, setSelectedElement] = useState()
  const [elementsList, setElementslist] = useState([])

  useEffect(() => {
    setElementslist(rbmElements)
    setSelectedElement(rbmElements[0])
    return
  }, [])
  return (
    <article className="flex flex-col w-full mt-4">
      <div id="elementsSection" className="max-h-24 relative mb-1">
        <div
          id="selectedWithAll"
          className="w-fit bg-white hover:shadow-md p-4 -ml-4 rounded-2xl overflow-hidden group"
        >
          <button className="flex flex-row w-full items-center justify-between bg-gray-400 rounded-md gap-4 p-4">
            <div id="rbmIdentity" className="flex flex-row gap-4 items-center">
              <div id="rbmIcon" className="w-8 h-9">
                {selectedElement && getElementIcon(selectedElement)}
              </div>
              <h2 className="text-white whitespace-nowrap text-xl font-medium ">
                {selectedElement && selectedElement.name}
              </h2>
            </div>
            <div className="flex justify-end">
              <VectorVSvg />
            </div>
          </button>
          <div
            id="elementsList"
            className="flex flex-col max-h-0 scrollbar-gutter overflow-auto group-hover:mt-2 group-hover:max-h-80 transition-all duration-700 ease-out"
          >
            {elementsList.map((el) => (
              <Element element={el} action={() => setSelectedElement(el)} />
            ))}
          </div>
        </div>
      </div>
      {selectedElement && renderElement(selectedElement)}
    </article>
  )
}
