import Bourne from '@hapi/bourne'
import React from 'react'
import {
  CARD_TYPE,
  CAROUSEL_TYPE,
  LINK_BUTTON,
  PHONE_CALL_BUTTON,
  REPLY_BUTTON,
  TEXT_TYPE
} from '../scenes/Templates/Create/components/constants'

const parseSecurely = (text) => {
  return Bourne.safeParse(text)
}

const parseTextToHTML = (text) => {
  const splitText = text.trim().split(/\r?\n/)
  return splitText.map((line, index) => {
    if ((index === 1 && line === '') || index === splitText.length - 1) {
      return <>{line}</>
    } else {
      return (
        <>
          {line}
          <br></br>
        </>
      )
    }
  })
}

const getOrangeSuggestions = (buttons) => {
  return buttons.map((button) => {
    let suggestion = {}
    switch (button.type) {
      case LINK_BUTTON:
        suggestion = {
          action: {
            displayText: button.text,
            urlAction: {
              openUrl: {
                url: button.link
              }
            }
          }
        }
        if (button.postback) {
          suggestion.action.postback = { data: button.postback }
        } else {
          suggestion.action.postback = { data: 'none' }
        }
        break
      case PHONE_CALL_BUTTON:
        suggestion = {
          action: {
            displayText: button.text,
            dialerAction: {
              dialPhoneNumber: {
                phoneNumber: button.phone
              }
            }
          }
        }
        if (button.postback) {
          suggestion.action.postback = { data: button.postback }
        } else {
          suggestion.action.postback = { data: 'none' }
        }
        break
      case REPLY_BUTTON:
        suggestion = {
          reply: {
            displayText: button.text
          }
        }
        if (button.postback) {
          suggestion.reply.postback = { data: button.postback }
        } else {
          suggestion.reply.postback = { data: 'none' }
        }
        break
      default:
        break
    }
    return suggestion
  })
}

const getGoogleSuggestions = (buttons) => {
  return buttons.map((button) => {
    let suggestion = {}
    switch (button.type) {
      case LINK_BUTTON:
        suggestion = {
          action: {
            text: button.text,
            openUrlAction: {
              url: button.link
            }
          }
        }
        if (button.postback) {
          suggestion.action.postbackData = button.postback
        } else {
          suggestion.action.postbackData = 'none'
        }
        break

      case PHONE_CALL_BUTTON:
        suggestion = {
          action: {
            text: button.text,
            dialAction: {
              phoneNumber: button.phone
            }
          }
        }
        if (button.postback) {
          suggestion.action.postbackData = button.postback
        } else {
          suggestion.action.postbackData = 'none'
        }
        break
      case REPLY_BUTTON:
        suggestion = {
          reply: {
            text: button.text
          }
        }
        if (button.postback) {
          suggestion.reply.postbackData = button.postback
        } else {
          suggestion.reply.postbackData = 'none'
        }
        break
      default:
        break
    }
    return suggestion
  })
}

const getOrangeCard = (card) => {
  const suggestions = card.buttons ? getOrangeSuggestions(card.buttons) : []
  const newCard = {
    media: {
      mediaUrl: card.image.url,
      mediaContentType: 'image/png',
      mediaFileSize: 44800,
      height: 'MEDIUM_HEIGHT'
    }
  }
  if (card.title) {
    newCard.title = card.title
  }
  if (card.description) {
    newCard.description = card.description
  }
  if (suggestions.length > 0) {
    newCard.suggestions = suggestions
  }
  return newCard
}

const getGoogleCard = (card) => {
  const suggestions = card.buttons ? getGoogleSuggestions(card.buttons) : []
  const newCard = {
    media: {
      height: 'MEDIUM',
      contentInfo: {
        fileUrl: card.image.url,
        forceRefresh: 'false'
      }
    }
  }
  if (card.title) {
    newCard.title = card.title
  }
  if (card.description) {
    newCard.description = card.description
  }
  if (suggestions.length > 0) {
    newCard.suggestions = suggestions
  }
  return newCard
}

const getOrangeElementType = (element) => {
  if (element.RCSMessage.textMessage) {
    return TEXT_TYPE
  } else if (element.RCSMessage.richcardMessage) {
    if (element.RCSMessage.richcardMessage.message.generalPurposeCard) {
      return CARD_TYPE
    } else if (
      element.RCSMessage.richcardMessage.message.generalPurposeCardCarousel
    ) {
      return CAROUSEL_TYPE
    }
  }
  return null
}

const parseTemplateToOrangeRbm = (template) => {
  const orangeRbm = {}
  Object.keys(template).forEach((key) => {
    const rbmElement = template[key]
    const rbmType = rbmElement.type
    const rbmTypeKey = key
    orangeRbm[rbmTypeKey] = {}
    orangeRbm[rbmTypeKey].name = rbmElement.name
    orangeRbm[rbmTypeKey].RCSMessage = {}
    switch (rbmType) {
      case TEXT_TYPE:
        orangeRbm[rbmTypeKey].RCSMessage.textMessage = rbmElement.text
        break
      case CARD_TYPE:
        orangeRbm[rbmTypeKey].RCSMessage.richcardMessage = {
          message: {
            generalPurposeCard: {
              layout: {
                cardOrientation: 'VERTICAL',
                imageAlignment: 'LEFT'
              },
              content: getOrangeCard(rbmElement)
            }
          }
        }
        if (rbmElement.buttons) {
          orangeRbm[
            rbmTypeKey
          ].RCSMessage.richcardMessage.message.generalPurposeCard.content.suggestions =
            getOrangeSuggestions(rbmElement.buttons)
        }
        break
      case CAROUSEL_TYPE:
        orangeRbm[rbmTypeKey].RCSMessage.richcardMessage = {
          message: {
            generalPurposeCardCarousel: {
              layout: { cardWidth: 'MEDIUM_WIDTH' },
              content: rbmElement.cards.map((card) => {
                return getOrangeCard(card)
              })
            }
          }
        }
        break
      default:
        orangeRbm[rbmTypeKey] = rbmElement
        break
    }
    if (rbmElement.suggestions) {
      orangeRbm[rbmTypeKey].RCSMessage.suggestedChipList = {
        suggestions: getOrangeSuggestions(rbmElement.suggestions)
      }
    }
  })
  return orangeRbm
}

const parseTemplateToGoogleRbm = (template) => {
  const googleRbm = {}
  Object.keys(template).forEach((key) => {
    const rbmElement = template[key]
    const rbmType = rbmElement.type
    const rbmTypeKey = key

    googleRbm[rbmTypeKey] = {}
    googleRbm[rbmTypeKey].contentMessage = {}

    switch (rbmType) {
      case TEXT_TYPE:
        googleRbm[rbmTypeKey].contentMessage = rbmElement.text
        break
      case CARD_TYPE:
        googleRbm[rbmTypeKey].contentMessage.richCard = {
          standaloneCard: {
            thumbnailImageAlignment: 'RIGHT',
            cardOrientation: 'VERTICAL',
            cardContent: getGoogleCard(rbmElement)
          }
        }
        break
      case CAROUSEL_TYPE:
        googleRbm[rbmTypeKey].contentMessage.richCard = {
          carouselCard: {
            cardWidth: 'MEDIUM',
            cardContents: rbmElement.cards.map((card) => {
              return getGoogleCard(card)
            })
          }
        }
        break
      default:
        break
    }
    if (rbmElement.suggestions) {
      googleRbm[rbmTypeKey].contentMessage.suggestions = getGoogleSuggestions(
        rbmElement.suggestions
      )
    }
  })
  return googleRbm
}

const getOrangeButtons = (suggestions) => {
  return suggestions.map((suggestion) => {
    let button = {}
    if (suggestion.reply) {
      button = {
        text: suggestion.reply.displayText,
        type: REPLY_BUTTON
      }
      if (suggestion.reply.postback) {
        button.replyFlow = suggestion.reply.postback.data.split('-')[1]
      }
    } else if (suggestion.action) {
      button = {
        text: suggestion.action.displayText
      }
      if (suggestion.action.postback) {
        button.replyFlow = suggestion.action.postback.data.split('-')[1]
      }
      switch (suggestion.action.urlAction || suggestion.action.dialerAction) {
        case suggestion.action.urlAction:
          button.type = LINK_BUTTON
          button.link = suggestion.action.urlAction.openUrl.url
          break
        case suggestion.action.dialerAction:
          button.type = PHONE_CALL_BUTTON
          button.phone =
            suggestion.action.dialerAction.dialPhoneNumber.phoneNumber
          break
        default:
          break
      }
    }

    return button
  })
}

const getTemplateCardFromOrange = (card) => {
  const suggestions = card.suggestions ? getOrangeButtons(card.suggestions) : []
  const newCard = {
    image: {
      uploaded: true,
      preview: card.media.mediaUrl,
      url: card.media.mediaUrl
    }
  }
  if (card.title) {
    newCard.title = card.title
  }
  if (card.description) {
    newCard.description = card.description
  }
  if (suggestions.length > 0) {
    newCard.buttons = suggestions
  }
  return newCard
}

const parseOrangeToTemplateRbm = (orangeRbm) => {
  const template = {}
  Object.keys(orangeRbm).forEach((key) => {
    const rbmElement = orangeRbm[key]
    if (key === 'rbmFlowAliasesMap') {
      template[key] = rbmElement
      return
    }
    template[key] = {}
    template[key].name = rbmElement.name
    template[key].type = getOrangeElementType(rbmElement)
    switch (template[key].type) {
      case TEXT_TYPE:
        template[key].text = rbmElement.RCSMessage.textMessage
        break
      case CARD_TYPE:
        const card =
          rbmElement.RCSMessage.richcardMessage.message.generalPurposeCard
            .content
        const cardParsed = getTemplateCardFromOrange(card)
        template[key] = {
          ...template[key],
          ...cardParsed
        }
        break
      case CAROUSEL_TYPE:
        const carousel =
          rbmElement.RCSMessage.richcardMessage.message
            .generalPurposeCardCarousel.content
        template[key].cards = carousel.map((card) =>
          getTemplateCardFromOrange(card)
        )
        break
      default:
        template[key] = rbmElement
        break
    }
    if (rbmElement.RCSMessage.suggestedChipList) {
      template[key].suggestions = getOrangeButtons(
        rbmElement.RCSMessage.suggestedChipList.suggestions
      )
    }
  })
  return template
}

const getGoogleButtons = (suggestions) => {
  return suggestions.map((suggestion) => {
    let button = {}

    switch (suggestion.reply || suggestion.action) {
      case suggestion.reply:
        button = {
          text: suggestion.reply.text,
          type: REPLY_BUTTON
        }
        if (suggestion.reply.postbackData) {
          button.replyFlow = suggestion.reply.postbackData.split('-')[1]
        }
        break
      case suggestion.action:
        if (suggestion.action.dialAction) {
          button = {
            text: suggestion.action.text,
            type: PHONE_CALL_BUTTON,
            phone: suggestion.action.dialAction.phoneNumber
          }
          if (suggestion.action.postbackData) {
            button.replyFlow = suggestion.action.postbackData.split('-')[1]
          }
        } else if (suggestion.action.openUrlAction) {
          button = {
            text: suggestion.action.text,
            type: LINK_BUTTON,
            link: suggestion.action.openUrlAction.url
          }
          if (suggestion.action.postbackData) {
            button.replyFlow = suggestion.action.postbackData.split('-')[1]
          }
        }
        break
      default:
        break
    }
    return button
  })
}

const getTemplateCardFromGoogle = (card) => {
  const suggestions = card.suggestions ? getGoogleButtons(card.suggestions) : []
  const newCard = {
    image: {
      uploaded: true,
      preview: card.media.contentInfo.fileUrl,
      url: card.media.contentInfo.fileUrl
    }
  }
  if (card.title) {
    newCard.title = card.title
  }
  if (card.description) {
    newCard.description = card.description
  }
  if (suggestions.length > 0) {
    newCard.buttons = suggestions
  }
  return newCard
}

const getGoogleElementType = (element) => {
  if (element.contentMessage) {
    if (element.contentMessage.text) {
      return TEXT_TYPE
    } else if (element.contentMessage.richCard) {
      if (element.contentMessage.richCard.standaloneCard) {
        return CARD_TYPE
      } else if (element.contentMessage.richCard.carouselCard) {
        return CAROUSEL_TYPE
      }
    }
  }
  return null
}

const parseGoogleToTemplateRbm = (googleRbm) => {
  const template = {}
  Object.keys(googleRbm).forEach((key) => {
    const rbmElement = googleRbm[key]
    if (key === 'rbmFlowAliasesMap') {
      template[key] = rbmElement
      return
    }
    template[key] = {}
    template[key].name = rbmElement.name
    template[key].type = getGoogleElementType(rbmElement)
    switch (template[key].type) {
      case TEXT_TYPE:
        template[key].text = rbmElement.contentMessage
        break
      case CARD_TYPE:
        const card = rbmElement.contentMessage.richCard
        template[key] = getTemplateCardFromGoogle(
          card.standaloneCard.cardContent
        )
        break
      case CAROUSEL_TYPE:
        const carousel = rbmElement.contentMessage.richCard.carouselCard
        template[key].cards = carousel.cardContents.map((card) =>
          getTemplateCardFromGoogle(card)
        )
        break
      default:
        break
    }
    if (rbmElement.contentMessage.suggestions) {
      template[key].suggestions = getGoogleButtons(
        rbmElement.contentMessage.suggestions
      )
    }
  })
  return template
}

const parseTemplateToSupplierRbm = (template, supplier) => {
  switch (supplier) {
    case 'orange':
      return parseTemplateToOrangeRbm(template)
    case 'google':
      return parseTemplateToGoogleRbm(template)
    default:
      return {}
  }
}

const parseSupplierToTemplateRbm = (supplierRbm, supplier) => {
  switch (supplier) {
    case 'orange':
      return parseOrangeToTemplateRbm(supplierRbm)
    case 'google':
      return parseGoogleToTemplateRbm(supplierRbm)
    default:
      return {}
  }
}

export { parseSecurely, parseTextToHTML }
export { parseTemplateToSupplierRbm, parseSupplierToTemplateRbm }
