import React, { useEffect, useState } from 'react'
import { notification } from 'antd'

const Idletime = (params) => {
  const isDev = process.env.REACT_APP_STAGE === 'dev'
  const maxMinutes = 8
  const [warningTime, setWarningTime] = useState((maxMinutes - 2) * 60 * 1000)
  const [signoutTime, setSignoutTime] = useState(maxMinutes * 60 * 1000)
  let warnTimeout
  let logoutTimeout

  const openNotification = (placement) => {
    notification.info({
      message: 'Tiempo de inactividad',
      description:
        'Has estado 8 minutos sin hacer uso de la App, en 2 minutos más, si sigues inactivo, se cerrará la sesión.',
      placement
    })
  }

  const warn = () => {
    openNotification('topLeft')
  }
  const logout = () => {
    console.log('You have been loged out')
    params.logOutAction()
  }

  const destroy = () => {
    console.log('Session destroyed')
  }
  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime)
    logoutTimeout = setTimeout(logout, signoutTime)
  }

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout)
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  useEffect(() => {
    //not timeout for dev mode
    if (isDev)return;

    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ]

    const resetTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout)
    }

    setTimeouts()
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout)
        clearTimeouts()
      }
    }
  }, [])

  return <></>
}
export default Idletime
