const config = {
  dev: {
    publishableKey : "pk_test_51N14QoFYnkbdr1eY0gle0fJH6bEQJkuMoIeD1Yne3zwuhkelEkiYU5jnBrKqUHfFdBEOdY3F2R5qpJlDDDmombqg00CpOZFTyq"
  },
  prod: {
    publishableKey : "pk_live_51N14QoFYnkbdr1eYYawguJ8l86eIJ2XUtX1FyKQAFGyDiqEMfYkToi0dCwQkm9TAAIfSbp9KbDiDQC2X41WNdNck00rSr3gITH"
  }
}

export default {
  ...config[process.env.REACT_APP_STAGE]
}

