const dateToString = (date) => {
  const mom = { base: new Date(date) }
  const dateSplitted = mom.base.toISOString().split('T')[0].split('-').reverse()
  mom.day = dateSplitted.join('/')
  mom.time = mom.base.toLocaleTimeString('es-ES', {
    timeZone: 'Europe/Madrid',
    timeStyle: 'short'
  })
  if (mom.time.length === 4) {
    mom.time = `0${mom.time}`
  }
  mom.full = `${mom.day} ${mom.time}`
  mom.dayMin = `${dateSplitted[0]}/${dateSplitted[1]}/${dateSplitted[2].slice(
    -2
  )}`
  return mom
}

const transformSMSDate = (d) => {
  const year = d.substring(0, 2)
  const month = d.substring(2, 4)
  const day = d.substring(4, 6)
  const hour = d.substring(6, 8)
  const minutes = d.substring(8, 10)
  return new Date(`20${year}/${month}/${day} ${hour}:${minutes}`).toISOString()
}

export { dateToString, transformSMSDate }
