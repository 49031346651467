import React, { useState, useEffect, Fragment } from 'react'
import { API, Auth } from 'aws-amplify'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Spin from '../../components/Spin'
import TopUpModal from './TopUpModal'
import { Pagination, Select } from 'antd'
import currencies from '../../config/currency'
import ResultModal from '../../components/ResultModal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const nullClient = {
  userId: 'null',
  total_sent: 0,
  month_sent: 0,
  balance: 0.0
}

const modalMsg = {
  ok: {
    title: 'Operación realizada',
    content: 'El cambio en el saldo del cliente se ha realizado corectamente',
    cta: 'Ok'
  },
  error: {
    title: 'Error',
    content:
      'No se ha podido realizar la operación, por favor revise los datos introducidos y si el error persiste contacte con support@tadoo.biz para más información',
    cta: 'Error'
  }
}

const Clients = () => {
  const { Option } = Select
  const [loading, setLoading] = useState(false)
  const [clients, setClients] = useState([])
  const [originalClients, setOriginalClients] = useState([])
  const [changedClients, setChangedClients] = useState({})
  const [selectedClient, setSelectedClient] = useState(nullClient)
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [clientsPerPage, setClientsPerPage] = useState(10)
  const [modalContent, setModalContent] = useState(modalMsg.ok)
  const [openResult, setOpenResult] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const indexOfLast = currentPage * clientsPerPage
  const indexOfFirst = indexOfLast - clientsPerPage

  const getUsers = async () => {
    setLoading(true)
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const users = await API.get('rcs-api', '/users', {
        headers: {
          Authorization: tokenReq
        }
      })
      setClients(users)
      setOriginalClients(users)
      setFilteredList(users)
    } catch (err) {
      console.log("error con los clientes")
    }
    setLoading(false)
  }

  const saveChanges = async (e) => {
    e.preventDefault()
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      await API.post('rcs-api', '/users', {
        headers: {
          Authorization: tokenReq
        },
        body: changedClients
      })
    } catch (err) {
      console.log(err)
    }

    const newClients = clients.map((item) =>
      item.newCurrency ? { ...item, currency: item.newCurrency } : item
    )
    setClients(newClients)
  }

  function handleTopUp(event, client) {
    event.preventDefault()
    setSelectedClient(client)
    setOpen(true)
  }

  function removeFilter(e) {
    e.preventDefault()
    setFilter('')
    setFilteredList(clients)
    setCurrentPage(1)
  }

  function handleFilterSubmit(e) {
    e.preventDefault()
    setFilteredList(
      clients.filter((client) =>
        client.userId.toUpperCase().includes(filterInput.trim().toUpperCase())
      )
    )
    setFilter(filterInput)
    setFilterInput('')
    setCurrentPage(1)
  }

  const onChangeUsername = (e, userId) => {
    const { value } = e.target
    const editData = clients.map((item) =>
      item.userId === userId ? { ...item, username: value } : item
    )
    setClients(editData)
    setChangedClients((chClients) => {
      return {
        ...chClients,
        [userId]: { ...chClients[userId], username: value }
      }
    })
  }
  const onChangeSMS = (e, userId) => {
    const { value } = e.target
    const cost = parseFloat(value)
    const re = /^\d+(?:\.\d{1,2})?$|^\d+\.?$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const editData = clients.map((item) =>
        item.userId === userId ? { ...item, costPerSMS: value } : item
      )
      setClients(editData)
      setChangedClients((chClients) => {
        return {
          ...chClients,
          [userId]: { ...chClients[userId], costPerSMS: cost }
        }
      })
    }
  }

  const onChangeRBM = (e, userId) => {
    const { value } = e.target
    const cost = parseFloat(value)
    const re = /^\d+(?:\.\d{1,2})?$|^\d+\.?$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const editData = clients.map((item) =>
        item.userId === userId ? { ...item, costPerRBM: value } : item
      )
      setClients(editData)
      setChangedClients((chClients) => {
        return {
          ...chClients,
          [userId]: { ...chClients[userId], costPerRBM: cost }
        }
      })
    }
  }

  const onChangeCurrency = (currency, userId) => {
    const editData = clients.map((item) =>
      item.userId === userId ? { ...item, newCurrency: currency } : item
    )
    setClients(editData)
    setChangedClients((chClients) => {
      return {
        ...chClients,
        [userId]: { ...chClients[userId], currency: currency }
      }
    })
  }

  const onChangePaymentType = (type, userId) => {
    const editData = clients.map((item) =>
      item.userId === userId ? { ...item, paymentType: type } : item
    )
    setClients(editData)
    setChangedClients((chClients) => {
      return {
        ...chClients,
        [userId]: { ...chClients[userId], paymentType: type }
      }
    })
  }

  const checkFilter = (client) => {
    if (filter === '') {
      return true
    } else {
      if (
        (client.username &&
          client.username
            .toUpperCase()
            .includes(filter.trim().toUpperCase())) ||
        client.userId.toUpperCase().includes(filter.trim().toUpperCase()) ||
        (client.email &&
          client.email.toUpperCase().includes(filter.trim().toUpperCase()))
      )
        return true
    }
    return false
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <>
      <ResultModal
        {...modalContent}
        isLoading={isLoading}
        open={openResult}
        setOpen={setOpenResult}
      />
      <TopUpModal
        open={open}
        setClients={setClients}
        client={selectedClient}
        setOpen={setOpen}
        setOpenResult={setOpenResult}
        setModalContent={setModalContent}
        modalMsg={modalMsg}
        setIsLoading={setIsLoading}
      />
      {loading ? (
        <div className="flex justify-center my-16">
          <Spin type="lightBg" />
        </div>
      ) : (
        <div>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">
              Filtro
            </label>
            <input
              type="text"
              name="filter"
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="shadow block w-full rounded-md border-gray-300 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Filtro"
            />
          </div>

          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Nombre
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Coste por RBM
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Coste por SMS
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Modo de pago
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Moneda
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Saldo
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Top up</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {clients
                        .filter((client) => checkFilter(client))
                        .slice(indexOfFirst, indexOfLast)
                        .map((person, personIdx) => (
                          <tr
                            key={person.userId}
                            className={
                              personIdx % 2 === 0 ? undefined : 'bg-gray-50'
                            }
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <input
                                className="bg-transparent cursor-pointer"
                                name="name"
                                type="text"
                                value={person.username}
                                onChange={(e) =>
                                  onChangeUsername(e, person.userId)
                                }
                                placeholder="Nombre vacio"
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                className="bg-transparent cursor-pointer w-24"
                                name="costPerRBM"
                                type="text"
                                value={
                                  person.costPerRBM ? person.costPerRBM : 0.04
                                }
                                onChange={(e) => onChangeRBM(e, person.userId)}
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <input
                                className="bg-transparent cursor-pointer w-24"
                                name="costPerSMS"
                                type="text"
                                value={
                                  person.costPerSMS ? person.costPerSMS : 0.04
                                }
                                onChange={(e) => onChangeSMS(e, person.userId)}
                              />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Select
                                onChange={(paymentType) =>
                                  onChangePaymentType(
                                    paymentType,
                                    person.userId
                                  )
                                }
                                defaultValue={
                                  person.paymentType
                                    ? person.paymentType
                                    : 'PRE'
                                }
                              >
                                <Option key={'PRE'} value={'PRE'}>
                                  PRE
                                </Option>
                                <Option key={'POST'} value={'POST'}>
                                  POST
                                </Option>
                              </Select>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.fixedCurrency ? (
                                person.currency
                              ) : (
                                <Select
                                  onChange={(currency) =>
                                    onChangeCurrency(currency, person.userId)
                                  }
                                  defaultValue={
                                    person.currency ? person.currency : 'EUR'
                                  }
                                >
                                  <Option key={'EUR'} value={'EUR'}>
                                    EUR
                                  </Option>
                                  <Option key={'HUF'} value={'HUF'}>
                                    HUF
                                  </Option>
                                </Select>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person.balance +
                                ' ' +
                                currencies[
                                  person.currency ? person.currency : 'EUR'
                                ].symbol}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <button
                                onClick={(event) => handleTopUp(event, person)}
                                className={classNames(
                                  person.currency
                                    ? 'text-indigo-600 hover:text-indigo-900'
                                    : 'cursor-not-allowed',
                                  'text-indigo-200 hover:text-indigo-300'
                                )}
                                disabled={person.currency ? false : true}
                              >
                                Top up
                                <span className="sr-only">
                                  , {person.userId}
                                </span>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            className="mt-3"
            total={filteredList.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            defaultPageSize={clientsPerPage}
            defaultCurrent={1}
            current={currentPage}
            onChange={setCurrentPage}
            onShowSizeChange={(current, size) => setClientsPerPage(size)}
          />
          <div className="flex justify-end">
            <button
              onClick={() => setClients(originalClients)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancelar
            </button>
            <button
              onClick={saveChanges}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Guardar cambios
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Clients
