export default {
  EUR :{
    symbol: "€",
    code : "EUR",
    minimum : 0.50
  },
  HUF : {
    symbol : "Ft",
    code : "HUF",
    minimum : 175
  }
}
