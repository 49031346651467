import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import 'antd/dist/antd.css'
import {
  Row,
  Col,
  Table,
  Tag,
  Button,
  Input,
  Select,
  Alert,
  Empty,
  Typography,
  Popover,
  Switch,
  Statistic,
  Badge
} from 'antd'
import { ReloadOutlined, ExportOutlined } from '@ant-design/icons'
import Papa from 'papaparse'
import { dateToString, transformSMSDate } from '../../libs/date'
import { useAppContext } from '../../libs/context'
import { msgStatesWeight, statesVisualization } from '../../config/app'

const Results = () => {
  const InputGroup = Input.Group
  const { Option } = Select
  const { Text } = Typography
  const { userInfo } = useAppContext()

  const [campignOutput, setCampignOutput] = useState()
  const [campaigns, setCampaigns] = useState(null)
  const [errorMessage, setErrorMessage] = useState()
  const [adminMessage, setAdminMessage] = useState()
  const [date, setDate] = useState(null)
  const [selectedCampaignId, setSelectedCampaignId] = useState()
  const [campignFiltered, setCampignFiltered] = useState()
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({})
  const [interactions, setInteractions] = useState()

  useEffect(() => {
    listCampaigns()
    userInfo.isAdmin &&
      setAdminMessage('Los números móviles no corresponden con los originales')
  }, [])

  const columns = [
    {
      title: 'Móvil',
      dataIndex: 'phone'
    },
    {
      title: 'Canal',
      dataIndex: 'type',
      render: (e) => (
        <span>{<Tag color={e === 'RCS' ? 'purple' : 'blue'}>{e}</Tag>}</span>
      )
    },
    {
      title: 'Estado',
      render: (item) => {
        const { state, color } = statesVisualization(item.status)
        return (
          <Popover
            content={
              item.statusTimestamp
                ? dateToString(item.statusTimestamp).full
                : ''
            }
            title="Momento del estado"
          >
            <span>{<Tag color={color}>{state}</Tag>}</span>
          </Popover>
        )
      }
    },
    {
      title: 'Interacciones',
      render: (_e) => {
        const e = _e.msgs.filter(
          (i) => i.event === 'message' || i.event === 'response'
        )
        const content = (
          <div>
            {e.map((i) => {
              return (
                <Tag color={i.action.includes('baja') ? 'red' : 'blue'}>
                  {i.action
                    ? i.action.includes('goal')
                      ? `⭐️ ${i.action}`
                      : i.action
                    : i.status === 'dontKnow' ||
                      (i.event === 'message' && _e.type === 'SMS')
                    ? 'mensaje-directo'
                    : i.status}
                </Tag>
              )
            })}
          </div>
        )
        const quantity = (
          <Tag dot={true} color="geekblue">
            {e.length}
          </Tag>
        )
        const achieved = e.some(({ action }) => action.includes('goal'))
        return e.length ? (
          <Popover content={content} title="Interacciones">
            {achieved ? (
              <Badge
                dot={false}
                status="success"
                // style={{ marginRight: '8px', marginTop: '2px' }}
              >
                {quantity}
              </Badge>
            ) : (
              quantity
            )}
          </Popover>
        ) : (
          quantity
        )
      }
    }
  ]

  const setupInteractions = (data) =>
    JSON.parse(JSON.stringify(data)).map((item) => {
      item.interactions = item.msgs
        .filter((i) => i.event === 'message' || i.event === 'response')
        .map((i) =>
          i.action
            ? i.action
            : i.status === 'dontKnow' ||
              (i.event === 'message' && item.type === 'SMS')
            ? 'mensaje-directo'
            : i.status
        )
        .toString()
      delete item['msgs']
      return item
    })

  const setupState = (data) =>
    JSON.parse(JSON.stringify(data)).map((item) => {
      let status
      let statusTimestamp
      item.msgs
        .filter((item) => item.action && item.action.includes('init'))
        .forEach((item) => {
          if (
            !status ||
            msgStatesWeight[item.status] > msgStatesWeight[status]
          ) {
            status = item.status
            statusTimestamp = item.timestamp
          }
        })
      if (!status) {
        item.msgs.forEach((item) => {
          if (
            !status ||
            msgStatesWeight[item.status] > msgStatesWeight[status]
          ) {
            status = item.status
            statusTimestamp = item.timestamp
          }
        })
      }
      item.status = status
      item.statusTimestamp = statusTimestamp
      return item
    })

  async function listCampaigns() {
    const user = await Auth.currentAuthenticatedUser()
    let campaigns = await API.get('rcs-api', '/campaigns', {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
    if (campaigns && campaigns.length) {
      campaigns
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .reverse()
      setCampaigns(campaigns)
      getCampaign(campaigns[0].campaignId)
      setSelectedCampaignId(campaigns[0].campaignId)
    }
  }

  const getCampaignData = async (token, campaignId, pagination) => {
    const user = await Auth.currentAuthenticatedUser()
    try {
      const req = await API.get(
        'rcs-api',
        `/get-campaign/${campaignId || selectedCampaignId}/${pagination}`,
        {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken
          }
        }
      )
      return req
    } catch (error) {
      console.error(error)
    }
  }

  async function getCampaign(campaignId) {
    setLoading(true)
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const campaign = []
    let itemsCampaign = { nextPagination: 0 }
    do {
      itemsCampaign = await getCampaignData(
        token,
        campaignId,
        itemsCampaign.nextPagination
      )
      if (itemsCampaign?.results) {
        campaign.push(...itemsCampaign.results)
      }
    } while (itemsCampaign?.results && 'nextPagination' in itemsCampaign)
    document.campaign = campaign
    const statsData = {
      rcs: 0,
      sms: 0
    }
    campaign.forEach((el) => {
      if (el.type) {
        statsData[el.type.toLowerCase()]++
      }
    })
    setStats(statsData)
    const dataWithStatus = setupState(campaign)
    setCampignOutput(dataWithStatus)
    setInteractions(setupInteractions(dataWithStatus))
    setLoading(false)
  }

  const setReload = () => {
    const now = dateToString(new Date())
    setDate(now)
  }

  const downloadFile = () => {
    const campaign = campaigns.find((c) => c.campaignId === selectedCampaignId)
    const campaignName = `${campaign.flow}_${dateToString(campaign.timestamp)
      .full.replace(' ', '_')
      .replace(/\//g, '')
      .replace(':', '')}`
    const csv = Papa.unparse(interactions)
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const csvURL = window.URL.createObjectURL(csvData)
    const tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', `${campaignName}.csv`)
    tempLink.click()
  }

  const reload = () => {
    setCampignOutput()
    listCampaigns()
    getCampaign()
    setReload()
  }

  const onChangeInteractions = (checked) => {
    setCampignFiltered(
      checked
        ? campignOutput.filter((i) =>
            i.msgs.find(
              (msg) => msg.event === 'message' || msg.event === 'response'
            )
          )
        : campignOutput
    )
  }

  return (
    <>
      {/* <Title style={{ textAlign: 'center', marginTop: '20px' }}>Vendor</Title> */}
      <Row justify="center">
        <Col
          span={16}
          style={{
            backgroundColor: '#fff',
            marginTop: '50px',
            padding: '25px'
          }}
        >
          <>
            <div
              style={{
                marginBottom: '25px',
                display: 'flex'
              }}
            >
              <div>
                <InputGroup compact>
                  <Select
                    style={{ minWidth: '280px' }}
                    onChange={(campaignId) => {
                      getCampaign(campaignId)
                      setSelectedCampaignId(campaignId)
                    }}
                    value={selectedCampaignId}
                  >
                    {campaigns &&
                      campaigns.map((el, i) => {
                        return (
                          <Option key={i} value={el.campaignId}>{`${
                            userInfo && userInfo.isAdmin
                              ? `${el.userId} - `
                              : ''
                          }${el.flow} - ${
                            dateToString(el.timestamp).full
                          }`}</Option>
                        )
                      })}
                  </Select>
                </InputGroup>
              </div>
              <div
                style={{
                  marginLeft: 'auto'
                }}
              >
                <Text
                  style={{
                    marginRight: '10px'
                  }}
                >
                  Interacciones
                </Text>
                <Switch
                  onChange={onChangeInteractions}
                  style={{
                    marginRight: '20px'
                  }}
                />
                {/* <Text
                  style={{
                    fontSize: 10,
                    textAlign: 'center',
                    marginRight: '20px'
                  }}
                >
                  {date ? `Actualizado ${date}` : null}
                </Text> */}
                <Button
                  onClick={() => {
                    reload()
                  }}
                  type="primary"
                  shape="round"
                  icon={<ReloadOutlined />}
                  style={{
                    marginLeft: 'auto',
                    marginRight: '20px'
                  }}
                >
                  Actualizar
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  icon={<ExportOutlined />}
                  onClick={() => downloadFile()}
                  disabled={!campignOutput || !selectedCampaignId}
                >
                  Exportar
                </Button>
              </div>
            </div>
            {errorMessage && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: '15px' }}
              />
            )}
            {adminMessage && (
              <Alert
                message="Atención"
                description={adminMessage}
                type="warning"
                showIcon
                style={{ marginBottom: '15px' }}
                closable
              />
            )}
            <div
              style={{
                marginBottom: '25px',
                display: 'flex'
              }}
            >
              <Statistic
                title="Total"
                value={
                  'rcs' in stats && 'sms' in stats ? stats.rcs + stats.sms : 0
                }
                style={{
                  marginRight: '30px'
                }}
              />
              <Statistic
                title="RBM"
                value={'rcs' in stats ? stats.rcs : 0}
                style={{
                  marginRight: '30px'
                }}
              />
              <Statistic
                title="SMS"
                value={'sms' in stats ? stats.sms : 0}
                style={{
                  marginRight: '100px'
                }}
              />
              <Statistic
                title="Éxito de campaña"
                value={
                  interactions &&
                  interactions.length &&
                  interactions.find(
                    (item) =>
                      'type' in item &&
                      item.type === 'RCS' &&
                      item.interactions.includes('goal')
                  )
                    ? '1/2'
                    : 0
                }
              />
            </div>
            <Table
              loading={loading}
              dataSource={campignFiltered || campignOutput}
              columns={columns}
              size="small"
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>Sin información</span>}
                  ></Empty>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </>
  )
}

export default Results
