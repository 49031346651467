import React from 'react'

import { ElementClicksTag } from './ElementClickTag'

import {
  EyeSvg,
  ClickSvg,
  WebSvg,
  DoubleCheckSvg
} from '../../components/Icons'

const SUGGESTION_ELEMENT = 'suggestion'

const RichCard = ({ imageUrl, title, description, buttons }) => {
  return (
    <div className="w-64">
      <div
        id="richCard"
        className="flex flex-col justify-between border-4 rounded-2xl overflow-hidden min-h-[310px]"
      >
        <div>
          <img
            style={{ aspectRatio: '4/3' }}
            className="object-cover w-full"
            src={imageUrl}
            alt="RichCard image"
          />
          <div className="felx felx-col">
            {title && (
              <p className="font-medium text-base p-4 leading-5">{title}</p>
            )}
            {description && (
              <p className="font-light mt-1 text-sm p-4 leading-4">
                {description}
              </p>
            )}
          </div>
        </div>
        <div id="buttonsGroup">
          {buttons.map((button) => (
            <div id="linkButton" className="p-4 w-full text-center">
              <span className="text-blue-500">{button.text}</span>
            </div>
          ))}
        </div>
      </div>

      <div
        id="suggestionsGroup"
        className="flex flex-wrap justify-center gap-1 mt-2"
      >
        {buttons.map((button) => (
          <div
            id="action"
            className="flex flex-row px-2 gap-2 h-20 bg-[color:#F5F5F5] items-center rounded-2xl"
          >
            <div
              id="actionButton"
              className=" flex flex-row gap-2 border-4 border-blue-500 py-2 px-2 rounded-3xl items-center"
            >
              <WebSvg />
              <span className="text-blue-500 font-medium text-base">
                {button.text}
              </span>
            </div>
            <span className="text-2xl font-semibold text-grey-blue py-4">
              {button.clicks}
            </span>
            <ClickSvg />
          </div>
        ))}
      </div>
    </div>
  )
}
const renderCards = (cards) => {
  return cards.map((cardInfo) => {
    return (
      <RichCard
        imageUrl={cardInfo.imageUrl}
        title={cardInfo.title}
        susbtitle={cardInfo.subtitle}
        clicks={cardInfo.clicks}
        buttons={cardInfo.buttons}
      />
    )
  })
}
export const CarouselStats = ({ elementInfo }) => {
  return (
    <div>
      <section id="statsColumm" className="flex gap-6">
        <div id="deliveriesRow" className="flex flex-row gap-2 items-center ">
          <h2 className="text-2xl font-semibold text-grey-blue border-grey-blue py-4 whitespace-nowrap">
            Deliveries :{' '}
            <span className="bg-gradient-to-r from-grey-blue to-indigo-400 bg-clip-text text-transparent text-3xl">
              {elementInfo.delivered}{' '}
            </span>
          </h2>
          <DoubleCheckSvg />
        </div>
        <div id="deliveriesRow" className="flex flex-row gap-2 items-center ">
          <h2 className="text-2xl font-semibold text-grey-blue border-grey-blue py-4 whitespace-nowrap">
            Visualizations :{' '}
            <span className="bg-gradient-to-r from-grey-blue to-indigo-400 bg-clip-text text-transparent text-3xl">
              {elementInfo.displayed}{' '}
            </span>
          </h2>
          <EyeSvg />
        </div>
        <div id="totalClicks" className="flex flex-row gap-2 items-center ">
          <h2 className="text-2xl font-semibold text-grey-blue border-grey-blue py-4 whitespace-nowrap">
            Total Clicks :{' '}
            <span className="bg-gradient-to-r from-grey-blue to-indigo-400 bg-clip-text text-transparent text-3xl">
              {elementInfo.totalClicks}{' '}
            </span>
          </h2>
          <ClickSvg />
        </div>
      </section>
      {elementInfo.suggestions && (
        <section id="suggestionsList" className="mt-4 flex flex-wrap gap-4">
          {elementInfo.suggestions.map((suggestion) => (
            <ElementClicksTag
              elementInfo={suggestion}
              elementType={SUGGESTION_ELEMENT}
            />
          ))}
        </section>
      )}
      <section id="CardsList" className="mt-4 grid grid-cols-4 gap-4">
        {renderCards(elementInfo.cards)}
        <div className="col-sp"></div>
      </section>
    </div>
  )
}
