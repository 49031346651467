import React, { useState, useEffect, Fragment } from 'react'
import { ChartPieIcon, PencilSquareIcon, PhotoIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { dateToString } from '../../libs/date'

import LanguageDropdown from '../../components/languageDropdown'
import countries from '../../config/countries'
import { API, Auth } from 'aws-amplify'
import CountryPicker from '../../components/CountryPicker'
import { useTranslation } from "react-i18next";
import ResultModal from '../../components/ResultModal'
import i18n from '../../i18n'
import { Link } from 'react-router-dom'
import Spin from '../../components/Spin'




const Campaigns = () => {

  const [campaigns, setCampaigns] = useState([])
  const [scheduled, setScheduled] = useState([])

  const [loadingCampaigns, setLoadingCampaigns] = useState(false)
  const [collapsedCampaigns, setCollapsedCampaigns] = useState(false)
  const [collapsedScheduled, setCollapsedScheduled] = useState(false)



  async function listCampaigns() {
    setLoadingCampaigns(true)
    const user = await Auth.currentAuthenticatedUser()
    let campaigns
    try {
      campaigns = await API.get('rcs-api', '/campaigns', {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      })
    } catch (error) {
      console.error(error)
    }
    if (campaigns && campaigns.length) {
      campaigns
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .reverse()

      let now = new Date();

      // Search last launched campaign index
      let lastCampaignLaunchedIndex = campaigns.findIndex(campaign => new Date(campaign.timestamp) < now);

      // the rest of the campaigns are scheduled
      let campaignsLaunched = []
      if (lastCampaignLaunchedIndex >= 0) {
        campaignsLaunched = campaigns.splice(lastCampaignLaunchedIndex);
      }

      setCampaigns(campaignsLaunched)
      setScheduled(campaigns)
      setLoadingCampaigns(false)
    }
  }

  useEffect(() => {
    listCampaigns()
  }, [])

  return (
    <>
      {loadingCampaigns ? (
        <div className="flex justify-center my-16">
          <Spin type="lightBg" />
        </div>
      ) :
        (<div>

          <button type="button"
            className="w-full py-3 px-4 inline-flex justify-between items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-500 text-white mb-5"
            onClick={() => { setCollapsedScheduled(!collapsedScheduled) }}>
            {i18n.t('campaigns_scheduled')}
            <svg className={" w-2.5 h-2.5 text-white"}
              width="16"
              style={{ transform: collapsedScheduled ? "rotate(180deg)" : "rotate(0deg)" }}
              height="16" viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>

          <div id="hs-basic-collapse-heading" className={(collapsedScheduled ? "h-0" : "") + " transition-[height] duration-500 ease-in-out overflow-hidden"} >

            {scheduled.map((campaign) => (
              <div
                key={campaign.campaignId}
                value={campaign}
                className={'border-gray-300 mb-5 relative block bg-white border rounded-lg shadow-sm px-6 py-4 sm:flex sm:justify-between focus:outline-none'}
              >

                <>
                  <span className="flex items-center w-3/12">
                    <span className="text-sm flex flex-col">
                      <div
                        as="span"
                        className="font-medium text-grey-blue"
                      >
                        {campaign.flow}
                      </div>
                    </span>
                  </span>

                  <div
                    as="span"
                    className="mt-2 flex self-center text-sm sm:mt-0 sm:text-right"
                  >
                    <span className="font-medium text-grey-blue">
                      {i18n.t('status')} :
                    </span>
                    <span className={(campaign.status === "open" ? "text-green-500" : "text-red-500") + " ml-1 font-medium  capitalize"}>
                      {campaign.status === "close" ? "closed" : campaign.status}
                    </span>
                  </div>

                  <div
                    as="span"
                    className="mt-2 flex item-center text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                  >
                    <span className="font-medium text-grey-blue">
                      {campaign.timestamp ? dateToString(campaign.timestamp).full : "Nan"}
                    </span>
                  </div>

                  <div
                    as="span"
                    className="mt-2 flex text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                  >
                    <span className="font-medium text-red-500">
                      Cancel Campaign
                    </span>
                  </div>

                  <div
                    as="span"
                    className="mt-2 flex self-center text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                  >

                    <button
                      onClick={() => { console.log(campaign) }}
                      className="flex-shrink-0 text-blue-800 flex items-center justify-center w-10"
                    >
                      <ChartPieIcon className="h-6 w-6 align-center" />
                    </button>

                  </div>
                  <span
                    className={'border-2 border-transparent absolute -inset-px rounded-lg pointer-events-none'}
                    aria-hidden="true"
                  />
                </>

              </div>
            ))}
          </div>

          <button type="button"
            className="w-full py-3 px-4 inline-flex justify-between items-center gap-2 rounded-md border border-transparent font-semibold bg-gray-500 text-white mb-5"
            onClick={() => { setCollapsedCampaigns(!collapsedCampaigns) }}>
            {i18n.t('campaigns_launched')}
            <svg className={" w-2.5 h-2.5 text-white"}
              width="16"
              style={{ transform: collapsedCampaigns ? "rotate(180deg)" : "rotate(0deg)" }}
              height="16" viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>

          <div id="hs-basic-collapse-heading" className={(collapsedCampaigns ? "h-0" : "") + " transition-[height] duration-500 ease-in-out overflow-hidden"} >

            {campaigns.map((campaign) => (
              <div
                key={campaign.campaignId}
                value={campaign}
                className={'border-gray-300 mb-5 relative block bg-white border rounded-lg shadow-sm px-6 py-4 sm:flex sm:justify-between focus:outline-none'}
              >

                <>
                  <span className="flex items-center w-3/12">
                    <span className="text-sm flex flex-col">
                      <div
                        as="span"
                        className="font-medium text-grey-blue"
                      >
                        {campaign.flow}
                      </div>
                    </span>
                  </span>

                  <div
                    as="span"
                    className="mt-2 flex self-center text-sm sm:mt-0 sm:text-right"
                  >
                    <span className="font-medium text-grey-blue">
                      {i18n.t('status')} :
                    </span>
                    <span className={(campaign.status === "open" ? "text-green-500" : "text-red-500") + " ml-1 font-medium  capitalize"}>
                      {campaign.status === "close" ? "closed" : campaign.status}
                    </span>
                  </div>

                  <div
                    as="span"
                    className="mt-2 flex text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                  >
                    <span className="font-medium text-grey-blue">
                      {campaign.timestamp ? dateToString(campaign.timestamp).full : "Nan"}
                    </span>
                  </div>

                  <div
                    as="span"
                    className="mt-2 flex self-center text-sm sm:mt-0 sm:flex-col sm:ml-4 sm:text-right"
                  >

                    <button
                      onClick={() => { console.log(campaign) }}
                      className="flex-shrink-0 text-blue-800 flex items-center justify-center w-10"
                    >
                      <ChartPieIcon className="h-6 w-6 align-center" />
                    </button>

                  </div>
                  <span
                    className={'border-2 border-transparent absolute -inset-px rounded-lg pointer-events-none'}
                    aria-hidden="true"
                  />
                </>

              </div>
            ))}
          </div>

        </div>
        )
      }
    </>
  )
}

export default Campaigns
