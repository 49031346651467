import React, { useState, useEffect } from 'react'
import { StatisticsGeneral } from './StatisticsGeneral'
import { StatisticsRbm } from './StatisticsRbm'
import { CampaignSelector } from './components/CampaignSelector.jsx'
import Papa from 'papaparse'
import { API, Auth } from 'aws-amplify'
import { useAppContext } from '../../libs/context'
import Spin from '../../components/Spin'
import { ChevronLeftIcon } from '@heroicons/react/20/solid'

const DownloadSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 56 60"
      fill="none"
    >
      <path
        d="M29.3838 41.2652C29.0285 41.6537 28.5265 41.875 28 41.875C27.4735 41.875 26.9715 41.6537 26.6163 41.2652L16.6162 30.3277C15.9175 29.5635 15.9706 28.3775 16.7348 27.6787C17.4991 26.98 18.6851 27.033 19.3838 27.7973L26.125 35.1705V7.5C26.125 6.46448 26.9645 5.625 28 5.625C29.0355 5.625 29.875 6.46448 29.875 7.5V35.1705L36.6163 27.7973C37.315 27.033 38.501 26.98 39.2653 27.6787C40.0295 28.3775 40.0825 29.5635 39.3838 30.3277L29.3838 41.2652Z"
        fill="white"
      />
      <path
        d="M7.375 37.5C7.375 36.4645 6.53555 35.625 5.5 35.625C4.46448 35.625 3.625 36.4645 3.625 37.5V37.6373C3.62495 41.0563 3.6249 43.812 3.9163 45.9795C4.21885 48.2297 4.86608 50.1242 6.37088 51.629C7.87568 53.134 9.77035 53.7812 12.0206 54.0837C14.1881 54.375 16.9439 54.375 20.3628 54.375H35.6373C39.0563 54.375 41.812 54.375 43.9795 54.0837C46.2297 53.7812 48.1242 53.134 49.6292 51.629C51.134 50.1242 51.7812 48.2297 52.0837 45.9795C52.375 43.812 52.375 41.0563 52.375 37.6373V37.5C52.375 36.4645 51.5355 35.625 50.5 35.625C49.4645 35.625 48.625 36.4645 48.625 37.5C48.625 41.0885 48.621 43.5913 48.3673 45.4798C48.1205 47.3143 47.6695 48.2855 46.9775 48.9775C46.2855 49.6695 45.3143 50.1205 43.4798 50.3673C41.5913 50.621 39.0885 50.625 35.5 50.625H20.5C16.9115 50.625 14.4087 50.621 12.5203 50.3673C10.6859 50.1205 9.71443 49.6695 9.02253 48.9775C8.33063 48.2855 7.8795 47.3143 7.63288 45.4798C7.37898 43.5913 7.375 41.0885 7.375 37.5Z"
        fill="white"
      />
    </svg>
  )
}

const handleDownloadDeliveries = async (campaignId) => {
  const user = await Auth.currentAuthenticatedUser()
  let deliveriesInfo
  try {
    deliveriesInfo = await API.get(
      'rcs-api',
      '/deliveries-info/' + campaignId,
      {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      }
    )
  } catch (error) {
    console.error(error)
  }
  const csv = Papa.unparse(deliveriesInfo)
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const csvURL = window.URL.createObjectURL(csvData)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `deliveriesCsv.csv`)
  tempLink.click()
}

const handleDownloadAnswers = async (campaignId) => {
  const user = await Auth.currentAuthenticatedUser()
  let answers
  try {
    answers = await API.get('rcs-api', '/answers/' + campaignId, {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
  } catch (error) {
    console.error('error get', error)
  }
  const csv = Papa.unparse(answers)
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const csvURL = window.URL.createObjectURL(csvData)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `answersCsv.csv`)
  tempLink.click()
}
const getCampaignStats = async (campaignId) => {
  const user = await Auth.currentAuthenticatedUser()
  let data
  try {
    data = await API.get('rcs-api', `/campaign-stats/${campaignId}`, {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
  } catch (error) {
    console.error(error)
  }
  return data
}

const addClientInfo = async (campaigns) => {
  let users = []
  try {
    const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
      .idToken.jwtToken
    users = await API.get('rcs-api', '/users', {
      headers: {
        Authorization: tokenReq
      }
    })
  } catch (err) {
    console.log('error con los clientes')
  }

  campaigns.forEach((campaign) => {
    let client = users.find((user) => user.userId === campaign.userId)
    if (client) {
      campaign.clientInfo = client
    }
  })

  return campaigns
}

export const Statistics = () => {
  const [view, setView] = useState(() => <StatisticsGeneral />)
  const [campaigns, setCampaigns] = useState([])
  const [campaignInfo, setCampaignInfo] = useState()
  const [loadingCampaigns, setLoadingCampaigns] = useState(true)
  const { userInfo } = useAppContext()

  async function getCampaigns() {
    const user = await Auth.currentAuthenticatedUser()
    let campaignsList = []
    try {
      campaignsList = await API.get('rcs-api', '/campaigns', {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        }
      })
    } catch (error) {
      console.error(error)
    }

    if (userInfo.isAdmin) {
      campaignsList = await addClientInfo(campaignsList)
    }

    if (campaignsList && campaignsList.length) {
      campaignsList
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
        .reverse()
      setCampaigns(campaignsList)
    } else {
      alert('Campaign without data yet.')
    }
    setLoadingCampaigns(false)
  }

  useEffect(() => {
    getCampaigns()
  }, [])

  const handleCampaignChange = async (campaign) => {
    console.log('campaign', campaign)

    const campaignData = await getCampaignStats(campaign.campaignId)
    campaignData.name = campaign.flow
    campaignData.campaignId = campaign.campaignId
    setView(() => <StatisticsGeneral campaignInfo={campaignData} />)
    setCampaignInfo(campaignData)
  }

  return (
    <>
      {loadingCampaigns ? (
        <div className="flex flex-col items-center justify-center my-16">
          <h2 className="text-2xl font-semibold text-grey-blue mb-8">
            Loading campaigns ...
          </h2>
          <Spin type="lightBg" />
        </div>
      ) : (
        <>
          {!campaignInfo ? (
            <CampaignSelector
              setCampaign={handleCampaignChange}
              campaigns={campaigns}
            />
          ) : (
            <div className="min-w-[1000px]">
              <button
                className="flex gap-1 mb-2 items-center h-full py-2 border-transparent font-medium text-gray-400 hover:text-indigo-700 hover:underline"
                onClick={() => setCampaignInfo(null)}
              >
                <ChevronLeftIcon className="h-5 w-5" />
                Return to campaigns
              </button>
              <div id="buttonsBar" className="flex gap-2">
                <button
                  className="inline-block h-full px-5 py-2 rounded-md border border-transparent bg-blue-dark text-center font-medium text-white hover:bg-indigo-700"
                  onClick={() =>
                    setView(() => (
                      <StatisticsGeneral campaignInfo={campaignInfo} />
                    ))
                  }
                >
                  All
                </button>
                <button
                  className="inline-block h-full px-5 py-2 rounded-md border border-transparent bg-blue-dark text-center font-medium text-white hover:bg-indigo-700"
                  onClick={() =>
                    setView(() => (
                      <StatisticsRbm
                        rbmElements={Object.values(campaignInfo.rbmElements)}
                      />
                    ))
                  }
                >
                  RBM
                </button>
                <button
                  className="flex gap-2 items-center h-full px-4 py-2 rounded-md border border-transparent bg-blue-dark text-center font-medium text-white hover:bg-indigo-700"
                  onClick={() =>
                    handleDownloadDeliveries(campaignInfo.campaignId)
                  }
                >
                  <DownloadSvg /> Deliveries CSV
                </button>
                <button
                  className="flex gap-2 items-center h-full px-4 py-2 rounded-md border border-transparent bg-blue-dark text-center font-medium text-white hover:bg-indigo-700"
                  onClick={() => handleDownloadAnswers(campaignInfo.campaignId)}
                >
                  <DownloadSvg /> Answers CSV
                </button>
              </div>
              {campaignInfo ? view : null}
            </div>
          )}
        </>
      )}
    </>
  )
}
