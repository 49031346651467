import React, { useState, useEffect, Fragment } from 'react'
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LanguageDropdown from '../../components/languageDropdown'
import countries from '../../config/countries'
import { API, Auth } from 'aws-amplify'
import CountryPicker from '../../components/CountryPicker'
import { useTranslation } from 'react-i18next'
import ResultModal from '../../components/ResultModal'
import { Link } from 'react-router-dom'
import BotPreview from './components/BotPreview'

const Profile = () => {
  const [countryCode, setCountryCode] = useState('af')
  const [countryPrefix, setCountryPrefix] = useState('93')
  const [userInfo, setUserInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openResult, setOpenResult] = useState(false)
  const { t, i18n } = useTranslation()

  const modalMsg = {
    ok: {
      title: t('operation_performed'),
      content: t('profile_changed'),
      cta: 'Ok'
    },
    error: {
      title: 'Error',
      content: t('error_operation'),
      cta: 'Error'
    }
  }
  const [modalContent, setModalContent] = useState(modalMsg.ok)

  const getInfo = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const userFullInfo = await API.get('rcs-api', '/user', {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
    const prefix = userFullInfo.countryCode ? userFullInfo.countryCode : '93'
    const countrie = countries.find((c) => c.code == prefix)
    setCountryCode(countrie.iso)
    setCountryPrefix(countrie.code)
    setUserInfo(userFullInfo)
  }

  const updateUserCountryCode = async (countryCode) => {
    if (userInfo && userInfo.countryCode != countryCode) {
      let changedClient = {
        [userInfo.userId]: { countryCode: countryCode }
      }
      setLoading(true)
      setOpenResult(true)
      try {
        const tokenReq = (await Auth.currentAuthenticatedUser())
          .signInUserSession.idToken.jwtToken
        await API.post('rcs-api', '/users', {
          headers: {
            Authorization: tokenReq
          },
          body: changedClient
        })
        setUserInfo((oldUser) => {
          oldUser.countryCode = countryCode
          return oldUser
        })
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  return (
    <div>
      <ResultModal
        {...modalContent}
        isLoading={loading}
        open={openResult}
        setOpen={setOpenResult}
      />
      <div className="space-y-12">
        <div className="pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t('profile')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t('profile_privacy')}
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {i18n.t('language')}
              </label>
              <div className="mt-2">
                <LanguageDropdown />
              </div>
            </div>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t('country_prefix')}
              </label>
              <div className="mt-2 w-80">
                <CountryPicker
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  setCountryPrefix={setCountryPrefix}
                  countryPrefix={countryPrefix}
                  updateUserCountryCode={updateUserCountryCode}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Bot Configuration
              </label>
              {userInfo &&
                (!userInfo.botData ? (
                  <div className="mt-2">
                    <Link to="/bot-configuration">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Create Bot
                      </button>
                    </Link>
                  </div>
                ) : (
                  <BotPreview
                    name={userInfo.botData.name}
                    description={userInfo.botData.description}
                    profilePicture={userInfo.botData.profilePicture}
                    bannerPicture={userInfo.botData.bannerPicture}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
