import React from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

const Alarm = ({ type, message, closable, setAlarmContent }) => {
  const typeColor = {
    info: 'blue',
    success: 'green',
    alarm: 'yellow',
    error: 'red'
  }
  let color = typeColor?.[type]

  return (
    <>
      {color && (
        <div className={`rounded-md bg-${color}-50 p-4`}>
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className={`h-5 w-5 text-${color}-400`}
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className={`text-sm font-medium text-${color}-800`}>
                {message}
              </p>
            </div>
            {closable && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    className={`inline-flex bg-${color}-50 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
                    onClick={() => {
                      setAlarmContent({})
                    }}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Alarm
