import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import {
  ExclamationTriangleIcon,
  CheckCircleIcon,
  CheckIcon
} from '@heroicons/react/20/solid'
import { useAppContext } from '../../libs/context'
import { ERRORS } from '../../config/app'
import Spin from '../../components/Spin'
import i18n from '../../i18n'
import config from '../../config/aws';

const NewPasswordForm = ({ email }) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [resetDone, setResetDone] = useState(false)
  const history = useHistory()

  const ResetDone = () => {
    return (
      <>
        <div className="mt-10">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {i18n.t('operation_performed')}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{i18n.t('reset_password_done')}</p>
            </div>
          </div>
        </div>
  
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => {
              history.push("/")
            }}
          >
            {i18n.t('sign_in')}
          </button>
        </div>
  
      </>
    )
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const [password, passwordCheck, code] = e.target
    console.log(password.value)
    console.log(passwordCheck.value)
    if (password.value === passwordCheck.value) {
      setLoading(true)
      try {
        const params = {
          email: email,
          UserPoolId: config.cognito.USER_POOL_ID,
          ClientId: config.cognito.APP_CLIENT_ID,
          code: code.value,
          password: password.value
        };
        const res = await API.post('rcs-api', '/confirm-password', {
          body: params
        })
        setErrorMessage(null)
        setResetDone(true)
      } catch (err) {
        console.log(err)
        setErrorMessage(i18n.t('wrong_code'))
      }
      setLoading(false)
    } else {
      setErrorMessage(i18n.t('password_not_match'))
    }
  }



  return (
    <>
      {resetDone ? <ResetDone /> :
        <div className="mt-8">
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  {i18n.t('sent_you_code')}
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>{i18n.t('use_the_code')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <form onSubmit={onSubmit} className="space-y-6">
              <div className="space-y-1">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-grey-blue"
                >
                  {i18n.t('password')}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label
                  htmlFor="passwordCheck"
                  className="block text-sm font-medium text-grey-blue"
                >
                  {i18n.t('confirm_password')}
                </label>
                <div className="mt-1">
                  <input
                    id="passwordCheck"
                    name="passwordCheck"
                    type="password"
                    autoComplete="check-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-grey-blue"
                >
                  {i18n.t('code')}
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="code"
                    autoComplete="code"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              {errorMessage && (
                <div className="rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">
                        {i18n.t('atention')}
                      </h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>{errorMessage}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <button
                  type="submit"
                  className="ease-in duration-100 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled=""
                >
                  {loading && <Spin type="darkBg" size="small" />}
                  {loading ? i18n.t('processing') : i18n.t('confirm_code')}
                </button>
              </div>
            </form>
          </div>
        </div>
      }
    </>

  )
}

export default NewPasswordForm
