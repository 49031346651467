import { PaymentElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { API, Auth } from 'aws-amplify'
import { useAppContext } from "../../libs/context";
import i18n from '../../i18n'

export default function CheckoutForm({setTopUps}) {
  const stripe = useStripe();
  const elements = useElements();
  const [showResult, setShowResult] = useState(false)
  const {setBalance} = useAppContext()
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);

    await stripe.confirmPayment({
      elements,
      confirmParams: {
      },
      redirect: 'if_required'
    }).then(async function (result) {
      if (result.error) {
        if (result.error.type === "card_error" || result.error.type === "validation_error") {
          setMessage(result.error.message);
        } else {
          setMessage("An unexpected error occured.");
        }
      } else {
        console.log(result)
        try {
          const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
            .idToken.jwtToken
          const res = await API.post('rcs-api', '/save-payment', {
            headers: {
              Authorization: tokenReq
            },
            body: {
              paymentIntent: result.paymentIntent
            }
          })
          setBalance(res.newBalance)
          setTopUps(oldTops => {
            return [...oldTops, res.topUp]
          })
        } catch (e) {
          console.log(e)
        }
        setShowResult(true)
      }
    });

    setIsProcessing(false);
  };

  return (
    <>
      {
        showResult ?
          (

            <>
              <div className="mt-3 text-center sm:mt-5">
                <span
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {i18n.t('operation_performed')}
                </span>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{i18n.t('pay_done')}</p>
                </div>
              </div>
            </>
          )
          :
          (
            <form id="payment-form" onSubmit={handleSubmit}>
              <PaymentElement id="payment-element" />
              <button
                className='mt-5 bg-blue-dark hover:bg-indigo-700 bg-gray-300 ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                disabled={isProcessing || !stripe || !elements}
                id="submit">
                <span id="button-text">
                  {isProcessing ? "Processing ... " : "Pay now"}
                </span>
              </button>
              {/* Show any error or success messages */}
              {message && <div className='text-red-500 text-xs italic' id="payment-message">{message}</div>}
            </form>
          )
      }
    </>

  );
}