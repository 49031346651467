import React, { useEffect, useState } from 'react'
import { StatisticsLinesChart } from '../../../components/Statistics'
import { parse } from 'papaparse'

const DATES_RANGE = {
  FIRST_30_DAYS: 'first_30_days',
  LAST_WEEK: 'last_week',
  CUSTOM: 'custom'
}

const MESSAGE_TYPES = {
  ALL: 'total',
  RBM: 'RCS',
  SMS: 'SMS'
}

const MMESSAGE_TYPES_MAP = {
  [MESSAGE_TYPES.RBM]: 'RBM',
  [MESSAGE_TYPES.SMS]: 'SMS',
  [MESSAGE_TYPES.ALL]: 'Total'
}

const STATUS_MAP = {
  delivered: 'Delivered',
  sent: 'Sent',
  displayed: 'Displayed (RBM)',
  clicks: 'Clicks (RBM)'
}

const getDataForChart = (dateStats, messagesType, datesRange) => {
  const initDate = new Date(datesRange[0])
  const endDate = datesRange[1]
  const STATUS = ['delivered', 'sent']
  const TEMPLATE_DAY = {
    date: '',
    delivered: 0,
    sent: 0
  }
  if (messagesType !== MESSAGE_TYPES.SMS) {
    STATUS.push('displayed')
    TEMPLATE_DAY.displayed = 0
    STATUS.push('clicks')
    TEMPLATE_DAY.clicks = 0
  }
  const data = []
  for (let i = initDate; i <= endDate; i.setDate(i.getDate() + 1)) {
    const date = i.toLocaleDateString('en-Gb')
    let dayStats = dateStats[messagesType][date] || TEMPLATE_DAY
    for (let messageStatus of STATUS) {
      data.push({
        date,
        category: STATUS_MAP[messageStatus],
        value: dayStats[messageStatus] || 0
      })
    }
  }
  return data
}

const MessgaeTypeButton = ({ type, selectedType, onClick }) => {
  return (
    <button
      key={type}
      className={`border-2 focus:border-indigo-400 border-opacity-50 rounded-md px-5 py-2 ${
        selectedType === type ? 'bg-blue-dark text-white' : 'border-gray-400'
      }`}
      onClick={() => onClick(type)}
    >
      {MMESSAGE_TYPES_MAP[type]}
    </button>
  )
}

const calculateEndDate = (initDate, days) => {
  const endDate = new Date(initDate)
  endDate.setDate(initDate.getDate() + days)
  return endDate
}

const parseStringToDate = (date) => {
  const dateSplited = date.split('/')
  return new Date(dateSplited[2], dateSplited[1] - 1, dateSplited[0])
}

const parseDateToInputString = (date) => {
  let month = date.getMonth() + 1
  if (month < 10) {
    month = `0${month}`
  }

  let day = date.getDate()
  if (day < 10) {
    day = `0${day}`
  }

  return `${date.getFullYear()}-${month}-${day}`
}

const getFirstDateFromStats = (dateStats) => {
  const dates = Object.keys(dateStats)
  dates.sort((a, b) => parseStringToDate(a) - parseStringToDate(b))
  return parseStringToDate(dates[0])
}

export const CampaignLinesChart = ({ dateStats }) => {
  const [datesRangeType, setDatesRangeType] = useState(
    DATES_RANGE.FIRST_30_DAYS
  )
  const [messagesType, setMessagesType] = useState(MESSAGE_TYPES.ALL)
  const [datesRange, setDatesRange] = useState([new Date(), new Date()])
  const [customInitDate, setCustomInitDate] = useState(
    parseDateToInputString(calculateEndDate(new Date(), -7))
  )
  const [customEndDate, setCustomEndDate] = useState(
    parseDateToInputString(new Date())
  )
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    handleDateRangeTypeChange(DATES_RANGE.FIRST_30_DAYS)
  }, [])

  const handleDateRangeTypeChange = (type) => {
    let newDateRange = []
    switch (type) {
      case DATES_RANGE.FIRST_30_DAYS:
        const firstDate = getFirstDateFromStats(dateStats[MESSAGE_TYPES.ALL])
        newDateRange = [firstDate, calculateEndDate(firstDate, 30)]
        break
      case DATES_RANGE.LAST_WEEK:
        newDateRange = [calculateEndDate(new Date(), -7), new Date()]
        break
      case DATES_RANGE.CUSTOM:
        newDateRange = [new Date(customInitDate), new Date(customEndDate)]
      default:
        break
    }
    setDatesRange(newDateRange)
    setChartData(getDataForChart(dateStats, messagesType, newDateRange))
  }

  const handleApplyCustomRange = () => {
    const initDate = new Date(customInitDate)
    const endDate = new Date(customEndDate)
    if (initDate > endDate) {
      alert('End date must be greater than start date')
      return
    }
    if (endDate > new Date()) {
      alert('End date must be before or equal to toaday')
      return
    }
    if (initDate < new Date('2019-01-01')) {
      alert('Start date must be greater than 01/01/2019')
      return
    }
    setDatesRange([initDate, endDate])
    setChartData(getDataForChart(dateStats, messagesType, [initDate, endDate]))
  }

  const handleChangeCustomRange = (date, isInit) => {
    if (isInit) {
      setCustomInitDate(date)
    } else {
      setCustomEndDate(date)
    }
  }

  const handleMessageTypeChange = (type) => {
    setMessagesType(type)
    setChartData(getDataForChart(dateStats, type, datesRange))
  }

  return (
    <section id="messageStats">
      <h1 className="text-3xl font-semibold text-grey-blue w-full border-b-4 border-grey-blue">
        Messages statistics
      </h1>
      <div id="filters" className="flex flex-col gap-2 py-2">
        <div id="datesFilter" className="flex flex-col gap-2">
          <label className="block text-base font-medium text-grey-blue">
            Dates range
          </label>
          <div className="flex items-center gap-4">
            <select
              onChange={(e) => {
                handleDateRangeTypeChange(e.target.value)
                setDatesRangeType(e.target.value)
              }}
              className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1"
            >
              <option value={DATES_RANGE.FIRST_30_DAYS}>First 30 days</option>
              <option value={DATES_RANGE.LAST_WEEK}>Last week</option>
              <option value={DATES_RANGE.CUSTOM}>Custom range</option>
            </select>
            {datesRangeType === DATES_RANGE.CUSTOM && (
              <>
                <label className="block text-sm font-medium text-grey-blue">
                  Start date
                </label>
                <input
                  value={customInitDate}
                  onChange={(e) => {
                    handleChangeCustomRange(e.target.value, true)
                  }}
                  type="date"
                  className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1"
                />
                <label className="block text-sm font-medium text-grey-blue">
                  End date
                </label>
                <input
                  value={customEndDate}
                  onChange={(e) =>
                    handleChangeCustomRange(e.target.value, false)
                  }
                  type="date"
                  className="border-2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-1"
                />
                <button
                  className="border-2 focus:border-indigo-400 border-opacity-50 rounded-md px-5 py-2 bg-blue-dark text-white"
                  onClick={handleApplyCustomRange}
                >
                  Apply
                </button>
              </>
            )}
          </div>
        </div>
        <div id="messagesTypeFilter" className="flex flex-col gap-2 py-2">
          <label className="block text-base font-medium text-grey-blue">
            Message type
          </label>
          <div className="flex gap-4">
            {Object.values(MESSAGE_TYPES).map((type) => (
              <MessgaeTypeButton
                type={type}
                selectedType={messagesType}
                onClick={handleMessageTypeChange}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="charts groups" className="flex gap-4">
        <div className="w-full h-[250px]">
          <StatisticsLinesChart data={chartData} />{' '}
        </div>
      </div>
    </section>
  )
}
