import React from 'react'

const color = {
  lightBg: { bg: 'black', rest: '#221bfe' },
  darkBg: { bg: 'white', rest: 'currentColor' }
}
const sizes = {
  default: 8,
  small: 5
}

const Spin = ({ size, type }) => (
  <svg
    className={`animate-spin -ml-1 mr-3 h-${sizes?.[size] || sizes.default} w-${
      sizes?.[size] || sizes.default
    } text-${color[type].bg}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke={color[type].rest}
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill={color[type].rest}
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

export default Spin
