import React from 'react'
const TypeButton = ({ children, type, selected, onClick, icon = null }) => {
  const Icon = icon
  return (
    <button
      type="button"
      className={`${
        selected
          ? 'border-2 border-transparent bg-grey-blue/70 text-white'
          : 'border-2 border-indigo-300 bg-blue-50 text-indigo-300 hover:bg-white transition-all duration-300 ease-in-out'
      } flex gap-2 px-4 py-2 rounded-md font-medium`}
      onClick={() => onClick(type)}
    >
      <Icon
        className="h-6 w-6"
        stroke={`${selected ? 'white' : '#a5b4fc'}`}
        fill={`${selected ? 'white' : '#a5b4fc'}`}
      />
      {children}
    </button>
  )
}

export default TypeButton
