import React, { useState } from 'react'
import NewElement from '../NewElement'
import CreateModal from '../CreateModal'
import RichCardMsgForm from './RichCardMsgForm'
import RichCardPreview from '../RichCardPreview'
import { RBM_ELEMENT_ERRORS_MESSAGES, RBM_ELEMENT_ERRORS } from '../constants'

const CarouselMsgForm = ({
  elementInfo = {},
  setInfo,
  isConversational,
  errors,
  replyOptions
}) => {
  const [cards, setCards] = useState(elementInfo.cards || [])
  const [cardIndex, setCardIndex] = useState(null)
  const [newCardInfo, setNewCardInfo] = useState({})
  const [isNewCardOpen, setNewCardOpen] = useState(false)

  const handleCardSubmit = () => {
    if (cardIndex === null) {
      setCards([...cards, newCardInfo])
      setInfo({ ...elementInfo, cards: [...cards, newCardInfo] })
    } else {
      const newCards = [...cards]
      newCards[cardIndex] = newCardInfo
      setCards(newCards)
      setInfo({ ...elementInfo, cards: newCards })
      setCardIndex(null)
    }
    setNewCardOpen(false)
    setNewCardInfo({})
  }

  const deleteCard = (index) => {
    const newCards = [...cards]
    newCards.splice(index, 1)
    setCards(newCards)
    setInfo({ ...elementInfo, cards: newCards })
  }

  const getButtonText = () => (cardIndex === null ? 'Add Card' : 'Update Card')

  const handleCancel = () => {
    setNewCardOpen(false)
    setCardIndex(null)
    setNewCardInfo({})
  }

  const onSetOpen = (isOpen) => {
    setNewCardOpen(isOpen)
    if (!isOpen) {
      setNewCardInfo({})
      setCardIndex(null)
    }
  }

  return (
    <div>
      <CreateModal open={isNewCardOpen} setOpen={onSetOpen} isSmall={false}>
        <div>
          <RichCardMsgForm
            errors={errors}
            elementInfo={newCardInfo}
            setInfo={setNewCardInfo}
            setOpen={onSetOpen}
            isConversational={isConversational}
            replyOptions={replyOptions}
          />
          <div className="mt-4 flex w-full justify-between">
            <button
              className="bg-none font-medium border-none text-gray-500"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
              onClick={handleCardSubmit}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      </CreateModal>
      <label
        htmlFor="image"
        className="block text-sm mt-3 font-medium text-grey-blue"
      >
        Carousel Cards
      </label>
      <div className="flex gap-4 py-2">
        {cards.map((card, index) => (
          <RichCardPreview
            onRemove={() => deleteCard(index)}
            card={card}
            key={index}
            onClick={() => {
              setCardIndex(index)
              setNewCardInfo(card)
              setNewCardOpen(true)
            }}
          />
        ))}
        {cards.length < 3 && (
          <NewElement isNewCard={true} action={() => setNewCardOpen(true)} />
        )}
      </div>
      {errors.includes(RBM_ELEMENT_ERRORS.MISSING_CARDS) && (
        <p className="text-red-500">
          {RBM_ELEMENT_ERRORS_MESSAGES[RBM_ELEMENT_ERRORS.MISSING_CARDS]}
        </p>
      )}
    </div>
  )
}

export default CarouselMsgForm
