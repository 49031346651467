export const eng ={

  //components/Layout.js
  campaigns : "Campaigns",
  statistics : "Statistics",
  create_campaign : "Create Campaign",
  balance : "Balance : ",
  profile: "Profile",

  //scenes/Access/ComfirmCodeForm.js
  sent_you_code : "We have sent you a code",
  use_the_code : "Use the code that we sent to your email",
  code : "code",
  atention : "Atention",
  processing : "Processing...",
  confirm_code : "Confirm the code",
  
  //scenesAccess/Login.js
  log_in : "Log in with your account",
  or : "Or",
  sign_up : "sign up with a new account",
  password : "Password",
  remember_me : "Remember me",
  sign_in : "Sign in",
  forgot_password : "Forgot password?",

  //scenes/Access/Register.js
  sign_up_register : "Sign up with a new account",
  log_in_register : "log in with your account",
  confirm_password : "Confirm password",
  sign_up_button : "Sign up",
  country_prefix : "Country prefix",
  gdpr_accept:
  "I've read and accept the <link1>privacy policy</link1> and <link2>legal notice</link2>",


  //scenes/Access/ForgotPassword.js
  reset_password : "Reset password",
  send_email : "Send email",
  wrong_email : "No account found with this email",

  //scenes/Access/ForgotPassword.js
  reset_password_done : "Your password has been updated",
  password_not_match : "Passwords do not match",
  wrong_code : "There were a problem, please check if the code is correct or try again",

  //scenes/Campaign/Campaign.js
  select_campaign :"Select campaig",
  select_template :"Select template",
  upload_contacts : "Upload contacts",
  send : "Send",
  total_subscribers : "Total Subscribers",

  //scenes/Campaign/Select.js
  campaign : "Campaign",
  next : "Next",

  //scenes/Campaign/Upload.js
  file_not_valid : "The file is not valid",
  upload_file : "Upload a file",
  formats_acepted : "The formats accepted are JSON & CSV",
  add_contacts : "Add contacts",
  csv_format: "CSV format :",
  json_format: "JSON format :",
  close: "Close",
  be_warmed: "Be warmed",
  missing_variables: "Some of your contacts are missing at least one of the following variables you are using in your campaign texts:",
  check_the_file: "This could cause problems during the launch of your campaign. Please check the file you are using.",

  //components/InvalidNumbersModal.js
  warning : "Warning",
  invalid_numbers : "There are {{invalidContacts}} numbers that does not meet validation criteria (contacts must have \"phone\" attribute) and {{duplicatedContacts}} numbers duplicated, these numbers will not be added to the campaign.",

  //scenes/Campaign/Send.js
  campaign_launched : "Your campaign has been launched",
  check_stats : "You can check the statistics section to review the data generated.",
  error_launching : "Error launching the campaign",
  could_not_launch : "The campaign could not be launched, please check the error by contacting us at support@tadoo.biz.",
  insufficient_balance : "Insufficient balance",
  numbers_capacity : "You have balance to send to {{capacity}} numbers, please remove the corresponding numbers from your file.",
  insufficient_min_balance : "Balance not calculated",
  wait_charge : "Your new balance has not yet been calculated after your last launched campaign, please try again later.",
  contacts : "Contacts",
  price_sms : "Price per SMS",
  price_rbm : "Price per RBM",
  launch : "Launch",
  scheduled : "Schedule Campaign",

  //scenes/Campaign/Create.js
  name : "Name",
  error_space_name : "The name of the campaign must be without spaces or hyphens",
  error_empty_name : "The name of the campaign is mandatory",
  error_empty_sms : "The sms message can not be empty",
  error_invalid_variable : "Variables must have a name like: {name}",
  campaign_name : "Campaign name",
  campaign_type : "Message Type",
  rbm_alert : "If you want to create an RBM campaign please contact support@tadoo.biz so we can help you.",
  operation_performed : "Operation performed",
  created_campaign : "The campaign has been created and added to your list of campaigns ready to launch",
  error_creating_campaign : "The campaign could not be created, please review the data entered and if the error persists contact support@tadoo.biz for more information",
  error_invalid_sender : "Invalid sender",
  error_empty_sender : "Empty sender",
  sender_validations : "It must be either numeric with a length range between 10 and 16 digits (only for non-greek destinations) or alphanumeric up to 11 latin chars (including the special chars !:;+.-)",
  sender : "Sender",
  update_template: "Update template",
  
  //scenes/Campaign/CreateModal/NewSmsForm.js
  to_add_variables : "To add variables, enclose the variable name in braces as in the following example: \n\"{variable}\"",
  text : "Text",
  characters_used : "Characters used: ",
  codification_type : "Codification type: ",
  available_chars : "Available characters: ",
  number_of_sms : "Number of SMS's: ",
  sms_could_increase : "The number of SMS could increase depending on the number of variables and their length.",
  message_text : "Message text",

  //scenes/TopUps/TopUps.js
  concept : "Concept",
  amount : "Amount",
  date : "Date",
  add_money : "Add money",

  //scenes/TopUps/TopUps.js
  error_amount_format: "This format is not valid",
  error_minimum_amount: "The minimum amount is {{minimum}}",
  error_amount_decimals: "This currency (\"{{currency}}\") doesnot accept decimals",
  pay_done: "Payment has been made successfully and has been added to your total balance.",
  error_operation: "The operation could not be performed, please review the data entered and if the error persists contact support@tadoo.biz for more information",

  //scenes/Statistics/General.js
  sent : "Sent",
  delivered : "Delivered",
  not_delivered : "Not delivered",
  rbm_delivered : "RBM delivered",
  rbm_not_delivered : "RBM not delivered",
  sms_delivered : "SMS delivered",
  sms_not_delivered : "SMS not delivered",
  unsubscriptioins : "Unsubscriptions",
  not_unsubscriptioins : "Not unsubscriptions",
  rbm_unsubscriptioins : "RBM with unsubscriptions",
  rbm_not_unsubscriptioins : "RBM without unsubscriptions",
  sms_unsubscriptioins : "SMS with unsubscriptions",
  sms_not_unsubscriptioins : "SMS without unsubscriptions",
  general_statistics : "General statistics",
  stats_by_states : "Statistics by states",
  all_stats_rbm_sms : "Sent, delivered and unsubscribes of RBM and SMS",
  deliveries_percentage : "Percentage of deliveries",
  deliveries_percentage_sms_rbm : "Percentage of deliveries of RBM and SMS ",
  deliveries_relation_sent : "Percentage of deliveries in relation to the number of sent.",
  deliverie_report : "Delivery report",
  not_deliverie_report : "Not delivery report",
  deliveries_rbm_relation_sent : "Percentage of RBM deliveries in relation to the number of sent.",
  rbm_delivered_report : "RBM delivery report",
  rbm_not_delivered_report : "RBM not delivery report",
  deliveries_sms_relation_sent : "Percentage of SMS deliveries in relation to the number of sent.",
  sms_delivery_report : "SMS delivery report",
  sms_not_delivery_report : "SMS not delivery report",
  percentage_unsubs : "Percentage of unsubscriptions",
  percentage_unsubs_sms_rbm : "Percentage of unsubscriptions of RBM and SMS",
  percentage_unsub_relation_delivery :"Percentage of unsubscriptions in relation to the number of deliveries.",
  unsub_report : "Unsubscriptions report",
  not_unsub_report :"Not unsubscriptions report",
  rbm_unsubs_relation_delivereies : "Percentage of RBM unsubscriptions in relation to the number of deliveries.",
  rbm_unsub_report : "RBM unsubscriptions report",
  rbm_not_unsub_report : "RBM not unsubscriptions report",
  sms_unsubs_relation_delivereies: "Percentage of SMS unsubscriptions in relation to the number of deliveries.",
  sms_unsub_report : "SMS unsubscriptions report",
  sms_not_unsub_report : "SMS not unsubscriptions report",
  print_statistics : "Print statistics",
  print_without_menus: "Print the statistics without the menus, outer margins and report buttons.",

  //scenes/Statistics/Interactions.js
  step_visualizations: "Step {{step}} visualizations",
  step_deliveries: "Step {{step}} deliveries",
  first_stp_deliveries: "1st step deliveries",
  first_stp_visualizations : "1st step visualizations",
  second_stp_deliveries: "2nd step deliveries",
  second_stp_visualizations: "2nd step visualizations",
  objectives_achived: "Objectives achieved",
  deliveries : "Deliveries",
  visualizations : "Visualizations",
  unique : "Unique",
  elems_touched : "Elements touched",
  unique_touch_suggs : "Unique touches on suggestions",
  total_touch_suggs : "Total touches on suggestions",
  unique_touch_objectives :  "Unique touches on objectives",
  total_touch_objectives : "Total touches on objectives",
  rbm_visualization : "RBM with visualizations",
  rbm_not_visualization : "RBM without visualizations",
  rbm_touched : "RBM elements touched",
  rbm_not_touched : "RBM elements not touched",
  rbm_objectives : "RBM with objectives",
  rbm_not_objectives : "RBM without objectives",
  rbm_stats : "RBM Statistics",
  rbm_funnel_stats : "RBM status funnel",
  funnel_content : "Funnel of deliveries, visualizations and unique goals achieved",
  percentage_all_interactions : "Percentages of elements viewed, clicked and goals achieved",
  percentage_unique_all : "The percentages of items viewed, unique touches, and unique goals achieved",
  percentage_displayed: "Percentage of displayed",
  displayed_respect_delivery : "Elements displayed with respect to delivered.",
  unique_visualizations : "Unique visualizations report",
  no_visualizations_report : "Not visualizations report",
  percentage_pulsations : "Percentage of pulsations",
  unique_touch_compared_displayed : "Percentage of unique touched items compared to displayed items.",
  unique_pulsations : "Unique pulsations report",
  no_pulsations : "No pulsations report",
  percentaje_objectives : "Percentage of objectives",
  unique_objectives_relation_displayed : "Percentage of unique objectives achieved in relation to the number of displayed items.",
  objectives_achived_report : " Unique objectives achieved report",
  objectives_not_achived_report : "Objectives not achieved report",
  total_unique_interactions : "Total and unique interactions",
  elems_touch__total_unique_goals : "Statistics of elements touched and unique and total goals achieved",
  unique_interactions : "Unique interactions report",
  total_interactions : "Total interactions report",
  interactions_stats : "Interaction elements statistics",
  all_stats_per_elem : "Unique and total deliveries, views, and pulsations for each interaction element",
  unique_interactions_per_elem : "Unique interactions for each interaction element",
  views_per_elem : "Visualizations statistics for each element.",
  unique_pulsations_per_elem : "Unique pulsations statistics  for each interaction element.",
  unique_interaction_elem : "Unique interaction elements report",
  total_interactions_per_elem : "Total interactions for each interaction element",
  total_pulsations_per_elem : "Total pulsations statistics for each interaction element",
  total_interactions_elem_report : "Total interaction elements report",

  //scenes/Statistics/Statistics.js
  no_informatioin : "No information",
  language : "Language ",
  refresh: "Refresh",

  //scenes/Statistics/Profile.js
  save_changes: "Save changes",
  cancel: "Cancel",
  profile_privacy: "This information will be displayed publicly so be careful what you share.",
  profile_changed: "Your profile information has been successfully updated",

  //scenes/Templates/Templates.js
  templates: "Templates",
  create_template: "Create template",
  delete_template: "Delete template",
  are_you_sure_delete: "Are you sure you want to delete this template called \"{{name}}\" ?",

    //scenes/Integration/Integration.js
    integration_hubspot_ok: "HubSpot integration completed!"
}