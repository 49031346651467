import React, { useState, useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useAppContext } from '../libs/context'

const Steps = ({ onStepClick }) => {
  const { steps, setSteps, stepsData, setStepsData } = useAppContext()

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0"
      >
        {stepsData.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.completed ? (
              <a
                onClick={() => {
                  onStepClick(step)
                }}
                className="group flex items-center w-full"
              >
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-dark rounded-full group-hover:bg-indigo-800">
                    <CheckIcon
                      className="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-grey-blue">
                    {step.name}
                  </span>
                </span>
              </a>
            ) : step.current ? (
              <a
                onClick={() => {
                  onStepClick(step)
                }}
                className="px-6 py-4 flex items-center text-sm font-medium"
                aria-current="step"
              >
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                  <span className="text-blue-dark">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-blue-dark">
                  {step.name}
                </span>
              </a>
            ) : (
              <a
                onClick={() => {
                  onStepClick(step)
                }}
                className="group flex items-center"
              >
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-grey-blue">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-grey-blue">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== stepsData.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="hidden md:block absolute top-0 right-0 h-full w-5"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Steps
