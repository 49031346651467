import React, { useState, useEffect } from 'react'
import Papa from 'papaparse'
import { PlusIcon } from '@heroicons/react/20/solid'
import Alarm from '../../components/Alarm'
import { parseSecurely } from '../../libs/parse'
import { API, Auth } from 'aws-amplify'
import { useAppContext } from '../../libs/context'
import { CAMPAIGNS } from '../../config/app'
import InvalidNumbersModal from '../../components/InvalidNumbersModal'
import i18n from '../../i18n'

const formats = {
  basic: {
    csv: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>phone</p>
          <p>123456789</p>
          <p>987654321</p>
          <p>...</p>
        </div>
      )
    },
    json: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>{'[{'}</p>
          <p style={{ textIndent: '20px' }}> phone: 123456789</p>
          <p>{'},...]'}</p>
        </div>
      )
    }
  },
  withName: {
    csv: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>name,phone</p>
          <p>John,123456789</p>
          <p>Alex,987654321</p>
          <p>...</p>
        </div>
      )
    },
    json: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>{'[{'}</p>
          <p style={{ textIndent: '20px' }}> name: John,</p>
          <p style={{ textIndent: '20px' }}> phone: 123456789</p>
          <p>{'},...]'}</p>
        </div>
      )
    }
  },
  unknown: {
    csv: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>phone, ...</p>
          <p>123456789, ...</p>
          <p>987654321, ...</p>
          <p>...</p>
        </div>
      )
    },
    json: () => {
      return (
        <div className="mt-5 p-5 rounded-md border-2 px-5 bg-gray-200">
          <p>{'[{'}</p>
          <p style={{ textIndent: '20px' }}> phone: 123456789,</p>
          <p style={{ textIndent: '20px' }}> xxx : xxx,</p>
          <p style={{ textIndent: '20px' }}> ...</p>
          <p>{'},...]'}</p>
        </div>
      )
    }
  }
}

const getTexts = (object) => {
  const keysToSearch = [
    'textMessage',
    'title',
    'description',
    'text',
    'expectedAnswer'
  ]
  let results = []

  // Recursive function to search in the object
  function recursiveSearch(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Check if the key is in the list of keys to search
        if (keysToSearch.includes(key)) {
          results.push(obj[key])
        }

        // If the value of the key is an object, perform a recursive search
        if (typeof obj[key] === 'object') {
          recursiveSearch(obj[key])
        }
      }
    }
  }

  // Start the recursive search
  recursiveSearch(object)

  return results
}

const getVariablesFormText = (text) => {
  const regex = /\{(.*?)\}/g
  const matches = []
  let match

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1])
  }

  return matches
}

const Upload = () => {
  const [alarmContent, setAlarmContent] = useState({})
  const [invalidContacts, setInvalidContacts] = useState([])
  const [duplicatedContacts, setDuplicatedContacts] = useState([])
  const [invalidModal, setInvalidModal] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [campaignMissingVariables, setCampaignMissingVariables] = useState([])

  const {
    onStepClick,
    setContacts,
    stepsData,
    setStepsData,
    stat,
    setStat,
    selectedCampaign
  } = useAppContext()
  const schema =
    selectedCampaign && selectedCampaign.schema
      ? selectedCampaign.schema
      : 'basic'
  const hiddenFileInput = React.useRef(null)

  const getAllVariables = () => {
    if(selectedCampaign.elements){
    const campaignTexts = getTexts(JSON.parse(selectedCampaign.elements))
    const variables = [].concat(
      ...campaignTexts.map((text) => getVariablesFormText(text))
    )
    return variables
    }else{
      return []
    }
  }
  const CAMPAIGN_VARIABLES = getAllVariables()

  const getContactMissingVariables = (contact) => {
    const contactVariables = Object.keys(contact)
    const missingVariables = CAMPAIGN_VARIABLES.filter(
      (variable) => !contactVariables.includes(variable)
    )
    return missingVariables
  }

  const updateMissingVariables = (contacts) => {
    let _union = new Set()
    for (let contact of contacts) {
      for (let elem of getContactMissingVariables(contact)) {
        _union.add(elem)
      }
    }
    const missingVariables = Array.from(_union)
    console.log(missingVariables)
    setCampaignMissingVariables(missingVariables)
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const getInfo = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const userFullInfo = await API.get('rcs-api', '/user', {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    })
    const prefix = userFullInfo.countryCode ? userFullInfo.countryCode : '93'
    setUserInfo(userFullInfo)
  }

  const cleanContacts = (contacts) => {
    let prefix = '00'

    if (userInfo.countryCode) {
      prefix = userInfo.countryCode
    }

    switch (prefix) {
      case '36':
        return hungarianFormat(contacts)

      default:
        console.log(selectedCampaign)
        let phonesUsed = []
        let invalids = []
        let duplicated = []
        const cleanPhones = contacts.map((contact) => {
          if (!contact.phone || contact.phone.length == 0) {
            invalids.push(contact)
            return null
          }
          contact.phone = contact.phone
            .substring(contact.phone.startsWith('00') ? 2 : 0)
            .replace(/\D/g, '')
          if (phonesUsed.includes(contact.phone)) {
            duplicated.push(contact)
            return null
          }
          if (contact.phone.length == 9) {
            contact.phone = '34' + contact.phone
          }

          phonesUsed.push(contact.phone)
          return contact
        })
        setInvalidContacts(invalids)
        setDuplicatedContacts(duplicated)
        if (invalids.length != 0 || duplicated.length != 0) {
          console.log('invalids')
          setInvalidModal(true)
        }
        return cleanPhones.filter((contact) => contact !== null)
    }
  }

  const hungarianFormat = (contacts) => {
    let invalid = []
    let duplicated = []
    let phonesUsed = []
    const cleanPhones = contacts.map((contact) => {
      const onlyDigits = contact.phone
        .substring(contact.phone.startsWith('00') ? 2 : 0)
        .replace(/\D/g, '')
      let cleanPhone = onlyDigits

      if (onlyDigits.length >= 10 && onlyDigits.length <= 11) {
        if (onlyDigits.startsWith('06')) {
          cleanPhone = '36' + onlyDigits.substr(2)
        } else if (onlyDigits.startsWith('36')) {
          cleanPhone = onlyDigits
        } else {
          invalid.push(contact)
          return null
        }
      } else if (onlyDigits.length >= 8 && onlyDigits.length <= 9) {
        cleanPhone = '36' + onlyDigits
      } else {
        invalid.push(contact)
        return null
      }
      if (!/^36(?:20|30|31|50|70)\d*$/.test(cleanPhone)) {
        invalid.push(contact)
        return null
      }
      //check if duplicated
      if (phonesUsed.includes(cleanPhone)) {
        duplicated.push(contact.phone)
        return null
      }
      phonesUsed.push(cleanPhone)
      contact.phone = cleanPhone
      return contact
    })
    console.log('invalid', invalid)
    setInvalidContacts(invalid)
    setDuplicatedContacts(duplicated)
    if (invalid.length != 0 || duplicated.length != 0) {
      setInvalidModal(true)
    }
    return cleanPhones.filter((contact) => contact !== null)
  }
  const readFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    let type = e.target.files[0].type
    reader.onload = async (e) => {
      const text = e.target.result
      let campaignContacts
      if (type === 'application/json') {
        campaignContacts = parseSecurely(text)
      } else {
        campaignContacts = Papa.parse(text, { header: true }).data
      }
      const varsMaps = CAMPAIGNS[schema].maps
      campaignContacts = campaignContacts.map((el, i) => {
        const o = { key: i }
        if (schema == 'unknown') {
          let swapMap = {}
          for (let key in varsMaps) {
            swapMap[varsMaps[key]] = key
          }
          Object.keys(el).forEach((k) => {
            o[swapMap[k] ? swapMap[k] : k] = el[k]
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          })
        } else {
          Object.keys(varsMaps).forEach((k) => {
            o[k] = el[varsMaps[k]]
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          })
        }
        if ('dueDate' in o) {
          o['dueDate'] = o['dueDate'].split('-').join('/')
        }
        return o
      })
      const validContacts = cleanContacts(campaignContacts)
      // const validContacts = campaignContacts
      updateMissingVariables(validContacts)
      console.log(validContacts)
      setContacts(validContacts)
      const _stat = { ...stat }
      _stat.users = validContacts.length
      setStat(_stat)
      const _stepsData = [...stepsData]
      _stepsData.find(({ id }) => id === 2).completed = true
      setStepsData(_stepsData)
      if (!validContacts) {
        setAlarmContent({
          type: 'error',
          message: i18n.t('file_not_valid'),
          closable: true
        })
      }
    }
    if (type === 'application/json' || type === 'text/csv') {
      reader.readAsText(e.target.files[0])
    }
  }

  useEffect(() => {
    getInfo()
  }, [])

  return (
    <div className="mt-5">
      <Alarm {...alarmContent} setAlarmContent={setAlarmContent} />
      <InvalidNumbersModal
        open={invalidModal}
        setOpen={setInvalidModal}
        invalidContacts={invalidContacts}
        duplicatedContacts={duplicatedContacts}
      />
      {stat.users == '0' && (
        <div className="text-center mt-10">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-grey-blue">
            {i18n.t('upload_file')}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {i18n.t('formats_acepted')}
          </p>
          <div className="flex justify-around my-5">
            <div className="text-left">
              <p className="font-semibold text-grey-blue">
                {i18n.t('json_format')}
              </p>
              {formats[schema].json()}
            </div>
            <div className="text-left">
              <p className="font-semibold text-grey-blue">
                {i18n.t('csv_format')}
              </p>
              {formats[schema].csv()}
            </div>
          </div>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleClick}
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {i18n.t('add_contacts')}
            </button>
            <input
              type="file"
              accept=".json,.csv"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={readFile}
            />
          </div>
        </div>
      )}
      {stat.users != '0' && (
        <div className="max-w-xs mx-auto">
          <div className="flex flex-col justify-center">
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <stat.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {stat.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-grey-blue">
                  {stat.users}
                </p>
              </dd>
            </div>
            {campaignMissingVariables.length > 0 && (
              <div
                class="bg-red-100 border-l-4 border-red-500 text-orange-700 p-4 mt-4"
                role="alert"
              >
                <p className="font-bold">{i18n.t('be_warmed')}</p>
                <p>{i18n.t('missing_variables')}</p>
                <strong>{campaignMissingVariables.join(", ")}</strong>
                <p className="mt-4">{i18n.t('check_the_file')}</p>
                
              </div>
            )}
            <div className=" flex justify-between">
              <button
                type="button"
                className="mt-5 border-gray-300 bg-white inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setStat({ ...stat, users: 0 })
                }}
              >
                {i18n.t('cancel')}
              </button>
              <button
                type="button"
                className="mt-5 bg-blue-dark hover:bg-indigo-700bg-gray-300 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                onClick={() => {
                  onStepClick({
                    id: 3,
                    name: i18n.t('send'),
                    href: 'send',
                    current: false,
                    completed: false
                  })
                }}
              >
                {i18n.t('next')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Upload
