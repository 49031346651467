import React, { useState, useEffect } from 'react'
import { dateToString } from '../../../libs/date'
import { UserCircleIcon } from '@heroicons/react/20/solid'

const CampaignSelectorCard = ({ campaign, setCampaign }) => {
  return (
    <div
      className={
        'flex border-gray-300 relative bg-white border rounded-lg shadow-sm px-6 py-4 justify-between hover:scale-105 cursor-pointer focus:outline-none transition-all duration-300 ease-in-out'
      }
      onClick={() => setCampaign(campaign)}
    >
      <span
        id="campaignName"
        className="text-base font-medium text-grey-blue flex-1 "
      >
        {campaign.campaignName || campaign.flow}
      </span>
      {campaign.clientInfo && (
        <div
          id="clientInfo"
          className="flex gap-1 text-sm items-center text-grey-blue flex-1"
        >
          <UserCircleIcon className="h-5 w-5 text-grey-blue" />
          <span className="text-base font-medium text-grey-blue">
            {campaign.clientInfo.username || campaign.clientInfo.email}
          </span>
        </div>
      )}
      {campaign.timestamp && (
        <span className="font-medium text-grey-blue flex-1 text-right">
          {dateToString(campaign.timestamp).dayMin}
        </span>
      )}
    </div>
  )
}

const wordIsInCampaign = (campaign, word) => {
  const lowerCaseWord = word.toLowerCase()
  if (campaign.flow.toLowerCase().includes(lowerCaseWord)) {
    return true
  }
  if (
    campaign.campaignName &&
    campaign.campaignName.toLowerCase().includes(lowerCaseWord)
  ) {
    return true
  }
  if (
    campaign.clientInfo &&
    campaign.clientInfo.email &&
    campaign.clientInfo.email.toLowerCase().includes(lowerCaseWord)
  ) {
    return true
  }
  if (
    campaign.clientInfo &&
    campaign.clientInfo.username &&
    campaign.clientInfo.username.toLowerCase().includes(lowerCaseWord)
  ) {
    return true
  }

  return false
}

const filterByWord = (campaigns, word) => {
  return campaigns.filter((campaign) => wordIsInCampaign(campaign, word))
}

const getFilterGroupedCampaigns = (groupedCampaigns, word) => {
  const filteredGroupedCampaigns = {
    hubspotCampaigns: filterByWord(groupedCampaigns.hubspotCampaigns, word),
    regularCampaigns: filterByWord(groupedCampaigns.regularCampaigns, word)
  }

  return filteredGroupedCampaigns
}

export const CampaignSelector = ({ setCampaign, campaigns }) => {
  const [groupedCampaigns, setGroupedCampaigns] = useState(null)
  const [searchWord, setSearchWord] = useState('')
  const [filteredCampaigns, setFilteredCampaigns] = useState(null)

  const getCampaignsGrouped = (campaigns) => {
    const campaignsGrouped = {
      hubspotCampaigns: [],
      regularCampaigns: []
    }

    campaigns.forEach((campaign) => {
      if (campaign.campaignId.startsWith('hubspot')) {
        campaignsGrouped.hubspotCampaigns.push(campaign)
      } else {
        campaignsGrouped.regularCampaigns.push(campaign)
      }
    })

    return campaignsGrouped
  }

  const getCampaignsFiltered = (word) => {
    const campaignsFiltered = getFilterGroupedCampaigns(groupedCampaigns, word)
    return campaignsFiltered
  }

  const getCampaigns = () => {
    let newCampaigns = [...campaigns]

    const newGroupedCampaigns = getCampaignsGrouped(newCampaigns)
    setGroupedCampaigns(newGroupedCampaigns)
    setFilteredCampaigns(newGroupedCampaigns)
  }

  useEffect(() => {
    getCampaigns()
  }, [campaigns])

  const handleSearch = (e) => {
    setSearchWord(e.target.value)
    const newFilteredCampaigns = getCampaignsFiltered(e.target.value)
    setFilteredCampaigns(newFilteredCampaigns)
  }

  return (
    <div className="flex flex-col gap-4">
      <input
        type="text"
        placeholder="Search"
        value={searchWord}
        onChange={handleSearch}
        className="shadow block w-full rounded-md border-gray-300 pl-3 py-2 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm max-w-xs"
      />
      <h2 className="text-3xl font-semibold text-grey-blue w-full">
        Select a campaign
      </h2>
      <div className="flex flex-col gap-4">
        {filteredCampaigns && filteredCampaigns.hubspotCampaigns.length > 0 && (
          <div className="flex flex-col gap-4">
            <h3 className="text-xl font-semibold text-grey-blue w-full">
              Hubspot campaigns
            </h3>

            {filteredCampaigns.hubspotCampaigns.map((campaign) => (
              <CampaignSelectorCard
                key={campaign.campaignId}
                campaign={campaign}
                setCampaign={setCampaign}
              />
            ))}
          </div>
        )}
        <div className="flex flex-col gap-4">
          <h3 className="text-xl font-semibold text-grey-blue w-full">
            Regular campaigns
          </h3>
          {filteredCampaigns &&
            filteredCampaigns.regularCampaigns.map((campaign) => (
              <CampaignSelectorCard
                key={campaign.campaignId}
                campaign={campaign}
                setCampaign={setCampaign}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
