import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import stripeConfig from "../../config/stripe";
import { API, Auth } from 'aws-amplify'
import Spin from "../../components/Spin";
import i18n from '../../i18n'

function Payment({ setTopUps, amount, currency }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const createPaymentIntent = async () => {
    try {
      const tokenReq = (await Auth.currentAuthenticatedUser()).signInUserSession
        .idToken.jwtToken
      const resp = await API.post('rcs-api', '/create-payment-intent', {
        headers: {
          Authorization: tokenReq
        },
        body: {
          amount: amount,
          currency: currency
        }
      });
      setClientSecret(resp.client_secret);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setStripePromise(loadStripe(stripeConfig.publishableKey,{locale : i18n.language}));
  }, []);

  useEffect(() => {
    createPaymentIntent()
  }, []);

  return (
    <>
      {/* <h1>React Stripe and the Payment Element</h1> */}
      {clientSecret && stripePromise ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm setTopUps={setTopUps}/>
        </Elements>

      )
        :
        (
          <div className="flex justify-center my-16">
            <Spin type="lightBg" />
          </div>
        )}
    </>
  );
}

export default Payment;