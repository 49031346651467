import React from 'react'
import {
  StatisticsColumnCompared,
  StatisticsDonut,
  StatisticsLinesChart
} from '../../components/Statistics'
import { CampaignLinesChart } from './components/CampaignLinesChart'

const getSmsData = (campaignInfo) => {
  return [
    {
      type: 'sent',
      value: campaignInfo.sms.sent
    },
    {
      type: 'delivered',
      value: campaignInfo.sms.delivered
    }
  ]
}
const getRbmData = (campaignInfo) => {
  return [
    {
      type: 'sent',
      value: campaignInfo.rbm.sent
    },
    {
      type: 'delivered',
      value: campaignInfo.rbm.delivered
    },
    {
      type: 'visualiced',
      value: campaignInfo.rbm.displayed
    }
  ]
}

const getUnsubscriptionsData = (campaignInfo) => {
  const totalDeliveries =
    campaignInfo.rbm.delivered + campaignInfo.sms.delivered
  return [
    {
      type: 'Deliveries',
      value: totalDeliveries
    },
    {
      type: 'SMS Unsubscriptions',
      value: campaignInfo.optOutInfo.sms
    },
    {
      type: 'RBM Unsubscriptions',
      value: campaignInfo.optOutInfo.rbm
    }
  ]
}

function generateData() {
  const data = []
  const categories = ['visualizations', 'deliveries', 'clicks']
  const startDate = new Date(2024, 0, 1) // 1/1/2024

  for (let i = 0; i < 20; i++) {
    const date = new Date(startDate)
    date.setDate(startDate.getDate() + i) // Incrementa el día para cada objeto

    categories.forEach((category) => {
      const value = Math.floor(Math.random() * 1000) // Genera un número aleatorio para cada categoría
      data.push({
        //format dd/mm/yyyy
        date: date.toLocaleDateString('en-GB'),
        category: category,
        value: value
      })
    })
  }
  return data
}

const data = generateData()

export const StatisticsGeneral = ({ campaignInfo }) => {
  return (
    <article className="mt-4 flex flex-col gap-4">
      <h2 className="text-3xl font-semibold text-grey-blue w-full">
        Campaign name : {campaignInfo.name}
      </h2>
      <section id="messageStatus">
        <h1 className="text-3xl font-semibold text-grey-blue w-full border-b-4 border-grey-blue">
          Messages status
        </h1>
        <div id="charts groups" className="flex gap-4">
          <div id="sms status chart" className="w-full">
            <h2 className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4">
              SMS
            </h2>
            <div className="w-full h-[250px]">
              <StatisticsColumnCompared data={getSmsData(campaignInfo)} />
            </div>
          </div>
          <div id="rbm status chart" className="w-full">
            <h2 className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4">
              RBM
            </h2>
            <div className="w-full h-[250px]">
              <StatisticsColumnCompared data={getRbmData(campaignInfo)} />
            </div>
          </div>
        </div>
      </section>
      <CampaignLinesChart dateStats={campaignInfo.dateStats} />
      {/* <section id="optOuts">
        <h1 className="text-3xl font-semibold text-grey-blue w-full border-b-4 border-grey-blue">
          Unsubscriptions
        </h1>
        <div
          id="OptOutStats"
          className="flex justify-between w-full h-auto mr-10"
        >
          <div className="felx flex-col self-center">
            <h1 className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4">
              SMS Unsubscriptions{' '}
              <span className="text-[color:#A14242] text-3xl font-bold">
                {campaignInfo.optOutInfo.sms}
              </span>
            </h1>
            <h1 className="text-2xl font-semibold text-grey-blue w-full border-grey-blue py-4">
              SMS Unsubscriptions{' '}
              <span className="text-[color:#A14242] text-3xl font-bold">
                {campaignInfo.optOutInfo.rbm}
              </span>
            </h1>
          </div>
          <div className="w-full h-auto">
            <StatisticsDonut data={getUnsubscriptionsData(campaignInfo)} />
          </div>
        </div>
      </section> */}
    </article>
  )
}
