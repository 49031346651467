import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import {
  ExclamationTriangleIcon,
  CheckCircleIcon
} from '@heroicons/react/20/solid'
import { useAppContext } from '../../libs/context'
import { ERRORS } from '../../config/app'
import Spin from '../../components/Spin'
import i18n from '../../i18n'

const ConfirmCodeForm = ({countryPrefix, userParams}) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { setIsAuthenticated, setUserInfo } = useAppContext()
  const history = useHistory()



  const signIn = async () => {
    //TODO traer user params 
    const sigInDetails = await Auth.signIn(userParams)
    setIsAuthenticated(true)
    setUserInfo({
      email: sigInDetails.attributes.email,
      isAdmin:
        sigInDetails.accessToken?.payload['cognito:groups'][0] === 'Admin'
    })
    history.push('/campaign')
  }

  const confirmUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await Auth.confirmSignUp(userParams.username, e.target.code.value)
    } catch (err) {
      setErrorMessage(ERRORS[err.code])
    }
    setLoading(false)
  }

  const onSubmit = async (e) => {
    try{
      await confirmUser(e)
      await signIn()
    }catch(err){
      console.log(err)
    } 
  }

  return (

              <div className="mt-8">
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        {i18n.t('sent_you_code')}
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p>{i18n.t('use_the_code')}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <form onSubmit={onSubmit} className="space-y-6">
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        {i18n.t('code')}
                      </label>
                      <div className="mt-1">
                        <input
                          id="code"
                          name="code"
                          type="code"
                          autoComplete="code"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              {i18n.t('atention')}
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <p>{errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="ease-in duration-100 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled=""
                      >
                        {loading && <Spin type="darkBg" size="small" />}
                        {loading ? i18n.t('processing') : i18n.t('confirm_code')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
          
    
  )
}

export default ConfirmCodeForm
