import React, { useState } from 'react'
import { CAROUSEL_TYPE, TEXT_TYPE, CARD_TYPE, ELEMENT_TYPES } from './constants'
import TextMsgForm from './forms/TextMsgForm'
import RichCardMsgForm from './forms/RichCardMsgForm'
import CarouselMsgForm from './forms/CarouselMsgForm'
import TypeButton from './TypeButton'
import { RBM_ELEMENT_ERRORS, RBM_ELEMENT_ERRORS_MESSAGES } from './constants'

const getTypeForm = (
  type,
  info,
  setInfo,
  isConversational,
  replyOptions,
  errors
) => {
  let MsgForm = null
  switch (type) {
    case TEXT_TYPE:
      MsgForm = TextMsgForm
      break
    case CAROUSEL_TYPE:
      MsgForm = CarouselMsgForm
      break
    case CARD_TYPE:
      MsgForm = RichCardMsgForm
      break
    default:
      MsgForm = TextMsgForm
  }
  return (
    <MsgForm
      elementInfo={info}
      setInfo={setInfo}
      isConversational={isConversational}
      replyOptions={replyOptions}
      errors={errors}
    />
  )
}

const NewElementForm = ({
  elementInfo: info = {},
  elementIndex = null,
  onClick,
  setOpen,
  isConversational = false,
  errors,
  replyOptions = []
}) => {
  const [elementInfo, setElementInfo] = useState(info)
  const [type, setType] = useState(info.type || TEXT_TYPE)
  const [name, setName] = useState(info.name || '')
  return (
    <div>
      <h2 className="text-3xl font-semibold text-grey-blue w-full border-grey-blue py-4">
        Create a new element
      </h2>
      <label
        className="block text-sm mt-3 font-medium text-grey-blue"
        htmlFor="type"
      >
        Element Name
      </label>
      <input
        className="border-2 w-1/2 border-blue-300 focus:border-indigo-600 active:border-indigo-600 border-opacity-50 rounded-md px-2 py-2"
        type="text"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      {errors.includes(RBM_ELEMENT_ERRORS.EMPTY_NAME) && (
        <p className="text-red-500">
          {RBM_ELEMENT_ERRORS_MESSAGES[RBM_ELEMENT_ERRORS.EMPTY_NAME]}
        </p>
      )}
      <label
        className="block text-sm mt-3 font-medium text-grey-blue"
        htmlFor="type"
      >
        Select the element type
      </label>
      <div className="flex gap-2">
        {ELEMENT_TYPES.map((elementType) => (
          <TypeButton
            key={elementType.type}
            type={elementType.type}
            selected={type === elementType.type}
            onClick={setType}
            icon={elementType.icon}
          >
            {elementType.name}
          </TypeButton>
        ))}
      </div>
      {getTypeForm(
        type,
        elementInfo,
        setElementInfo,
        isConversational,
        replyOptions,
        errors
      )}
      <div
        id="buttons"
        className="w-full flex justify-between items-center mt-4"
      >
        <button
          onClick={() => setOpen(false)}
          className="bg-none font-medium border-none text-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={() => onClick({ ...elementInfo, type, name }, elementIndex)}
          className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
        >
          {elementInfo.name ? 'Update' : 'Create'}
        </button>
      </div>
    </div>
  )
}

export default NewElementForm
