import  React,{ Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import i18n from '../i18n'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const languages = {
  en : "English",
  es : "Español"
}



export default function LanguageDropdown() {
  const [language, setLanguage] = useState('en')


  useEffect(() => {
    setLanguage(i18n.language)
  }, [])

  const handleChange = (newLanguage) => {
    if(newLanguage !== language){
      i18n.changeLanguage(newLanguage)
      setLanguage(newLanguage)
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {languages[language]}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <label
                
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm'
                )}
                onClick = {()=>handleChange("en")}
              >
                <img
                  className="mr-3 h-5 w-5  group-hover:text-gray-500"
                  src="/assets/imgs/united-kingdom.png"
                  alt=""
                />
                English
              </label>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <label
                
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex items-center px-4 py-2 text-sm'
                )}
                onClick = {()=>handleChange("es")}
              >
                <img
                  className="mr-3 h-5 w-5  group-hover:text-gray-500"
                  src="/assets/imgs/spain.png"
                  aria-hidden="true"
                  alt=""
                />
                Español
              </label>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
