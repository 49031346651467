import React from 'react'
import { CarouselSvg, TextSvg, RichCardSvg } from '../../../../components/Icons'
import {
  TEXT_TYPE,
  CAROUSEL_TYPE,
  CARD_TYPE,
  ALL_BUTTONS_TYPES
} from './constants'
import { XMarkIcon } from '@heroicons/react/24/outline'

const COLOR_GREY_BLUE = '#29275c'
const getSvg = (type, isButton) => {
  if (isButton) {
    for (const buttonType of ALL_BUTTONS_TYPES) {
      if (buttonType.type === type) {
        const Icon = buttonType.icon
        return <Icon fill={COLOR_GREY_BLUE} stroke={COLOR_GREY_BLUE} />
      }
    }
  }
  switch (type) {
    case TEXT_TYPE:
      return <TextSvg stroke={COLOR_GREY_BLUE} />
    case CAROUSEL_TYPE:
      return <CarouselSvg fill={COLOR_GREY_BLUE} stroke={COLOR_GREY_BLUE} />
    case CARD_TYPE:
      return <RichCardSvg fill={COLOR_GREY_BLUE} stroke={COLOR_GREY_BLUE} />
    default:
      return null
  }
}

const RbmElementCard = ({
  name,
  type,
  onClick,
  onDelete,
  isButton = false,
  isSuggestion = false
}) => {
  const handleDeleteClick = (event) => {
    event.stopPropagation()
    onDelete()
  }

  return (
    <div
      onClick={onClick}
      className={`${
        isButton || isSuggestion ? 'h-14' : 'h-20'
      } cursor-pointer relative group w-1/3 p-4 gap-2 flex justify-center items-center border-2 border-indigo-300 bg-blue-50 rounded-md hover:scale-105 transition duration-300 ease-in-out`}
    >
      <button
        onClick={(e) => handleDeleteClick(e)}
        className="absolute top-2 right-2 z-10 text-red-500 opacity-0 group-hover:opacity-100 hover:scale-105 hover:bg-red-500 hover:text-white  transition duration-300 ease-in-out"
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <div className="w-10 h-10 flex items-center">
        {getSvg(type, isButton || isSuggestion)}
      </div>
      <h2 className="text-lg font-medium text-grey-blue w-full border-grey-blue overflow-hidden overflow-ellipsis whitespace-nowrap">
        {name}
      </h2>
    </div>
  )
}

export default RbmElementCard
