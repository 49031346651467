import React from 'react'

import {
  ClickSvg,
  WebSvg
} from '../../components/Icons'

const REPLY_TYPE = 'reply'
const ACTION_TYPE = 'action'
const BUTTON_ELEMENT = 'button'
const SUGGESTION_ELEMENT = 'suggestion'

const RBMButton = ({ text, type }) => {
  return(
    <div
      id="actionButton"
      className=" flex flex-row gap-2 border-[3px] border-blue-500 py-2 px-2 rounded-3xl items-center"
    >
      {type !== REPLY_TYPE && <WebSvg />}
      <span className="text-blue-500 font-medium text-base">{text}</span>
    </div>
  )
}

const RBMSuggestion = ({ text, type }) => {
  return (
    <div
      id="suggestion"
      className="border-[3px] flex flex-row gap-2 items-center justify-center border-gray-700 rounded-3xl px-3 py-1 h-[46px] min-w-[56px]"
    >
      {type !== REPLY_TYPE && <WebSvg />}
      <span className="text-gray-700 font-medium text-base">{text}</span>
    </div>
  )
}

const renderCTA = (elementInfo, elementType) => {
  switch (elementType) {
    case BUTTON_ELEMENT:
      return <RBMButton text={elementInfo.text} type={elementInfo.type} />
    case SUGGESTION_ELEMENT:
      return <RBMSuggestion text={elementInfo.text} type={elementInfo.type} />
    default:
      break
  }
}
export const ElementClicksTag = ({ elementInfo, elementType }) => {
  return (
    <div
      id="suggestionAction"
      className="flex flex-row gap-2 px-2 h-20 bg-[color:#F5F5F5] items-center rounded-2xl "
    >
      {elementInfo && elementType && renderCTA(elementInfo, elementType)}
      <span className="text-2xl font-semibold text-grey-blue py-4">
        {elementInfo.clicks}
      </span>
      <ClickSvg />
    </div>
  )
}
