import React, { useState } from 'react'
import { useAppContext } from '../../libs/context'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { ERRORS } from '../../config/app'
import Spin from '../../components/Spin'
import {
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import ConfirmCodeForm from './ConfirmCodeForm'
import i18n from '../../i18n'
import LanguageDropdown from '../../components/languageDropdown'
import { useTranslation } from "react-i18next"

const Login = () => {
  const [t] = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showConfirmationBox, setShowConfirmationBox] = useState(false)
  const [userParams, setUserParams] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const { setIsAuthenticated, setUserInfo } = useAppContext()
  const history = useHistory()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setErrorMessage(null)
    const [email, password, rememberMe] = e.target
    const data = {
      username: email.value,
      password: password.value,
      rememberMe: rememberMe.value
    }
    try {
      await Auth.signIn(data)
      setIsAuthenticated(true)
      await Auth.currentSession()
      const { attributes } = await Auth.currentUserInfo()
      const { accessToken } = await Auth.currentSession()
      setUserInfo({
        name: attributes.sub,
        email: attributes.email,
        isAdmin: accessToken.payload['cognito:groups'][0] === 'Admin'
      })
      history.push('/')
    } catch (err) {
      if ('UserNotConfirmedException' === err.code) {
        //TODO show confirm code form and resend code
        setUserParams(data)
        setShowConfirmationBox(true)
        await Auth.resendSignUp(data.username)
      }
      setErrorMessage(ERRORS[err.code])
    }
    setLoading(false)
  }

  return (
    <>
      <div className="min-h-full flex">

        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute top-2 left-2">
            {t('language')}<LanguageDropdown />

          </div>
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                src="/assets/imgs/logo-tadoo-black.svg"
                style={{ width: '200px' }}
                alt="logo"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-grey-blue">
                {/* Sign in to your account */}
                {i18n.t('log_in')}
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                {i18n.t('or')}{' '}
                <Link
                  to="/register"
                  className="font-medium text-blue-dark hover:text-blue-dark hover:underline"
                >
                  {/* open an account */}
                  {i18n.t('sign_up')}
                </Link>
              </p>
            </div>
            {!showConfirmationBox ? (
              <div className="mt-8">
                <div className="mt-6">
                  <form onSubmit={onSubmit} className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-grey-blue"
                      >
                        {i18n.t('password')}
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <Link
                      to="/resetpassword"
                      className="font-medium text-blue-dark hover:text-blue-dark hover:underline"
                    >
                      {/* open an account */}
                      {i18n.t('forgot_password')}
                    </Link>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-blue-dark border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-grey-blue"
                        >
                          {/* Remember me */}
                          {i18n.t('remember_me')}
                        </label>
                      </div>


                      {/* <div className="text-sm">
                                  <Link
                                    href="#"
                                    className="font-medium text-blue-dark hover:text-blue-dark hover:underline"
                                  >
                                    Forgot your password?
                                  </Link>
                                </div> */}
                    </div>
                    {errorMessage && (
                      <div className="rounded-md bg-yellow-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              {i18n.t('atention')}
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <p>{errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="ease-in duration-100 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled=""
                      >
                        {loading && <Spin type="darkBg" size="small" />}
                        {/* {loading ? 'Processing...' : 'Sign in'} */}
                        {loading ? i18n.t('processing') : i18n.t('sign_in')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <ConfirmCodeForm userParams={userParams} />
            )}
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/assets/imgs/img-bg-chatsales.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default Login
